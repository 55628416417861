import React from 'react';
import './policy.css';

const TermsAndConditions = () => {
  return (
    <div className="privacy-policy">
      <h1 style={{ textAlign: 'center' }}>Terms and Conditions</h1>

      <section>
        <h2>1. Acceptance of Terms</h2>
        <p>
          By accessing and using this portal, you agree to comply with and be bound by these terms and conditions. If you do not agree with any part of these terms, please do not use the portal.
        </p>
      </section>

      <section>
        <h2>2. User Account</h2>
        <p>
          - You are responsible for maintaining the confidentiality of your login credentials and for all activities that occur under your account.
        </p>
        <p>
          - You agree to immediately notify us of any unauthorized use of your account or any other breach of security.
        </p>
      </section>

      <section>
        <h2>3. Use of Portal</h2>
        <p>
          - The portal is provided to you for managing your properties and viewing associated personal details and documents.
        </p>
        <p>
          - You may only access your own data. Unauthorized access to other users' data is strictly prohibited.
        </p>
      </section>

      <section>
        <h2>4. Data Privacy</h2>
        <p>
          - We are committed to protecting your personal information. Your data, including personal details and documents such as ID and passport, will only be accessible to you and our authorized personnel.
        </p>
        <p>
          - We will not share your personal data with third parties without your consent, except as required by law.
        </p>
      </section>

      <section>
        <h2>5. Property Data and Monitoring</h2>
        <p>
          - The portal displays data from Property Monitor, including charts on price changes and the latest transactions. This information is for informational purposes only and should not be construed as professional financial advice.
        </p>
        <p>
          - We do not guarantee the accuracy, completeness, or timeliness of the data provided.
        </p>
      </section>

      <section>
        <h2>6. Security</h2>
        <p>
          - We implement various security measures to protect your personal data. However, we cannot guarantee absolute security due to the inherent risks associated with internet use.
        </p>
      </section>

      <section>
        <h2>7. Liability</h2>
        <p>
          - We are not liable for any damages, losses, or expenses arising from the use of this portal, including any reliance on the data provided.
        </p>
        <p>
          - In no event shall we be liable for any indirect, incidental, special, or consequential damages.
        </p>
      </section>

      <section>
        <h2>8. Changes to Terms and Conditions</h2>
        <p>
          - We reserve the right to modify these terms and conditions at any time. Any changes will be effective immediately upon posting to the portal. Your continued use of the portal after any modifications signifies your acceptance of the new terms.
        </p>
      </section>

      <section>
        <h2>9. Termination</h2>
        <p>
          - We reserve the right to terminate or suspend your access to the portal at any time, without prior notice, for any reason, including if you breach these terms and conditions.
        </p>
      </section>

      <section>
        <h2>10. Governing Law</h2>
        <p>
          - These terms and conditions are governed by and construed in accordance with the laws of the United Arab Emirates. Any disputes arising from or relating to these terms will be subject to the exclusive jurisdiction of the courts of Dubai.
        </p>
      </section>

      <section>
        <h2>11. Contact Information</h2>
        <p>
          - For any questions or concerns regarding these terms and conditions, please contact our support team at info@kestates.ae.
        </p>
      </section>

      <p style={{ textAlign: 'center', marginTop: '40px' }}>
        By using this portal, you acknowledge that you have read, understood, and agree to be bound by these terms and conditions.
      </p>
    </div>
  );
}

export default TermsAndConditions;
