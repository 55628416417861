import React, { useEffect, useState } from 'react'
import { 
    Container,
    Typography,
    Button,
    TextField,
    Select,
    MenuItem,
    Grid,
    Box,
    Alert
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import AttachmentIcon from '@mui/icons-material/Attachment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Breadcrumb, Avatar,Drawer, Divider, Skeleton,Modal, Input  } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import { Link } from 'react-router-dom';

import { BaseImageUrl } from '../../../Components/BaseImageUrl';
import AddClientComp from '../../../AdminComponents/AddClientComp';
import UpdateClient from '../../../AdminComponents/UpdateClientComp';
import { Snackbar } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { BaseUrl } from '../../../Components/BaseUrl';
import { baseUrl } from '../../Server/baseUrls';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
 function PropertyTypes() {
    const [propertytypes,setPropertyTypes] = useState([])
    const [loading,setLoading] = useState(false)
    const [name,setName] = useState("")
    const [id,setID] = useState("")
    const [showAdd, setShowAdd] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);

    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);
    const [updateClientData, setUpdateClientData] = useState({});
    const [recordID,setRecordID] = useState("")
    const [open, setOpen] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [client,setClient] = useState({})
    const [profileLoading,setProfileLoading] = useState(false)
    const [country,setCountry] = useState("")
    const [status,setStatus] = useState("")
    const [search,setSearch] = useState("")
    const [errors, setErrors] = useState({});
    const [isLoading,setIsLoading] = useState(false)
    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
        },
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowToast(false);
      };

    const showAddClientModal = () => {
        setIsAddClientModalOpen(true);
      };
      const handleAddClientOk = () => {
        setIsAddClientModalOpen(false);
      };
      const handleAddClientCancel = () => {
        setIsAddClientModalOpen(false);
      };

      const showUpdateClientModal = (record) => {
        
        setIsUpdateClientModalOpen(true);
        setUpdateClientData(record)
        setRecordID(record.id)
      };
      const handleUpdateClientOk = () => {
        setIsUpdateClientModalOpen(false);
      };
      const handleUpdateClientCancel = () => {
        setIsUpdateClientModalOpen(false);
      };

      const showDrawer = (record_id) => {
        console.log(record_id);
        setProfileLoading(true)
        setClient({})
        setOpenDrawer(true);

        axios.get(baseUrl+"/api/owners_details/"+record_id,axiosConfig)
        .then((res) =>{
            if(res.data.success){
                // console.log(res.data.client.documents);

                setClient(res.data.client)
                setProfileLoading(false)
                
            }
        }).catch((err) =>{
          console.log("err",err);
        })
        
      };
      const onCloseDrawer = () => {
        setOpenDrawer(false);
      };

    useEffect(() =>{
        const page = 1;
    const perPage = 10;
    fetchData(page, perPage);

  
   
    },[])

    const handleShowAdd =() =>{
      setName("");
      setShowAdd(true)
    }

    const handleCloseAdd =() =>{
      setShowAdd(false)
    }

    const validateForm = () => {
      const newErrors = {};
      if(!name) {
          newErrors.name = 'Name is required';
      }
      setErrors(newErrors);
      return Object.keys(newErrors).length === 0;
  };

    const handleAddOk = () =>{
      if(validateForm()) {
        setIsLoading(true)
      const data = {name:name};

      axios.post(baseUrl+"/api/propertytypes",data,axiosConfig)
      .then((res) =>{
        if(res.data.success){
          setIsLoading(false)
          setName("")
          setShowAdd(false)
          const page = 1
          const perPage = 10
          fetchData(page,perPage)
        }
      }).catch((err) =>{
        setIsLoading(false)
        console.log("err",err);
    })
    }
      
    }

    const handleShowUpdate =(record) =>{
      setName(record.label)
      setID(record.value)
      setShowUpdate(true)
    }

    const handleCloseUpdate =() =>{
      setShowUpdate(false)
    }

    const handleUpdateOk =() =>{
      if(validateForm()) {
        setIsLoading(true)
      const data = {id:id,name:name};

      axios.post(baseUrl+"/api/updatepropertytype",data,axiosConfig)
      .then((res) =>{
        if(res.data.success){
          setIsLoading(false)
          setName("")
          setShowUpdate(false)
          const page = 1
          const perPage = 10
          fetchData(page,perPage)
        }
      }).catch((err) =>{
        setIsLoading(false)
        console.log("err",err);
    })
    }
    }

    const refreshPage = () =>{
         setIsAddClientModalOpen(false)
         setIsUpdateClientModalOpen(false)
         const page = 1;
         const perPage = 10;
     
         fetchData(page, perPage);

    }

    const searchClient = (e) =>{
        const page = 1;
        const perPage = 10;
        const search = e.target.value
        setSearch(e.target.value)
        fetchData(page, perPage);
        console.log(e.target.value);
    }

    const setStatusFilter = (e)=>{
        const page = 1;
        const perPage = 10;
        const status = e.target.value
        setStatus(e.target.value)
        fetchData(page, perPage);
    }

    const fetchData = async (page, perPage) => {
        try {
            setLoading(true)
            const url = `${baseUrl}/api/propertytypes?page=${page}&perPage=${perPage}`;
            const response = await axios.get(url, axiosConfig);
          
            setLoading(false);
            setPropertyTypes(response.data.propertytypes);
        } catch (error) {
          setLoading(false);
            console.error("Error:", error);
        }
    };
    
    // Call fetchData function with the additional parameters
    const handleSearch = (e) => {
        // const searchValue = e.target.value;
        // fetchData(page, perPage, searchValue, status, country);
    };
    


      const docs = [
        {
          title: '#',
          dataIndex: 'id',
          key: 'id',
        },
        {
          title: 'Document',
          dataIndex: 'document',
          key: 'document',
        },
        {
          title: '',
          render: (text, record) => (
            <div className="ms-auto">
         
          
         <AttachmentIcon className="icon-margin-left" />
        <CloseIcon className="icon-margin-left" />


                     
                        
            </div>
          ),
        },
      ];
      const columns = [
 
        {
          title: "Type",
          dataIndex: "label",
        },
        
     
      
        {
          title: "Actions",
          render: (text, record) => (
            <div className="ms-auto">
         
          
              <Button
                            variant="outlined"
                            color="primary"
                            // startIcon={<AddIcon />}
                            // onClick={handleOpenModal}
                            style={{ marginBottom: '1rem' }}
                            startIcon={<ModeEditOutlineIcon />}
                            onClick={() => handleShowUpdate(record)}
                        >
                            Update
                        </Button>
                        <Button className='ml-2'
                            variant="outlined" color="error"
                            // startIcon={<AddIcon />}
                            // onClick={handleOpenModal}
                            style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                            onClick={() => handleDelete(record.value)}
                            startIcon={<DeleteOutlineIcon />}
                        >
                            Delete
                        </Button>
                       
            </div>
          ),
        },
      ];

      const handleTableChange = (pagination) =>{
        const page = pagination.current
        const perPage = pagination.pageSize
        fetchData(page,perPage)
      }

    const handleDelete = (record_id) =>{
        setOpen(true);
        setRecordID(record_id)
    

      }
    
    
      const handleClose = () => {
        setOpen(false);
      };

      const handleAgreeDelete = () =>{

        axios.delete(baseUrl+"/api/propertytypes/"+recordID,axiosConfig)
        .then((res) =>{
            setOpen(false);
            if(res.data.success){
            const page =1
            const perPage = 10
           setTimeout(() => {
            fetchData(page,perPage)
            setShowToast(true)
           }, 200);
          
          
            }
        }).catch((err) =>{
          console.log("err",err);
        })
      }

  return (
    <Container maxWidth={'xl'} >
        <Drawer width={"50%"} title="Client Details" onClose={onCloseDrawer} open={openDrawer} style={{marginTop:'20px'}}>
             <Skeleton loading={profileLoading} active avatar >
             
             
            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between',marginTop:'20px'}}>
            <Avatar   size={{
                    xs: 24,
                    sm: 32,
                    md: 40,
                    lg: 64,
                    xl: 80,
                    xxl: 80,
                  }} src={BaseImageUrl+"profile_images/"+client.profile_image} /> 
                  <div>
                  <p>Name    {client.name}</p>
                  <p>Email    {client.email}</p>
                  <p>Phone    {client.phone}</p>
                  <p>Status    {client.active}</p>
                  </div>
            </div>
      
        
            </Skeleton> 

        <Divider />
        <Skeleton loading={profileLoading} active >
        <Table dataSource={client.documents} columns={docs} pagination={false} />
        </Skeleton>
      </Drawer>
      <Snackbar
  open={showToast}
  onClose={handleCloseToast}
  autoHideDuration={1500}
  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
>
  <Alert severity="success" variant="filled">
    PropertyType Successfully deleted
  </Alert>
</Snackbar>


         {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You wont able revert
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" onClick={handleAgreeDelete}  startIcon={<CheckIcon />}>
           Continue 
          </Button>
        </DialogActions>
      </Dialog> */}
       <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delete PropertyType
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
          Are you sure to delete? You will not be able back 
          </Typography>
         
        </DialogContent>
        <DialogActions>
          <Button  variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" onClick={handleAgreeDelete}  startIcon={<CheckIcon />}>
           Continue 
          </Button>
        </DialogActions>
      </BootstrapDialog>

        <Modal width={400} title="Add New Type" 
        open={showAdd} 
        onOk={handleAddOk}
         onCancel={handleCloseAdd}
        confirmLoading={isLoading}
         >
          <Divider />
          <label>Name</label>
          <Input  onChange={(e) => setName(e.target.value)} />
          {errors.name && <Alert severity="error">{errors.name}</Alert>}
      
      </Modal>

      <Modal width={400} title="Update Type" 
        open={showUpdate} 
        onOk={handleUpdateOk}
         onCancel={handleCloseUpdate}
        confirmLoading={isLoading}
         >
          <Divider />
          <label>Name</label>
          <Input value={name} onChange={(e) => setName(e.target.value)} />
          {errors.name && <Alert severity="error">{errors.name}</Alert>}
      
      </Modal>

            <div className=''>
                <Grid container spacing={2} display={'flex'} alignItems={'center'}  marginBottom={'1rem'} >
                    
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
                        <Typography variant='h3' align='left' width={'100%'} marginBottom={'1rem'}>
                            Property Types
                        </Typography>
                    </Grid>

                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} > 
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleShowAdd}
                            style={{ marginBottom: '1rem' }}
                        >
                            Add New Type
                        </Button>
                    </Grid>

                </Grid>
                 

              
                <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
                <Table
                      onChange={handleTableChange}
                      loading={loading}
                      className="table-striped"
                      pagination={{
                        pageSizeOptions: ["10", "20", "50", "100"],
                        total:
                        propertytypes?.total > 0 &&
                        propertytypes.total,
                        pageSize: propertytypes.per_page,
                        current: propertytypes.current_page,
                        showTotal: (total, range) =>
                          `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                        showSizeChanger: true,
                        onShowSizeChange: onShowSizeChange,
                        itemRender: itemRender,
                      }}
                      style={{ overflowX: "auto" }}
                      columns={columns}
                      // bordered
                      dataSource={
                        propertytypes?.data
                          ? propertytypes?.data
                          : []
                      }
                    //   rowKey={(record) => record}
                    />
                </Grid>
                    
                </div>
               
                </Container>
  )
}

export default PropertyTypes
