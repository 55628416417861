import React, { useEffect, useState } from 'react'
import {
  Container,
  Typography,
  Button,
  TextField,
  Select,
  MenuItem,
  Grid,
  Box,
  Modal,
  Alert
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import AttachmentIcon from '@mui/icons-material/Attachment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Breadcrumb, Avatar, Drawer, Divider, Skeleton } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined, UserOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";

import { Link } from 'react-router-dom';
import './owner.css'
import { BaseImageUrl } from '../../../Components/BaseImageUrl';
import AddClientComp from '../../../AdminComponents/AddClientComp';
import UpdateClient from '../../../AdminComponents/UpdateClientComp';
import { Snackbar } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { BaseUrl } from '../../../Components/BaseUrl';
import { baseUrl, profileImageBaseUrl } from '../../Server/baseUrls';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
  }));
 function Owners() {
    const [clients,setClients] = useState([])
    const [loading,setLoading] = useState(false)
    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);
    const [updateClientData, setUpdateClientData] = useState({});
    const [recordID,setRecordID] = useState("")
    const [open, setOpen] = React.useState(false);
    const [showToast, setShowToast] = React.useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [client,setClient] = useState({})
    const [profileLoading,setProfileLoading] = useState(false)
    const [country,setCountry] = useState("")
    const [status,setStatus] = useState("")
    const [search,setSearch] = useState("")
    const [countries,setCountries] = useState([])
    


  const token = useSelector((state) => state.login.token);
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`, // Include the token as a Bearer token
    },
  };

  const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToast(false);
  };

  const showAddClientModal = () => {
    setIsAddClientModalOpen(true);
  };
  const handleAddClientOk = () => {
    setIsAddClientModalOpen(false);
  };
  const handleAddClientCancel = () => {
    setIsAddClientModalOpen(false);
  };

  const showUpdateClientModal = (record) => {
    // console.log("rec", record);
    setIsUpdateClientModalOpen(true);
    setUpdateClientData(record)
    setRecordID(record.id)
  };
  const handleUpdateClientOk = () => {
    setIsUpdateClientModalOpen(false);
  };
  const handleUpdateClientCancel = () => {
    setIsUpdateClientModalOpen(false);
  };

  const showDrawer = (record_id) => {
    console.log(record_id);
    setProfileLoading(true)
    setClient({})
    setOpenDrawer(true);

    axios.get(baseUrl + "/api/owners_details/" + record_id, axiosConfig)
      .then((res) => {
        if (res.data.success) {
          // console.log(res.data.client.documents);

          setClient(res.data.client)
          setProfileLoading(false)

        }
      })

  };
  const onCloseDrawer = () => {
    setOpenDrawer(false);
  };

  useEffect(() => {
    const page = 1;
    const perPage = 10;
    fetchData(page, perPage, search, status, country);

     axios.get(baseUrl+"/api/countries",axiosConfig)
     .then((res) =>{
 
 if(res.data.success){
  setCountries(res.data.countries)
 }
   }).catch((err) =>{
      console.log("errors",err);
     })
   
    },[])


 

  const refreshPage = () => {
    setIsAddClientModalOpen(false)
    setIsUpdateClientModalOpen(false)
    const page = 1;
    const perPage = 10;

    fetchData(page, perPage, search, status, country);

  }

  const searchClient = (e) => {
    const page = 1;
    const perPage = 10;
    const search = e.target.value
    setSearch(e.target.value)
    fetchData(page, perPage, search, status, country);
    console.log(e.target.value);
  }

  const setStatusFilter = (e) => {
    const page = 1;
    const perPage = 10;
    const status = e.target.value
    setStatus(e.target.value)
    fetchData(page, perPage, search, status, country);
  }

  const fetchData = async (page, perPage, searchData, status, country) => {
    try {
      setLoading(true)
      const url = `${baseUrl}/api/owners?page=${page}&perPage=${perPage}&search=${searchData}&status=${status}&country=${country}`;
      const response = await axios.get(url, axiosConfig);
      setLoading(false);
      setClients(response.data.owners);
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };



    const setCountryFilter =(e) =>{
      const page = 1;
      const perPage = 10;
      const country = e.target.value
      setCountry(e.target.value)
      fetchData(page, perPage,search,status,country);
    }


    
    // Call fetchData function with the additional parameters
    const handleSearch = (e) => {
        // const searchValue = e.target.value;
        // fetchData(page, perPage, searchValue, status, country);
    };
    



  const docs = [
    {
      title: '#',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Document',
      dataIndex: 'document',
      key: 'document',
    },
    {
      title: '',
      render: (text, record) => (
        <div className="ms-auto">


                     
                        
            </div>
          ),
        },
      ];
      const columns = [
      
        {
          title: "Name",
          dataIndex: "name",
        },
        {
            title: "Photo",
            dataIndex: "name",
            render: (text, record) => {
              return(
                <div>
                  {record.profile_image == null ? (
                    <Avatar size={64} icon={<UserOutlined />} />
                  ) : (
                    <Avatar
                      size={{
                        xs: 24,
                        sm: 32,
                        md: 40,
                        lg: 50,
                        xl: 60,
                        xxl: 70,
                      }}
                      src={profileImageBaseUrl+"/"+record.profile_image}
                    />
                  )}
                </div>
              )
            }
          },
          {
            title: "Email",
            dataIndex: "email",
          },
        {
          title: "Phone Number",
          dataIndex: "phone"
        },
        {
            title: "Status",
            dataIndex: "active"
          },
          {
            title: "Country",
            dataIndex: "country_name",
          },
          {
            title: "Address",
            dataIndex: "address",
          },
      
        {
          title: "Actions",
          render: (text, record) => (
            <div className="ms-auto">
         
          
              <Button
                            variant="outlined"
                            color="primary"
                            // startIcon={<AddIcon />}
                            // onClick={handleOpenModal}
                            style={{ marginBottom: '1rem' }}
                            startIcon={<ModeEditOutlineIcon />}
                            onClick={() => showUpdateClientModal(record)}
                        >
                            Update
                        </Button>
                        <Button className='ml-2'
                            variant="outlined" color="error"
                            // startIcon={<AddIcon />}
                            // onClick={handleOpenModal}
                            style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                            onClick={() => handleDelete(record.id)}
                            startIcon={<DeleteOutlineIcon />}
                        >
                            Delete
                        </Button>

                        {/* <Button className='ml-2'
                            variant="outlined" color="success"
                            style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                            onClick={() => showDrawer(record.id)}
                            startIcon={<PreviewIcon />}
                        >
                            Details
                        </Button> */}
                        
            </div>
          ),
        },
      ];



  
 

  const handleTableChange = (pagination) => {
    const page = pagination.current
    const perPage = pagination.pageSize
    fetchData(page, perPage, search, status, country);
  }

  const handleDelete = (record_id) => {
    setOpen(true);
    setRecordID(record_id)


  }


  const handleClose = () => {
    setOpen(false);
  };

  const handleAgreeDelete = () => {

    axios.delete(baseUrl + "/api/owners/" + recordID, axiosConfig)
      .then((res) => {
        setOpen(false);
        if (res.data.success) {
          const page = 1
          const perPage = 10
          setTimeout(() => {
            fetchData(page, perPage, search, status, country);
            setShowToast(true)
           }, 200);
          
          
            }
        }).catch((err) =>{
          console.log("err",err);
        })
      }

  return (
    <Container maxWidth={'xl'} >
      <Drawer width={"50%"} title="Client Details" onClose={onCloseDrawer} open={openDrawer} style={{ marginTop: '20px' }}>
        <Skeleton loading={profileLoading} active avatar >


          <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginTop: '20px' }}>
            <Avatar size={{
              xs: 24,
              sm: 32,
              md: 40,
              lg: 64,
              xl: 80,
              xxl: 80,
            // }} src={BaseImageUrl + "profile_images/" + client.profile_image} />
          }} src={ profileImageBaseUrl +"/"+client.profile_image} />
            <div>
              <p>Name    {client.name}</p>
              <p>Email    {client.email}</p>
              <p>Phone    {client.phone}</p>
              <p>Status    {client.active}</p>
            </div>
          </div>


        </Skeleton>

        <Divider />
        <Skeleton loading={profileLoading} active >
          <Table dataSource={client.documents} columns={docs} pagination={false} />
        </Skeleton>
      </Drawer>
      <Snackbar
        open={showToast}
        onClose={handleCloseToast}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          User Successfully deleted
        </Alert>
      </Snackbar>


      {/* <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure to delete?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            You wont able revert
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button  variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" onClick={handleAgreeDelete}  startIcon={<CheckIcon />}>
           Continue 
          </Button>
        </DialogActions>
      </Dialog> */}
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delete User
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure to delete? You will not be able back
          </Typography>

        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" onClick={handleAgreeDelete} startIcon={<CheckIcon />}>
            Continue
          </Button>
        </DialogActions>
      </BootstrapDialog>
      <Modal
        open={isAddClientModalOpen}
        onClose={handleAddClientOk}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
      >
        <Box 
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: '90%', // Set width to 90% for responsiveness
            maxWidth: 800, // Max width for larger screens
            bgcolor: 'background.paper',
            boxShadow: 24,
            p: 4,
            maxHeight: '80vh', // Set maxHeight to 80vh for responsiveness
            overflowY: 'auto', // Enable vertical scrolling
            overflowX: 'hidden', // Disable horizontal scrolling
            backgroundColor: 'white'
          }}
        >
          <Typography variant="h6" component="h2" gutterBottom>
            Add New Client
          </Typography>
          <AddClientComp xCLoseModal={() => refreshPage()} />
        </Box>
      </Modal>

      <Modal
        open={isUpdateClientModalOpen}
        onClose={handleUpdateClientOk}
        aria-labelledby="add-client-modal-title"
        aria-describedby="add-client-modal-description"
      >
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '90%', // Set width to 90% for responsiveness
          maxWidth: 800, // Max width for larger screens
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
          maxHeight: '80vh', // Set maxHeight to 80vh for responsiveness
          overflowY: 'auto', // Enable vertical scrolling
          overflowX: 'hidden', // Disable horizontal scrolling
          backgroundColor: 'white'
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Update Client
          </Typography>
          <UpdateClient
            id={updateClientData.id}
            xImage={updateClientData.profile_image}
            xName={updateClientData.name}
            xPhoneNumber={updateClientData.phone}
            xEmail={updateClientData.email}
            xDob={updateClientData.dob}
            xStatus={updateClientData.active}
            xAddress={updateClientData.address}
            xCountry={updateClientData.country_id}
            xDocument={updateClientData.passportFile}
            xCLoseUpdateModal={() => refreshPage()} />
        </Box>
      </Modal>
      {/* <Modal width={1000} title="Add New Client" 
        open={isAddClientModalOpen} onOk={handleAddClientOk}
         onCancel={handleAddClientCancel}
        
         >
             <AddClientComp />
      
      </Modal> */}
      <div className=''>
        <Grid container spacing={2} display={'flex'} alignItems={'center'} marginBottom={'1rem'} >

          <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
            <Typography variant='h3' align='left' width={'100%'} marginBottom={'1rem'}>
              Clients
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} xl={6} lg={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'} >
            <Button
              variant="contained"
              color="primary"
              startIcon={<AddIcon />}
              onClick={showAddClientModal}
              style={{ marginBottom: '1rem' }}
            >
              Add New Client
            </Button>
          </Grid>

        </Grid>


        <Grid container spacing={2} display={'flex'} alignItems={'center'} marginBottom={'1rem'} >

          <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
            <Box
              sx={{
                width: '100%',
                // maxWidth: '100%',
              }}
            >
              <TextField
                label="Search"
                variant="outlined"
                // value={searchTerm}
                onChange={(e) => searchClient(e)}
                fullWidth
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={6} xl={6} lg={6} display={'flex'} >
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
                        <Select
                                // value={statusFilter}
                                onChange={(e) => setCountryFilter(e)}
                                variant="outlined"
                                fullWidth
                                displayEmpty
                            >
                               <MenuItem value="">All Countries</MenuItem>
                              {countries.map((country,index) =>(
                            <MenuItem key={index} value={country.id}>{country.name}</MenuItem>
                              ))}
                               
                               
                            </Select>
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
                            <Select
                                // value={statusFilter}
                                onChange={(e) => setStatusFilter(e)}
                                variant="outlined"
                                fullWidth
                                displayEmpty
                            >
                                <MenuItem value="">Filter by Status</MenuItem>
                                <MenuItem value="Active">Active</MenuItem>
                                <MenuItem value="Inactive">Inactive</MenuItem>
                            </Select>
                        </Grid>
                    </Grid>

        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6} >
          <Table
            onChange={handleTableChange}
            loading={loading}
            className="table-striped"
            pagination={{
              pageSizeOptions: ["10", "20", "50", "100"],
              total:
                clients?.total > 0 &&
                clients.total,
              pageSize: clients.per_page,
              current: clients.current_page,
              showTotal: (total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              itemRender: itemRender,
            }}
            style={{ overflowX: "auto" }}
            columns={columns}
            // bordered
            dataSource={
              clients?.data
                ? clients?.data
                : []
            }
          //   rowKey={(record) => record}
          />
        </Grid>

      </div>

    </Container>
  )
}

export default Owners
