import React, { useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    CircularProgress,
    Snackbar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Paper } from '@mui/material';
// Local
import profileImage from "../../Images/img.jpeg";
import "./resetPass.css";
import Lottie from 'lottie-react';
import ResetPasswordAnime from '../../Images/ressetPass.json';
import { CHECK_OTP_API, EMAIL_VERIFICATION_API, UPDATE_PASSWORD_API } from '../Server/Apis';
import { httpRequest } from '../Server/Axios';
import { useSelector } from 'react-redux';
import { Alert } from 'antd';
import { ConfirmationNumber } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

const ResetPassword = () => {
    const tokenInfo = useSelector((state) => state.login.token);
    const userInfo = useSelector((state) => state.login.user);
    const navigate = useNavigate();

    const [image, setImage] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState(userInfo.email ? userInfo.email : "");
    const [emailVerified, setEmailVerified] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        // Limit input to 6 characters
        if (value.length <= 6) {
          setOtp(value);
        }
    };
  
    const handleNewPaswordChange = (event) => {
      setNewPassword(event.target.value);
    };

    const handleConfoirmPaswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const confirmEmailAPI = async() => {
        const API_EMAIL_VERIFICATION_CONFIG = EMAIL_VERIFICATION_API(email, tokenInfo);
        setLoader(true)
        try {
            const response = await httpRequest(API_EMAIL_VERIFICATION_CONFIG);
            if(response.success){
                setLoader(false)
                setEmailVerified(true)
            }
            else{
                setLoader(false)
                setOpen(true);
                setError("Email doesn't work")
                setEmailVerified(false)
            }
        } catch (error) {
            setLoader(false)
             setOpen(true);
            setError("Something went wrong!")
            setEmailVerified(false)
        }
    }

    const checkOTP = async() => {
        const API_CHECK_OTP_CONFIG = CHECK_OTP_API(email, otp);
        setLoader(true)
        try {
            const response = await httpRequest(API_CHECK_OTP_CONFIG);
            if(response.success){
                setLoader(false)
                setCodeVerified(true)
            }
            else{
                setLoader(false)
                setOpen(true);
                setError("Wrong OTP")
                setCodeVerified(false)
            }
        } catch (error) {
            setLoader(false)
             setOpen(true);
            setError("Something went wrong!")
            setCodeVerified(false)
        }
    }

    const updatePassword = async() => {
        if( newPassword == confirmPassword){
            const API_UPDATE_PASSWORD_CONFIG = UPDATE_PASSWORD_API(email, `${newPassword}`, `${confirmPassword}`);
            setLoader(true)
            try {
                const response = await httpRequest(API_UPDATE_PASSWORD_CONFIG);
                if(response.success){
                    setLoader(false)
                    setOpen(true);
                    setError(response.msg)
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
                else{
                    setLoader(false)
                    setOpen(true);
                    setError("Email doesn't work")
                    setCodeVerified(false)
                }
            } catch (error) {
                setLoader(false)
                setOpen(true);
                setError("Something went wrong!")
            }   
        }
        else{
            setOpen(true);
            setError("Password not match...")
        }
    }


    const handleClose = () => {
        setOpen(false);
      };

    return(
        <div style={{ height:"75vh", width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='setting-container' >
                <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"flex-start", }} >
                    {/* <Avatar alt="Profile Picture" src={image} style={{ width:'6rem', height:'6rem' }} /> */}
                    <div className='avatar_style' >
                        <Lottie animationData={ResetPasswordAnime} loop={true} width={'100%'} height={'100%'} />
                    </div>
                    <div style={{ marginLeft:"0.5rem" }} >
                        <Typography variant="subtitle1" style={{ fontFamily:'Montserrat'}} >{userInfo.name}</Typography>
                        <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >Reset Password</Typography>
                    </div>
                </div>

                <div style={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"3rem" }} >

                    <TextField
                        className='text_field_style1'
                        label="Email Address"
                        value={email}
                        onChange={handleEmailChange}
                    />

                </div>

                { 
                    emailVerified == false && codeVerified == false && 
                        <Grid item xs={12} style={{ marginTop: '2rem', display:"flex", alignItems:"center", justifyContent:'center' }}>
                            {
                                loader ? 
                                <CircularProgress style={{ color:"black" }} />
                                :
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    style={{ backgroundColor:'black', alignSelf:"center"}}
                                    size='small'
                                    onClick={confirmEmailAPI}
                                >
                                    Confirm Email
                                </Button>
                            }
                        </Grid>
                }

                {
                    emailVerified == true && codeVerified == false && //false
                        <div style={{ width:"100%", display:"flex", flexDirection:'column', alignItems:"center", justifyContent:"center", marginTop:"3rem" }}  >
                            <TextField
                                label="OTP"
                                variant="outlined"
                                size="small"
                                margin="normal"
                                // type="number"
                                inputProps={{ inputMode: 'numeric'}}
                                value={otp}
                                onChange={handleChange}
                                style={{ letterSpacing:5 }}
                                className='text_field_style1'
                            />
                             <Grid item xs={12} style={{ marginTop: '2rem', display:"flex", alignItems:"center", justifyContent:'center' }}>
                                {
                                    loader ?
                                        <CircularProgress style={{ color:"black" }} />
                                        :
                                        <Button
                                            variant="contained"
                                            // color="primary"
                                            style={{ backgroundColor:'black', alignSelf:"center"}}
                                            onClick={checkOTP}
                                        >
                                            submit
                                        </Button>
                                }
                            </Grid>
                        </div>
                }


                {
                    emailVerified == true && codeVerified == true && //false
                        <div style={{ width:"100%", display:"flex", flexDirection:'column', alignItems:"center", justifyContent:"center", marginTop:"3rem" }} >

                            <TextField
                                className='text_field_style1'
                                label="New Password"
                                value={newPassword}
                                type='password'
                                onChange={handleNewPaswordChange}
                            />

                            <TextField
                                style={{ marginTop:"1rem" }}
                                className='text_field_style1'
                                label="Confirm Password"
                                value={confirmPassword}
                                type='password'
                                onChange={handleConfoirmPaswordChange}
                            />

                            <Grid item xs={12} style={{ marginTop: '2rem', display:"flex", alignItems:"center", justifyContent:'center' }}>
                                {
                                    loader?
                                    <CircularProgress style={{ color:"black" }} />
                                    :
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        style={{ backgroundColor:'black', alignSelf:"center"}}
                                        onClick={updatePassword}
                                    >
                                        Submit
                                    </Button>
                                }
                            </Grid>
                        </div>
                }
            </div>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={error}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                style={{ marginBottom:"5rem", marginRight:"5rem" }}
            />
        </div>
    )
}

export default ResetPassword;