import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    FormHelperText
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
// Local
import "./AddClientComp.css";
import { user } from '../LocalDB';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseUrl } from '../Components/BaseUrl';
import { baseUrl } from '../Pages/Server/baseUrls';

const UpdateTransaction = ({updateData,locations, developers, property_types,xCLoseModal}) => {
    const [image, setImage] = useState(null);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [dob, setDob] = useState('');
    const [status, setStatus] = useState('Active');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [documentName, setDocumentName] = useState(null);
    const [document, setDocument] = useState(null);

    const [countries,setCountries] = useState([])
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});
    const [password,setPassword] =useState('')

    const [projectName, setProjectName] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [price, setPrice] = useState('');
    const [date, setDate] = useState('');
    const [location, setLocation] = useState('');
    const [property_type, setPropertyType] = useState('');
    const [area_size, setAreaSize] = useState('');

    const token = useSelector((state) => state.login.token);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
            'Content-Type': 'multipart/form-data'
        },
    };

    useEffect(() => {
        if (updateData && updateData != null) {
        
        
        setLocation(updateData.location_id ? updateData.location_id : '' );
        setPropertyType(updateData.property_type_id ? updateData.property_type_id : '' );
        setBedrooms(updateData.bedrooms !="" ? updateData.bedrooms : '' )
        setProjectName(updateData.projectName ? updateData.projectName : '' )
        setPrice(updateData.price ? updateData.price : '' )
        setDate(updateData.date ? updateData.date : '' )
        setAreaSize(updateData.area_size ? updateData.area_size : '' )



    
       

          
        } else {
          console.log("no proper");
        }
      }, [updateData]);

      const formattedDate = date.split(' ')[0];
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };

    const handlePasswordChange = (event) =>{
        setPassword(event.target.value)
    }
    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
      };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
     
    };
  
    const handleDobChange = (event) => {
      setDate(event.target.value);
    };
  
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
    };
  
    const handleAddressChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };


    const validate = () => {
        let input = inputs;
        let errors = {};
        let isValid = true;
        if (!projectName) {
          isValid = false;
          errors["projectName"] = "Project Name is required";
        }

        if (!property_type) {
            isValid = false;
            errors["property_type"] = "Property type is required";
          }

        if (!location) {
            isValid = false;
            errors["location"] = "Location is required";
          } 

          if (!price) {
            isValid = false;
            errors["price"] = "Price is required";
          }

      
       

        setErrors(errors)
        return isValid;
      };
  
    const handleProfileUpdate = () => {

        if(validate()){

        const data =
        {
            id:updateData.id,
            projectName:projectName,
            bedrooms:bedrooms,
            location:location,
            property_type:property_type,
            date:date,
            price:price,
            area_size:area_size

        }
     
        
   
    // if(validate()){
    //   const formData = new FormData();
    //   formData.append('name', name);
    //   formData.append('phone', phoneNumber);
    //   formData.append('email', email);
    //   formData.append('dob', dob);
    //   formData.append('status', status);
    //   formData.append('address', address);
    //   formData.append('country', country);
    //   formData.append('image', image); // Assuming image is a File object
    //   formData.append('document', document); // Assuming document is a File object
    //   formData.append('password', password);
      
      axios.post(baseUrl+"/api/updatetransaction", data, {
          headers: {
              'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
              ...axiosConfig.headers // Include any other headers
          }
      })
      .then((res) => {
        if(res.data.success){
            xCLoseModal()

        }
      })
      .catch((error) => {
       
        xCLoseModal()
          console.error("Error:", error);
      });
      xCLoseModal()
    }
    }


    // Function to handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
        setImage(reader.result);
        };

        if (file) {
        reader.readAsDataURL(file);
        }
    };

    // Function to handle image update
    const handleImageUpdate = () => {
        // Implement your image update logic here, for example, send the image to the server
        console.log('Image Updated:', image);
    };

    const handleDocumentChange = (e) => {
        const file = e.target.files[0];
        setDocument(file);
        setDocumentName(file.name)
    };

    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='addClient-container' >
                {/* <h1> User Acount </h1> */}
                {/* <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography> */}

            

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Select Type"
                                value={property_type}
                                onChange={(e) => setPropertyType(e.target.value)}
                            >
                                {property_types.map((property_type,index)=>(
                                    <MenuItem key={index} value={property_type.id}>{property_type.name}</MenuItem>
                                ))}
                                
                            </Select>
                            <FormHelperText className='text-danger'>{errors.property_type}</FormHelperText>

                        </FormControl>
                       
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl fullWidth>
                            <InputLabel>No of Bedrooms</InputLabel>
                            <Select value={bedrooms}  onChange={(e) => setBedrooms(e.target.value)}>
                           
                            <MenuItem value="-1" >Studio </MenuItem>
                            <MenuItem value="1" >1 Bedroom </MenuItem>
                            <MenuItem value="2" >2 Bedrooms </MenuItem>
                            <MenuItem value="3" >3 Bedrooms </MenuItem>
                            <MenuItem value="4" >4 Bedrooms </MenuItem>
                            <MenuItem value="5" >5 Bedrooms </MenuItem>
                            <MenuItem value="6" >6 Bedrooms </MenuItem>
                            <MenuItem value="7" >7 Bedrooms </MenuItem>
                            <MenuItem value="8" >8 Bedrooms </MenuItem>
                            <MenuItem value="9" >9 Bedrooms </MenuItem>
                            <MenuItem value="10" >10 Bedrooms </MenuItem>
                                </Select>
                     
                            <FormHelperText className='text-danger'>{errors.bedrooms}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

           
               

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Project name"
                            value={projectName}
                            onChange={(e) =>setProjectName(e.target.value)}
                        />
                          <FormHelperText className='text-danger'>{errors.projectName}</FormHelperText>

                    </Grid>

                    
                    <Grid item xs={12} md={12} lg={6} xl={6} >
                    <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Location</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Select Location"
                                value={location}
                                onChange={(e) =>setLocation(e.target.value)}
                            >
                                {locations.map((location,index)=>(
                                    <MenuItem key={index} value={location.value}>{location.label}</MenuItem>
                                ))}
                                
                            </Select>
                        </FormControl>
                        <FormHelperText className='text-danger'>{errors.location}</FormHelperText>

                       
                    </Grid>

                  
                </Grid>

                <Grid item xs={12} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Price"
                            value={price}
                            onChange={(e) =>setPrice(e.target.value)}
                        />
                        <FormHelperText className='text-danger'>{errors.price}</FormHelperText>

                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6} style={{ marginTop: '2rem' }}>
                        <TextField
                            fullWidth
                            label="Area in sqf"
                            value={area_size}
                            onChange={(e) =>setAreaSize(e.target.value)}
                        />
                    </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                <Typography  >
                            Date  
                        </Typography>
                        <TextField
                            fullWidth
                            type="date"
                            // label="Change DOB"
                            value={formattedDate}
                            onChange={handleDobChange}
                            size='small'
                        />
                        </Grid>
     </Grid>

                <Grid item xs={12} style={{ marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleProfileUpdate}
                    >
                        Update Transaction
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default UpdateTransaction;