import React from 'react';
import { Container, Typography, Box, List, ListItem, ListItemIcon, ListItemText, Accordion, AccordionSummary, AccordionDetails } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const HelpAndSupport = () => {
  const faqs = [
    {
      question: "How can I contact customer support?",
      answer: "You can contact us via email at info@kestates.ae or call us at (04) 529 9724."
    },
    {
      question: "What are your office hours?",
      answer: "Our office hours are from 9 AM to 6 PM, Sunday to Thursday."
    },
    {
      question: "Where is your office located?",
      answer: "Our office is located at 402, Park Heights Square 1, Dubai Hills, 12205 Dubai."
    },
    {
      question: "Do you offer support on weekends?",
      answer: "Currently, our support is available only on weekdays. For urgent matters, please contact us via email."
    },
    {
      question: "Can I schedule an appointment online?",
      answer: "Yes, you can schedule an appointment through our website or by contacting our support team."
    }
  ];

  return (
    <Container maxWidth="md" sx={{ mt: 4 }}>
      <Typography variant="h4" align="center" gutterBottom>
        Help and Support
      </Typography>
      <Typography variant="body1" paragraph>
        At K Estates, we are dedicated to providing exceptional support to our clients. If you have any questions or need assistance, please feel free to reach out to us using the contact information below.
      </Typography>
      <Box sx={{ mb: 4 }}>
        <List>
          <ListItem>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Email" secondary="info@kestates.ae" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <PhoneIcon />
            </ListItemIcon>
            <ListItemText primary="Phone" secondary="(04) 529 9724" />
          </ListItem>
          <ListItem>
            <ListItemIcon>
              <LocationOnIcon />
            </ListItemIcon>
            <ListItemText primary="Location" secondary="402, Park Heights Square 1, Dubai Hills, 12205 Dubai" />
          </ListItem>
        </List>
      </Box>
      <Typography variant="h5" gutterBottom>
        Frequently Asked Questions
      </Typography>
      {faqs.map((faq, index) => (
        <Accordion key={index}>
          <AccordionSummary expandIcon={<ExpandMoreIcon />}>
            <Typography>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Typography>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Container>
  );
}

export default HelpAndSupport;
