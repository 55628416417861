import React, { useEffect, useState } from 'react';
import { Grid, Typography, Card, CardContent, Button, Avatar, Container } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import XIcon from '@mui/icons-material/X';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import LocalPhoneIcon from '@mui/icons-material/LocalPhone';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import './index.css';
import profileImage from "../../Images/img.jpeg";
import { user } from '../../LocalDB';
import { useSelector } from 'react-redux';
import { httpRequest } from '../Server/Axios';
import { GET_USER_DETAIL_API } from '../Server/Apis';
import LottieAnimationLoader from '../../Components/lottieAnimation';
import { ownerDocumentsUrl, profileImageBaseUrl, propertyDocumentsUrl } from '../Server/baseUrls';
import moment from 'moment';

const Account = () => {
    const userInfo = useSelector((state) => state.login.user);
    const tokenInfo = useSelector((state) => state.login.token);

    const [userDetail, setUserDetail] = useState({});
    const [loader, setLoader] = useState(false);

    // console.log("****** Profile Image ***** ", userInfo.profile_image);

    const profileImage = userInfo.profile_image == null ? null : profileImageBaseUrl+"/"+userInfo.profile_image;

    console.log("****** userDetail ***** ", userDetail);


    useEffect(() => {
        getUserDetails()
    }, []);

    const getUserDetails = async() => {
        setLoader(true)
        const API_GET_USER_DETAILS_CONFIG = GET_USER_DETAIL_API(tokenInfo);
        
        try {
            const response = await httpRequest(API_GET_USER_DETAILS_CONFIG);
            setUserDetail(response)
            setLoader(false)
        } catch (error) {
            setLoader(false)
        }
    }

    return (
            // <Container maxWidth={'xl'}>
            //     <div className='account-container' >
            //     <Grid container spacing={1} style={{ marginTop: '2rem' }}>
            //         <Grid item xs={12} md={4} lg={4} xl={4} className='avatar' flexDirection={'column'} >
                       
            //             {
            //                 true?
            //                     <Avatar style={{ width: '15rem', height: '15rem', borderWidth:'0.15rem', backgroundColor: 'GrayText'}}>
            //                       <Typography style={{ fontSize:'10rem', fontWeight: '600', fontFamily:'Montserrat'}} >
            //                         {user.name.slice(0,1)}
            //                       </Typography>
            //                     </Avatar>
            //                     :
            //                     <Avatar style={{ width: '10rem', height: '10rem', borderWidth:'0.15rem', borderColor:'white', borderStyle:'solid', marginBottom:'2rem', marginTop:'2rem'}}>
            //                         <img src={profileImage} alt="User"  />
            //                     </Avatar>
            //                }
            //             <Typography variant="body1" style={{ color:'white', fontFamily:'Montserrat', marginTop:'1rem'}} >{user.name}</Typography>
            //             <Typography variant="body1" style={{ color:'white', fontFamily:'Montserrat'}} >{user.address}</Typography>
            //         </Grid>
            //         <Grid item xs={12} md={8} lg={8} xl={8} className='avatar-info' >
            //             <div className='haeding-decoration' >
            //                 <Typography variant="h5" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Information </Typography>
            //             </div>

            //             <Grid container spacing={1} className='subcategory'>
            //                 <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <Typography variant="body2" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Email</Typography>
            //                     <Typography variant="subtitle1" style={{ fontFamily:'Montserrat', fontWeight:'300' }} > {user.email}</Typography>
            //                 </Grid>
                            
            //                 <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <Typography variant="body2" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Phone</Typography>
            //                     <Typography variant="subtitle1" style={{ fontFamily:'Montserrat', fontWeight:'300' }} > {user.phone}</Typography>
            //                 </Grid>
            //             </Grid>

            //             <Grid container spacing={1} className='subcategory'>
            //                 <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <Typography variant="body2" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > DOB</Typography>
            //                     <Typography variant="subtitle1" style={{ fontFamily:'Montserrat', fontWeight:'300' }} > {user.dob}</Typography>
            //                 </Grid>
                            
            //                  <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <Typography variant="body2" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Status</Typography>
            //                     <Typography variant="subtitle1" style={{ fontFamily:'Montserrat', fontWeight:'300' }} > {user.status}</Typography>
            //                 </Grid>
            //             </Grid>

            //             <div className='haeding-decoration' >
            //                 <Typography variant="h5" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Property </Typography>
            //             </div>

            //             <Grid container spacing={1} className='subcategory'>
            //                 <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <Typography variant="body2" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Total Properties</Typography>
            //                     <Typography variant="subtitle1" style={{ fontFamily:'Montserrat', fontWeight:'300' }} > 6 </Typography>
            //                 </Grid>
                            
            //                 <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <Typography variant="body2" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Listing with k Estates</Typography>
            //                     <Typography variant="subtitle1" style={{ fontFamily:'Montserrat', fontWeight:'300' }} > 2 </Typography>
            //                 </Grid>
            //             </Grid>

            //             <div className='haeding-decoration' >
            //                 <Typography variant="h5" style={{ fontFamily:'Montserrat', fontWeight:'600' }} > Connect </Typography>
            //             </div>

            //             <Grid container spacing={1} className='subcategory'>
            //                 <Grid item xs={12} md={6} lg={6} xl={6} style={{padding:'1rem'}} >
            //                     <FacebookIcon />
            //                     <InstagramIcon style={{ marginLeft: '1rem' }} />
            //                     <XIcon style={{ marginLeft: '1rem' }} />
            //                     <LinkedInIcon style={{ marginLeft: '1rem' }}  />
            //                 </Grid>
                            
            //             </Grid>

            //         </Grid>
            //     </Grid>
            //     <div style={{height: '10rem'}} />
            //     </div>
            // </Container>

            <>
                <div className='top-container' >
                    {/* <div className='sub-container' maxWidth > */}
                    <Container style={{ maxWidth:'xl', display:'flex', alignItems:"center", justifyContent:"flex-start" }}>  
                        {/* {
                            true?
                                <Avatar className='avatar-1'  style={{ width: '10rem', height: '10rem', }} >
                                    <Typography style={{ fontSize:'10rem', fontWeight: '600', fontFamily:'Montserrat'}} >
                                        {user.name.slice(0,1)}
                                    </Typography>
                                </Avatar>
                                :
                                <Avatar className='avatar-2' style={{ width: '10rem', height: '10rem'}} >
                                    <img src={profileImage} alt="User"  />
                                </Avatar>
                        }
                        <Typography variant="body1" style={{ color:'white', fontFamily:'Montserrat', marginTop:'1rem'}} >{user.name}</Typography> */}
                    </Container>
                    {/* </div> */}
                </div>
                <Container maxWidth={'xl'}>
                    <div className='account-container' >
                        <Grid container spacing={1} className='account_main_style'>
                            <Grid item xs={12} sm={12} md={6} lg={6} xl={6}  style={{ display:'flex', flexDirection:"row", alignItems:'center', justifyContent:'flex-start' }} >
                                {
                                    profileImage ?
                                        <Avatar className='avatar-1' src={profileImage} style={{ width: '8rem', height: '8rem', }} />
                                        :
                                        <Avatar className='avatar-1' style={{ width: '8rem', height: '8rem', backgroundColor:'black'}} >
                                            <Typography style={{ color:'white', fontSize:'5rem', fontWeight: '600', fontFamily:'Montserrat'}} >
                                                { !userInfo.name ? " " : userInfo.name.slice(0,1)}
                                            </Typography>
                                        </Avatar>
                                }
                                <div>
                                    <Typography variant="subtitle1" style={{ color:'black', fontFamily:'Montserrat', marginLeft:'1rem'}} >{ !userInfo.name ? " -- " : userInfo.name}</Typography>
                                    <Typography variant="body2" style={{ color:'black', fontFamily:'Montserrat', marginLeft:'1rem'}} >{ !userDetail.email ? " -- " : userDetail.email}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}  className='contact_info_style' >
                                <div>
                                    <div style={{ display:'flex', flexDirection:"row", alignItems:'center', justifyContent:'flex-start' }}>
                                        <LocalPhoneIcon style={{ color:'gray' }} />
                                        <Typography variant="subtitle1" style={{ color:'black', fontFamily:'Montserrat', marginLeft:'1rem'}} >{ !userDetail.phone ? " -- " : "+"+userDetail.phone}</Typography>
                                    </div>
                                    <div style={{ display:'flex', flexDirection:"row", alignItems:'center', justifyContent:'flex-start' }}>
                                        <CalendarMonthIcon style={{ color:'gray' }} />
                                        {/* <Typography variant="subtitle1" style={{ color:'black', fontFamily:'Montserrat', marginLeft:'1rem'}} >{ !userInfo.dob ? " -- " : moment(userInfo.dob).format('Do MMM YYYY')}</Typography> */}
                                        <Typography variant="subtitle1" style={{ color:'black', fontFamily:'Montserrat', marginLeft:'1rem'}} >{ !userDetail.dob ? " -- " : moment(userDetail.dob).format('Do MMM YYYY')}</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <div style={{ marginTop:'3rem', }}>
                            <Typography variant="h4" style={{ color:'black', fontFamily:'Montserrat'}} >Valued Client with K Estates</Typography>
                            {/* <Typography variant="subtitle1" style={{ fontSize:'0.85rem', fontWeight:'normal', color:'black', fontFamily:'Montserrat', marginTop:"1rem"}} >{!userInfo.name ? " He " : userInfo.name} is one of our most valued clients at K Estates. His dedication to finding the perfect property aligns with our commitment to providing exceptional real estate services. {!userInfo.name ? " He " : userInfo.name} has shown remarkable insight and discernment in his property selections, and we are proud to support him in his real estate endeavors.</Typography> */}
                        </div>

                        <div style={{ minHeight:'25rem',marginTop:"3rem",}} >
                            {
                                loader?
                                <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                    <LottieAnimationLoader />
                                </div>
                                :
                                <>
                                    {/* <Grid container spacing={1} style={{display:'flex', flexDirection:"row", alignItems:'center', justifyContent:'space-between',  marginTop:'3rem' }}> */}
                                    <Grid container spacing={1} className='user_details_style'>
                                        <Grid item /*xs={12} sm={12} md={4} lg={4} xl={4} */  className='box-style' display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} >
                                            <Typography variant="h5" style={{ textAlign:'center', color:'black', fontFamily:'Montserrat'}} >{userDetail.nationality ?  userDetail.nationality : " -- "}</Typography>
                                            {/* <Typography style={{ fontSize:'0.75rem', fontWeight:'normal', color:'black', fontFamily:'Montserrat'}} >Residence</Typography> */}
                                            <Typography style={{ fontSize:'0.75rem', fontWeight:'normal', color:'black', fontFamily:'Montserrat'}} >Nationality</Typography>
                                        </Grid>
                                        <Grid item /*xs={12} sm={12} md={4} lg={4} xl={4} */  className='box-style' display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} >
                                            <Typography variant="h5" style={{ color:'black', fontFamily:'Montserrat'}} >{" -- "}</Typography>
                                            <Typography style={{ fontSize:'0.75rem', fontWeight:'normal', color:'black', fontFamily:'Montserrat'}} >Relations with K Estates</Typography>
                                        </Grid>
                                        <Grid item /*xs={12} sm={12} md={4} lg={4} xl={4} */  className='box-style' display={'flex'} flexDirection={'column'} alignItems={'center'} justifyContent={'center'} >
                                            <Typography variant="h5" style={{ color:'black', fontFamily:'Montserrat'}} >{userDetail.properties_count ?  userDetail.properties_count : " -- "}</Typography>
                                            <Typography style={{ fontSize:'0.75rem', fontWeight:'normal', color:'black', fontFamily:'Montserrat'}} >Total Investments</Typography>
                                        </Grid>
                                    </Grid>

                                    <div style={{ marginTop:'5rem', }}>
                                        <Typography variant="h4" style={{ color: 'black', fontFamily: 'Montserrat' }}>Documents & Agreement</Typography>
                                            {
                                                userDetail.documents && userDetail.documents.length !== 0 ? 
                                                    userDetail.documents.map((item, index) => {
                                                        return(
                                                            <Typography variant="subtitle1" key={index} style={{ fontSize: '0.85rem', fontWeight: 'normal', fontFamily: 'Montserrat', marginTop: "1rem" }}>
                                                                <a href={`${ownerDocumentsUrl}/${item.document}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}>
                                                                    {item.document}
                                                                </a> to view documents.
                                                            </Typography>
                                                        )
                                                    })
                                                    :
                                                    <Typography variant="subtitle1" style={{ fontSize: '0.85rem', fontWeight: 'normal', color: 'black', fontFamily: 'Montserrat', marginTop: "1rem" }}>
                                                        No documents available
                                                    </Typography>
                                            }
                                    </div>
                                </>
                            }
                        </div>
                    </div>  
                </Container>
            </>
    );
};

export default Account;
