import React, { useEffect } from 'react';
import "./index.css";
import { Grid, TextField, Button, FormControl, InputLabel, Select, MenuItem, Container } from '@mui/material';
import ListingComponent from '../../Components/ListingComponent';
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Modal from '@mui/material/Modal';
import { Slide, Fade, Zoom } from 'react-slideshow-image';
import ListingModalComponent from '../../Components/ListingModalComp';
import PropertyComp from '../../Components/PropertyComp';

const style = {
  position: 'absolute',
  display:'flex',
  flexDirection:'column',
  alignItems:'center',
  justifyContent:'center',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: "100%",
  bgcolor: 'background.paper',
  padding:'1rem',
  boxShadow: 10,
};

  const ListingData= [
    {
        id:0,
        xSlideImagesArr:[
            {
                url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        
           
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },
    {
        id:1,
        xSlideImagesArr:[
            
             
            {
                url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 1'
            },
            {
                url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 2'
            },
            {
                url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },
    {
        id:2,
        xSlideImagesArr:[
          
            {
                url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        
            {
                url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 1'
            },
            {
                url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 2'
            },
            {
                url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },
    {
        id:3,
        xSlideImagesArr:[
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        
            {
                url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 1'
            },
            {
                url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 2'
            },
            {
                url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
         
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },
    {
        id:4,
        xSlideImagesArr:[
            {
                url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        
            {
                url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 1'
            },
            {
                url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 2'
            },
            {
                url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },
    {
        id:5,
        xSlideImagesArr:[
            {
                url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        
            {
                url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 1'
            },
            {
                url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 2'
            },
            {
                url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },
    {
        id:6,
        xSlideImagesArr:[
            {
                url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        
            {
                url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 1'
            },
            {
                url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 2'
            },
            {
                url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
            {
                url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Property 1",
        xRefNumber:"KE 123456",
    },

  ]

  const divStyle = {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundSize: 'cover',
    // height: '400px',
    // height: '15rem',
    // width:"100%"
    height:'15rem',
    width:'100%'
  }

const Listings = () => {
    const navigate = useNavigate();
    const [open, setOpen] = React.useState(false);
    const [modalData, setModalData] = React.useState({});
    
    const handleOpen = (item) => {
        setOpen(true)
        setModalData(item);
    };
    const handleClose = () => {
        setOpen(false);
        // setModalData({})
    };
    return(
        <Container maxWidth={"xl"} >
            <div className="listing-container" style={{ marginLeft:'-1rem' }} >

                <Grid container spacing={2} style={{ width:'100%', display:'flex', alignItems:"center", justifyContent:"space-between"}} >

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <TextField
                            fullWidth
                            variant="outlined"
                            placeholder="Search..."
                        />
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3} >
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Bedroom</InputLabel>
                            <Select defaultValue="" label="Bedroom">
                                <MenuItem value="">Any</MenuItem>
                                <MenuItem value={1}>1</MenuItem>
                                <MenuItem value={2}>2</MenuItem>
                                <MenuItem value={3}>3</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Property Type</InputLabel>
                            <Select defaultValue="" label="Property Type">
                                <MenuItem value="">Any</MenuItem>
                                <MenuItem value="villa">Villa</MenuItem>
                                <MenuItem value="apartment">Apartment</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel>Property For</InputLabel>
                            <Select defaultValue="" label="Property For">
                                <MenuItem value="">Any</MenuItem>
                                <MenuItem value="buy">Buy</MenuItem>
                                <MenuItem value="rent">Rent</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                {/* <Grid container spacing={'3rem'}  width={'100%'}>
                    {

                        ListingData.map((property) => {
                            return (
                                <ListingComponent
                                    key={property.id}
                                    xSlideImagesArr={property.xSlideImagesArr}
                                    xArea={property.xArea}
                                    xDescription={property.xDescription}
                                    xNoOfBath={property.xNoOfBath}
                                    xNoOfBeds={property.xNoOfBeds}
                                    xPropertyName={property.xPropertyName}
                                    xRefNumber={property.xRefNumber}
                                    xOnpressListing={() => { navigate("/ListingsDetails", {state: {propertyDetails: property}})}} // You can define your custom action here
                                    xOnpressListingWithKestates={() => handleOpen(property)}
                                />
                            );
                        })
                    }
                
                </Grid> */}

                <ListingComponent 
                // xOnpressListing={(property) => { navigate("/ListingsDetails", {state: {propertyDetails: property}})}} // You can define your custom action here
                xOnpressListing={(property) => { navigate("/Listing_Detail", {state: {propertyDetails: property}})}} // You can define your custom action here
                xOnpressListingWithKestates={(property) => handleOpen(property)}
                />


                {
                    <Modal
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="modal-modal-title"
                        aria-describedby="modal-modal-description"
                        className='modal-style'
                    >
                        <Box sx={style}> 
                            <ListingModalComponent
                                xSlideImagesArr={ modalData? modalData.xSlideImagesArr : 
                                    [  
                                        {
                                            url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
                                            caption: 'Slide 3'
                                        },
                                    ]
                                }
                                xArea={ modalData ? modalData.xArea : "N/A"}
                                xNoOfBath={ modalData ? modalData.xNoOfBath : "N/A"}
                                xNoOfBeds={ modalData ? modalData.xNoOfBeds : "N/A"}
                                xPropertyName={ modalData ? modalData.xPropertyName : "N/A"}
                                xRefNumber={ modalData ? modalData.xRefNumber : "N/A"}
                            />
                            <div>
                                <Button variant='contained' color='inherit' style={{ marginTop: '1rem' }} onClick={() => handleClose()} >
                                    cancel
                                </Button>

                                <Button variant='contained' color='info' style={{ marginTop: '1rem', marginLeft: '1rem' }} onClick={() => handleClose()} >
                                    confirm
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                }
            </div>
        </Container>
    )
}

export default Listings;