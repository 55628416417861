import React from "react";
import { useSelector } from "react-redux";
// import { ThemeProvider } from "@emotion/react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";

// local modules
// import theme from "../Theme";
import { persistor, store } from "../Redux/store";
import Login from "../Pages/Login/login";
import Layout from "../Pages/Layout/layout";
import ProjectRoutes from "../Routes";

const App2 = () => { 
  const userInfo = useSelector((state) => state.login.user);
  const tokenInfo = useSelector((state) => state.login.token);
  // console.log("*********** USER INFO ********** ", userInfo);
  // console.log("*********** Token INFO ********** ", tokenInfo);
  return userInfo !== null ? <Layout/> : <Login/>;
  // return true ? <Layout/> : <Login/>;
};
const App = () => {
  return (
    // <ThemeProvider theme={theme}>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <ProjectRoutes />
        </PersistGate>
      </Provider>
    // </ThemeProvider>
  );
};

export default App;
