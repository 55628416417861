import React, { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Container,
    Avatar,
    FormHelperText
} from '@mui/material';
import ImageCompressor from 'image-compressor.js';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './AddPropertyComp.css';
import ImageUploading from 'react-images-uploading';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation } from 'react-router-dom';
import { AmountSaperator } from '../Utilities';
import axios from 'axios';
import { BaseUrl } from '../Components/BaseUrl';
import { useSelector } from 'react-redux';
import { BaseImageUrl } from '../Components/BaseImageUrl';
import { baseUrl, profileImageBaseUrl } from '../Pages/Server/baseUrls';


import CurrencyInput from 'react-currency-input-field';
import BeatLoader from "react-spinners/BeatLoader";
import SaveIcon from '@mui/icons-material/Save';
import { Divider } from 'antd';

const AddPropertyComp = ({ owner, locations, developers, property_types, xCloseModal }) => {



    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
        },
    };

    const { state } = useLocation();
    const [propertyImages, setPropertyImages] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [totalArea, setTotalArea] = useState('');
    const [location, setLocation] = useState('');
    const [developer, setDeveloper] = useState('')
    const [listingType, setListingType] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [expectedPrice, setExpectedPrice] = useState('');
    const [showimages, setShowImages] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedData, setSelectdData] = useState([])
    const [owners, setOwners] = useState([])
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [unitno, setUnitno] = useState('');
    const [handover,setHandover] = useState("")
    const [titledeed,setTitledeed] = useState(null)
    const [subPropertyName, setSubPropertyName] = useState('');
    const [floorplan,setFloorplan] = useState(null)

    const handleUserChange = (event) => {
        setSelectedUsers(event.target.value);
    };

    const compressImages = async () => {
        const compressedImages = [];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            const options = {
                maxWidth: 800,
                maxHeight: 600,
                quality: 0.7, // Adjust compression quality as needed (0.7 means 70%)
                success(result) {
                    compressedImages.push(result);
                },
                error(e) {
                    console.error(e.message);
                },
            };

            await new ImageCompressor(file, options).compress();
        }
    }

    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
        const newimages = imageList.map(option => option.file);
        setShowImages(imageList);
        setImages(newimages)
    };




    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setPropertyImages(files);
    };

    const handleFormSubmit = () => {
        // Implement form submission logic here
        //   console.log('Form submitted:', {
        //     propertyImages,
        //     propertyName,
        //     bedrooms,
        //     bathrooms,
        //     totalArea,
        //     location,
        //     listingType,
        //     propertyType,
        //     expectedPrice,
        //   });
    };



    const handleAutocompleteChange = (event, value) => {
        const ids = value.map(option => option.id);
        setSelectedUsers(ids);
    }

    const handleHandover = (e) =>{
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (file && !allowedTypes.includes(file.type)){
            const newErrors = {};
            newErrors.handover = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
            setErrors(newErrors);
            e.target.value = null;
            setHandover(null)
            return 
        }else{
            setHandover(file)
        }
       
    }

    const handleTtitledeed = (e) =>{
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (file && !allowedTypes.includes(file.type)){
            const newErrors = {};
            newErrors.titledeed = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
            setErrors(newErrors);
            e.target.value = null;
            setTitledeed(null)
            return 
        }else{
            setTitledeed(file)
        }
     }

     const handleFloorplan = (e) =>{
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (file && !allowedTypes.includes(file.type)){
            const newErrors = {};
            newErrors.floorplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
            setErrors(newErrors);
            e.target.value = null;
            setFloorplan(null)
            return 
        }else{
            setFloorplan(file)
        }
     }

    const validateForm = () => {
        const newErrors = {};
        if (!propertyName) newErrors.propertyName = 'Property name is required';
        // if (!bedrooms) newErrors.bedrooms = 'Number of bedrooms is required';
        // if (!bathrooms) newErrors.bathrooms = 'Number of bathrooms is required';
        if (!totalArea) newErrors.totalArea = 'Total area is required';
        if (!location) newErrors.location = 'Location is required';
        if (!developer) newErrors.developer = 'Developer is required';
        if (!expectedPrice) newErrors.expectedPrice = 'Expected price is required';
        if (!propertyType) newErrors.propertyType = 'Property type is required';
        if (!listingType) newErrors.listingType = 'Listing type is required';
        if (!unitno) newErrors.unitno = 'Unitno  is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async () => {

        if (!validateForm()) return;
        setLoading(true)
        const formData = new FormData();
        // images.forEach((file, index) => {
        //     formData.append(`images[${index}]`, file);
        // });

        selectedUsers.forEach((file, index) => {
            formData.append(`owners[${index}]`, file);
        });


        formData.append("title", propertyName)
        formData.append("sub_title", subPropertyName)
        formData.append("bedrooms", bedrooms)
        formData.append("bathrooms", bathrooms)
        formData.append("area_size", totalArea)
        formData.append("location_id", location)
        formData.append("property_type_id", propertyType)
        formData.append("status", listingType)
        formData.append("price", expectedPrice)
        formData.append("developer_id", developer)
        formData.append("unit_no", unitno)
        formData.append('hand_over', handover); 
        formData.append('titledeed', titledeed); 
        formData.append('floorplan', floorplan); 



        try {
            const response = await axios.post(baseUrl + "/api/properties", formData, axiosConfig);
            if (response.data.success) {
                setLoading(false);
                setUnitno("")
                setBathrooms("")
                setBedrooms("")
                setDeveloper("")
                setExpectedPrice("")
                setListingType("")
                setLocation("")
                setPropertyName("")
                setSelectedUsers([])
                setTotalArea("")
                setFloorplan(null)
                setHandover("")
                setTitledeed(null)
                setSelectedUsers([])
                setSubPropertyName("")
            
                let hasFiles = false;
                const imagesFormData = new FormData();
                images.forEach((file, index) => {
                    if(file && file != undefined){
                        imagesFormData.append(`images[${index}]`, file);
                        hasFiles = true;
                    }
                });

               
                imagesFormData.append("property_id", response.data.property_id);
                if (hasFiles) {
                axios.post(baseUrl + "/api/upload-files", imagesFormData, axiosConfig)
                .then((res) =>{
                    setImages([])
                    setShowImages([])
                    xCloseModal()
                    console.log("images upload",res.data);
                }).catch((err) =>{
                    console.log("err:",err);
                })
            }else{
                console.log("no images");

            }
            
            xCloseModal();
            } else {
                // Handle response with success: false
                setLoading(false);
                console.log("Server response was not successful:", response.data);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error during form submission:", error);
        }
        // axios.post(baseUrl + "/api/properties", formData, axiosConfig)
        //     .then((res) => {
        //         if (res.data.success) {
        //             setLoading(false)
        //             xCloseModal()
        //             setTimeout(() => {
        //                 initialValues();
        //             }, 1000);
        //         }
        //     })
        //     .catch((err) => {
        //         setLoading(false)
        //         console.log("errorr", err);
        //     })
    }

    const initialValues = () => {
        setImages([]);
        setPropertyName("");
        setBedrooms("");
        setTotalArea("");
        setLocation("");
        setPropertyType(1);
        setListingType("Rent");
        setExpectedPrice("");
        setDeveloper("");
        setSelectedUsers([]);
        setBathrooms("")
    }


    return (
        // <Container maxWidth={'xl'} >
        <div  >
            <div className='' >
                <div className="">
                    <ImageUploading
                        multiple
                        value={showimages}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                        }) => (
                            // write your building UI
                            <div className="upload__image-wrapper" >
                                <Button variant="contained" type="submit" size='small' onClick={onImageUpload} style={{ marginLeft: '0rem', backgroundColor: "black", }} >
                                    Click or Drop here
                                </Button>
                                <Button variant="contained" type="submit" size='small' onClick={onImageRemoveAll} style={{ marginLeft: '1rem', backgroundColor: "black", }} >
                                    Remove all images
                                </Button>


                                <Grid container spacing={1} style={{ marginTop: '1rem', width: '100%' }} className='images-list' >
                                    {
                                        imageList.slice(0, 10).map((image, index) => (
                                            <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={3.5} margin={1} >
                                                <div className='image-controller' >
                                                    <ChangeCircleIcon onClick={() => onImageUpdate(index)} color='info' />
                                                    <RemoveCircleIcon onClick={() => onImageRemove(index)} color='error' />
                                                </div>
                                                <img src={image['data_url']} alt="" style={{ width: '100%', height: '100%' }} />
                                            </Grid>
                                        ))}
                                </Grid>
                                <Typography variant='caption' color={'lightgray'} >
                                    Only max 10 images can be shown
                                </Typography>
                            </div>
                        )}
                    </ImageUploading>
                </div>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                        <TextField
                            label="Unit no"
                            value={unitno}
                            onChange={(e) => setUnitno(e.target.value)}
                            fullWidth
                            error={!!errors.unitno}
                            helperText={errors.unitno}
                        />
                    </Grid>
                

                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <TextField
                            label="Total Area"
                            value={totalArea}
                            onChange={(e) => setTotalArea(e.target.value)}
                            fullWidth
                            error={!!errors.totalArea}
                            helperText={errors.totalArea}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Project"
                            value={propertyName}
                            onChange={(e) => setPropertyName(e.target.value)}
                            fullWidth
                            error={!!errors.propertyName}
                            helperText={errors.propertyName}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Phase or building"
                            value={subPropertyName}
                            onChange={(e) => setSubPropertyName(e.target.value)}
                            fullWidth
                            error={!!errors.propertyName}
                            helperText={errors.propertyName}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>

<Grid item xs={12} md={12} lg={6} xl={6}>
    <FormControl fullWidth>
        <InputLabel>Property Type</InputLabel>
        <Select
            value={propertyType}
            onChange={(e) => {
                // console.log(e.target)
                setPropertyType(e.target.value)
            }}
        // defaultValue={PropertyDetails.type}
        >

            {
                property_types.map((e, index) => {
                    return (
                        <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
                    )
                })
            }
        </Select>
        <FormHelperText className='text-danger'>{errors.propertyType}</FormHelperText>
    </FormControl>
</Grid>

<Grid item xs={12} md={12} lg={6} xl={6}>
    <FormControl component="fieldset">
        <FormLabel component="legend">Status</FormLabel>
        <RadioGroup
            row
            value={listingType}
            onChange={(e) => setListingType(e.target.value)}
        >
            <FormControlLabel
                value="Rent"
                control={<Radio color="primary" />}
                label="Rent"
            />
            <FormControlLabel
                value="Sale"
                control={<Radio color="primary" />}
                label="Sale"
            />
            <FormControlLabel
                value="Off_plan"
                control={<Radio color="primary" />}
                label="Off Plan"
            />
            <FormControlLabel
                value="Sold"
                control={<Radio color="primary" />}
                label="Sold"
            />
            <FormControlLabel
                value="Ready"
                control={<Radio color="primary" />}
                label="Ready"
            />
        </RadioGroup>
        <FormHelperText className='text-danger'>{errors.listingType}</FormHelperText>

    </FormControl>
</Grid>
</Grid>
{propertyType==10?null:

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            <InputLabel>No of Bath</InputLabel>
                            <Select
                                value={bathrooms}
                                onChange={(e) => setBathrooms(e.target.value)}
                            >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                        {num} Bathroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                            </Select>
                            <FormHelperText className='text-danger'>{errors.bathrooms}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            <InputLabel>No of Bedrooms</InputLabel>
                            <Select value={bedrooms}  onChange={(e) => setBedrooms(e.target.value)}>
                           
                            <MenuItem value="-1" >Studio </MenuItem>
                            <MenuItem value="1" >1 Bedroom </MenuItem>
                            <MenuItem value="2" >2 Bedrooms </MenuItem>
                            <MenuItem value="3" >3 Bedrooms </MenuItem>
                            <MenuItem value="4" >4 Bedrooms </MenuItem>
                            <MenuItem value="5" >5 Bedrooms </MenuItem>
                            <MenuItem value="6" >6 Bedrooms </MenuItem>
                            <MenuItem value="7" >7 Bedrooms </MenuItem>
                            <MenuItem value="8" >8 Bedrooms </MenuItem>
                            <MenuItem value="9" >9 Bedrooms </MenuItem>
                            <MenuItem value="10" >10 Bedrooms </MenuItem>
                                </Select>
                            {/* <Select
                                value={bedrooms}
                                onChange={(e) => setBedrooms(e.target.value)}
                            >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                        {num} Bedroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={'studio'}>Studio</MenuItem> */}
                            {/* </Select>  */}
                            <FormHelperText className='text-danger'>{errors.bedrooms}</FormHelperText>
                        </FormControl>
                    </Grid>
                </Grid>

}

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        {/* <TextField
                                label="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                            /> */}
                        <FormControl fullWidth>
                            <InputLabel>location</InputLabel>
                            <Select
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                            >
                                {
                                    locations.map((e, index) => {
                                        return (
                                            <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className='text-danger'>{errors.location}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <CurrencyInput
                            style={{
                                width: '100%', padding: '15px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px',
                                fontSize: '16px', transition: 'border-color 0.3s'
                            }}
                            id="input-example"
                            name="input-name"
                            label="Price"
                            placeholder="Price"
                            //   defaultValue={0}
                            value={expectedPrice}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) => setExpectedPrice(value)}
                            error={!!errors.expectedPrice}
                            helperText={errors.expectedPrice}
                        />
                        <div className='text-danger'>{errors.expectedPrice}</div>
                        {/* <TextField
                                label="Price"
                                value={AmountSaperator(expectedPrice)}
                                onChange={(e) => setExpectedPrice(e.target.value)}
                                fullWidth
                                error={!!errors.expectedPrice}
                                helperText={errors.expectedPrice}
                            /> */}
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>

                        <FormControl fullWidth>
                            <InputLabel>Developer</InputLabel>
                            <Select
                                value={developer}
                                onChange={(e) => setDeveloper(e.target.value)}
                            >
                                {
                                    developers.map((e, index) => {
                                        return (
                                            <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className='text-danger'>{errors.developer}</FormHelperText>

                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            {/* <InputLabel id="users-label">Select Users</InputLabel> */}
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={owner}
                                onChange={handleAutocompleteChange}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox


                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <Avatar style={{ marginRight: '10px' }} src={profileImageBaseUrl + "/" + option.profile_image} />

                                        {option.name}


                                    </li>
                                )}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Users" placeholder="" />
                                )}
                            />
                            {/* <Select
                                    labelId="users-label"
                                    id="users"
                                    multiple
                                    value={selectedUsers}
                                    onChange={handleUserChange}
                                    label="Select Users"
                                    size='medium'
                                >
                                    {users.map((user) => (
                                        <MenuItem key={user.id} value={user.id}>
                                            <Avatar src='http://localhost/owners_portal/public/property_images/2.jpg' style={{ marginRight:'0.5rem' }} />
                                            {user.name}
                                        </MenuItem>
                                    ))}
                                </Select> */}
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                            label="Handover"
                            value={handover}
                            onChange={(e) => setHandover(e.target.value)}
                            fullWidth
                            error={!!errors.handover}
                            helperText={errors.handover}
                        />
                        </Grid>
                        </Grid>
                <Grid container spacing={2} style={{ marginTop: '2rem',marginBottom: '5rem' }}>

                   
                {/* <Grid item xs={12} md={12} lg={4} xl={4}>
                <label  class="form-label">Handover</label>
                <input accept=".pdf,.docx,.png,.jpeg,.jpg" class="form-control" type="file" onChange={handleHandover}  />
                <div className='text-danger'>{errors.handover}</div>
                </Grid> */}
                <Grid item xs={12} md={12} lg={6} xl={6}>
                <label  class="form-label">Title deed</label>
                <input accept=".pdf,.docx,.png,.jpeg,.jpg" class="form-control" type="file" onChange={handleTtitledeed} />
                <div className='text-danger'>{errors.titledeed}</div>
                </Grid>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                <label  class="form-label">Floor plan</label>
                <input accept=".pdf,.docx,.png,.jpeg,.jpg" class="form-control" type="file" onChange={handleFloorplan} />
                <div className='text-danger'>{errors.floorplan}</div>
                </Grid>
                </Grid>


                <Button onClick={handleSubmit} variant="contained" type="submit" style={{ marginTop: '1rem', backgroundColor: "black", }} disabled={loading} >
                    {
                        loading ?
                            (
                                <>
                                    <BeatLoader color="white" size={5} />
                                    Processing
                                </>
                            ) : (
                                <>
                                    Add Property
                                </>
                            )
                    }

                </Button>

            </div>
        </div>
        // </Container>
    )
}

export default AddPropertyComp;
