import React, { useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    CssBaseline,
    Paper,
    CircularProgress,
    Snackbar
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import Lottie from 'lottie-react';

//  Local

import "./index.css";
import ResetPasswordAnime from '../../Images/ressetPass.json';
import kestateImage from "../../Images/LoginImage.png"
import { httpRequest } from '../Server/Axios';
import { CHECK_OTP_API, EMAIL_VERIFICATION_API, UPDATE_PASSWORD_API } from '../Server/Apis';


const ForgotPassword = () => {
    const tokenInfo = useSelector((state) => state.login.token);
    const userInfo = useSelector((state) => state.login.user);
    const navigate = useNavigate();

    const [image, setImage] = useState(null);
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [email, setEmail] = useState('');
    const [otp, setOtp] = useState('');
    const [error, setError] = useState('');
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [emailVerified, setEmailVerified] = useState(false);
    const [codeVerified, setCodeVerified] = useState(false);

    const handleChange = (e) => {
        let value = e.target.value;
        if (value.length <= 6) {
          setOtp(value);
        }
    };
  
    const handleNewPaswordChange = (event) => {
      setNewPassword(event.target.value);
    };

    const handleConfoirmPaswordChange = (event) => {
        setConfirmPassword(event.target.value);
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const confirmEmailAPI = async() => {
        const API_EMAIL_VERIFICATION_CONFIG = EMAIL_VERIFICATION_API(email, tokenInfo);
        setLoader(true)
        try {
            const response = await httpRequest(API_EMAIL_VERIFICATION_CONFIG);
            if(response.success){
                setLoader(false)
                setEmailVerified(true)
            }
            else{
                setLoader(false)
                setOpen(true);
                setError("Email doesn't work")
                setEmailVerified(false)
            }
        } catch (error) {
            setLoader(false)
             setOpen(true);
            setError("Something went wrong!")
            setEmailVerified(false)
        }
    }

    const checkOTP = async() => {
        const API_CHECK_OTP_CONFIG = CHECK_OTP_API(email, otp);
        setLoader(true)
        try {
            const response = await httpRequest(API_CHECK_OTP_CONFIG);
            if(response.success){
                setLoader(false)
                setCodeVerified(true)
            }
            else{
                setLoader(false)
                setOpen(true);
                setError("Wrong OTP")
                setCodeVerified(false)
            }
        } catch (error) {
            setLoader(false)
             setOpen(true);
            setError("Something went wrong!")
            setCodeVerified(false)
        }
    }

    const updatePassword = async() => {
        if( newPassword == confirmPassword){
            const API_UPDATE_PASSWORD_CONFIG = UPDATE_PASSWORD_API(email, `${newPassword}`, `${confirmPassword}`);
            setLoader(true)
            try {
                const response = await httpRequest(API_UPDATE_PASSWORD_CONFIG);
                if(response.success){
                    setLoader(false)
                    setOpen(true);
                    setError(response.msg)
                    setTimeout(() => {
                        navigate(-1)
                    }, 1000);
                }
                else{
                    setLoader(false)
                    setOpen(true);
                    setError("Email doesn't work")
                    setCodeVerified(false)
                }
            } catch (error) {
                setLoader(false)
                setOpen(true);
                setError("Something went wrong!")
            }   
        }
        else{
            setOpen(true);
            setError("Password not match...")
        }
    }

    const handleClose = () => {
        setOpen(false);
      };


    return(
       <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid
                item
                xs={false}
                sm={4}
                md={7}
                sx={{
                    backgroundImage: `url(${kestateImage})`, // Set the imported image as background
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }}
            >
            </Grid>
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square style={{ display:'flex', alignItems:"center", justifyContent:"center"}} >
               
                <div className='FP-container' >
                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"flex-start", }} >
                        <div className='avatar_style' >
                            <Lottie animationData={ResetPasswordAnime} loop={true} width={'100%'} height={'100%'} />
                        </div>
                        <div style={{ marginLeft:"0.5rem" }} >
                            <Typography variant="subtitle1" style={{ fontFamily:'Montserrat'}} >K Estates</Typography>
                            <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >Forgot Password</Typography>
                        </div>
                    </div>

                    <div style={{ width:"100%", display:"flex", alignItems:"center", justifyContent:"center", marginTop:"3rem" }} >

                        <TextField
                            className='text_field_style'
                            label="Email Address"
                            value={email}
                            onChange={handleEmailChange}
                            margin="normal"
                            required
                            id="email"
                            name="email"
                            autoComplete="email"
                            autoFocus
                            size='small'
                        />

                    </div>

                    { 
                        emailVerified == false && codeVerified == false && 
                            <Grid item xs={12} style={{ marginTop: '2rem', display:"flex", alignItems:"center", justifyContent:'center' }}>
                                {
                                    loader ? 
                                    <CircularProgress style={{ color:"black" }} />
                                    :
                                    <Button
                                        variant="contained"
                                        // color="primary"
                                        style={{ backgroundColor:'black', alignSelf:"center"}}
                                        size='small'
                                        onClick={confirmEmailAPI}
                                    >
                                        Confirm Email
                                    </Button>
                                }
                            </Grid>
                    }

                    {
                         emailVerified == true && codeVerified == false && //false
                            <div style={{ width:"100%", display:"flex", flexDirection:'column', alignItems:"center", justifyContent:"center", marginTop:"3rem" }}  >
                                <TextField
                                    label="OTP"
                                    variant="outlined"
                                    size="small"
                                    margin="normal"
                                    // type="number"
                                    inputProps={{ inputMode: 'numeric'}}
                                    value={otp}
                                    onChange={handleChange}
                                    style={{ letterSpacing:5 }}
                                    className='text_field_style'
                                />
                                <Grid item xs={12} style={{ marginTop: '2rem', display:"flex", alignItems:"center", justifyContent:'center' }}>
                                    {
                                        loader ? 
                                        <CircularProgress style={{ color:"black" }} />
                                        :
                                        <Button
                                            variant="contained"
                                            // color="primary"
                                            style={{ backgroundColor:'black', alignSelf:"center"}}
                                            onClick={checkOTP}
                                        >
                                            submit
                                        </Button>
                                    }
                                </Grid>
                            </div>
                    }


                    {
                        emailVerified == true && codeVerified == true && //false
                            <div style={{ width:"100%", display:"flex", flexDirection:'column', alignItems:"center", justifyContent:"center", marginTop:"3rem" }} >

                                <TextField
                                    className='text_field_style'
                                    label="New Password"
                                    value={newPassword}
                                    type='password'
                                    onChange={handleNewPaswordChange}
                                />

                                <TextField
                                    style={{ marginTop:"1rem" }}
                                    className='text_field_style'
                                    label="Confirm Password"
                                    value={confirmPassword}
                                    type='password'
                                    onChange={handleConfoirmPaswordChange}
                                />

                                <Grid item xs={12} style={{ marginTop: '2rem', display:"flex", alignItems:"center", justifyContent:'center' }}>
                                    {
                                        loader ?
                                        <CircularProgress style={{ color:"black" }} />
                                        :
                                        <Button
                                            variant="contained"
                                            // color="primary"
                                            style={{ backgroundColor:'black', alignSelf:"center"}}
                                            onClick={updatePassword}
                                        >
                                            Submit
                                        </Button>
                                    }
                                </Grid>
                            </div>
                    }

                </div>
            </Grid>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={error}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
                style={{ marginBottom:"5rem", marginRight:"5rem" }}
            />
        </Grid>
    )
}

export default ForgotPassword;