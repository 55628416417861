import React, { useEffect, useState } from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, TablePagination } from '@mui/material';
import moment from 'moment';
import { AmountSaperator } from '../Utilities';

const rows = [
  // { date: "Feb 2023", amount: 66730 },
  // { date: "March 2023", amount: 42400 },
  // { date: "April 2023", amount: 86500 },
  // { date: "May 2023", amount: 57790 },
  // { date: "June 2023", amount: 76750 },
  // { date: "July 2023", amount: 57650 },
  { date: "Aug 2023", amount: 13400, status: 'Paid' },
  { date: "Sept 2023", amount: 87400, status: 'Paid' },
  { date: "Oct 2023", amount: 66730, status: 'Paid' },
  { date: "Nov 2023", amount: 10977, status: 'Paid' },
  { date: "Dec 2023", amount: 97076, status: 'Paid' },
  { date: "Jan 2024", amount: 34563, status: 'Paid' },
  { date: "Feb 2024", amount: 45345, status: 'Paid' },
  { date: "March 2024", amount: 45345, status: 'Paid' },
  { date: "April 2024", amount: 45345, status: 'Paid' },
  { date: "May 2024", amount: 45345, status: 'Upcoming' },
  { date: "June 2024", amount: 45345, status: 'Upcoming' },
  { date: "July 2024", amount: 45345, status: 'Upcoming' },
];

const TableComponent = ({xData}) => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
      const mediaQuery = window.matchMedia('(max-width: 425px)');
      setIsMobile(mediaQuery.matches);

      const handleResize = () => {
        setIsMobile(mediaQuery.matches);
      };

      mediaQuery.addEventListener('change', handleResize);

      return () => {
        mediaQuery.removeEventListener('change', handleResize);
      };
    }, []);

    const containerStyle = {
      display: isMobile && "none",
      color:'white'
    };
    const containerStyle1 = {
      display: isMobile && "none",
    };

  return (
    <Paper>
      <TableContainer component={Paper} style={{ maxHeight: '400px', overflowY: 'auto' }} >
        <Table>
          <TableHead>
            <TableRow sx={{ backgroundColor: '#222', color: '#fff' }} >
              <TableCell align='center' style={{ color: 'white' }} >No</TableCell>
              <TableCell align='center' style={ containerStyle } >Installment</TableCell>
              <TableCell align='center' style={ containerStyle } >Payment %</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Amount</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Date</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Status</TableCell>
              <TableCell align='center' style={{ color: 'white' }} >Receipt</TableCell>
            </TableRow>
          </TableHead>
        <TableBody>
        {
        xData.map((row, index) => (
            <TableRow key={index}>
                <TableCell align='center' >{row.number_of_installment }</TableCell>
                <TableCell align='center' style={ containerStyle1 }>{row.installment_details}</TableCell>
                <TableCell align='center' style={ containerStyle1 }>{ row.paymnet_perccentage}</TableCell>
                <TableCell align='center' >AED { AmountSaperator(row.amount)} </TableCell>
                <TableCell align='center' >{ row.date? moment(row.date).format('Do MMM YYYY') : ' -- ' }</TableCell>
                <TableCell align='center' >{ row.paid_status}</TableCell>
                <TableCell align='center'  >
                  {
                    row.receipt ? 
                      <a href={`${row.receipt}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}>
                        View
                      </a> 
                      :
                      <p> -- </p>
                  }
                </TableCell>
            </TableRow>
          ))}
        </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default TableComponent;
