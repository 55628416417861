import React from 'react'
import { useSelector } from 'react-redux';
import AdminDashboardArea from '../AdminPanel/dashboardArea';
import Home from "../Home/home";

 function AllDashboard() {
  const token = useSelector((state) => state.login.token);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const user = useSelector((state) => state.login.user);
  return (
    <div>{user.role_id==1?<AdminDashboardArea />:<Home />}</div>
  )
}

export default AllDashboard
