import React, { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Container,
    Snackbar,
    CircularProgress,
} from '@mui/material';
import './index.css';
import ImageUploading from 'react-images-uploading';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation, useNavigate } from 'react-router-dom';
import { LIST_PROPERTY_WITH_K_ESTATES } from '../Server/Apis';
import { useSelector } from 'react-redux';
import { httpRequest } from '../Server/Axios';
import { propertyImagesUrl } from '../Server/baseUrls';

const Listings_Listed_With_K_Estates = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const PropertyDetails = state.propertyDetails;
    const tokenInfo = useSelector((state) => state.login.token);

    const [propertyImages, setPropertyImages] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [totalArea, setTotalArea] = useState('');
    const [refNo, setRefNo] = useState('');
    const [status, setStatus] = useState(PropertyDetails.status);
    // const [Status, setStatus] = useState('rent');
    const [propertyType, setPropertyType] = useState(PropertyDetails.property_type);
    const [expectedPrice, setExpectedPrice] = useState(PropertyDetails.price);
    const [images, setImages] = useState([]);
    const [loader, setLoader] = useState(false);
    const [open, setOpen] = useState(false);
    const [error, setError] = useState('');
    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
      // data for submit
      setImages(imageList);
      setPropertyImages(imageList);
    //   const newImage = images.find((data) => {
    //     if(data.data_url !== imageList.data_url){
    //         return data.data_url;
    //     }
    //   })
    const nonSimilarValues = images.filter(obj1 => !imageList.some(obj2 => obj1.data_url === obj2.data_url)).concat(imageList.filter(obj2 => !images.some(obj1 => obj1.data_url === obj2.data_url)));

    // console.log("******** nonSimilarValues *********** ", nonSimilarValues);

    };


    useEffect(() => {
        const imageURLs = PropertyDetails.images.map((e) => ({
        //   data_url: e.url
          data_url: `${ propertyImagesUrl+"/"+e.name}`
        }));
        setImages(imageURLs);
        // setPropertyName(PropertyDetails.title);
        // setBedrooms(PropertyDetails.bedrooms)
        // setBathrooms(PropertyDetails.xNoOfBath)
        // setTotalArea(PropertyDetails.area_size)
        // setRefNo(PropertyDetails.xRefNumber)
        // setStatus(PropertyDetails.status)
        // setPropertyType({ name:PropertyDetails.type, value: undefined })
        // setExpectedPrice(PropertyDetails.xPrice)
        // setPropertyType(PropertyDetails.type);
    }, [PropertyDetails]); // Make sure to include PropertyDetails in the dependency array
  
    const fHandleFormSubmit = async() => {
    //   console.log('Form submitted:', {
    //         propertyImages,
    //         PropertyDetails,
    //         expectedPrice,
    //         status,
    //   });

        setLoader(true);
        const imageURLs = propertyImages.map((e) => e.data_url );
        // console.log("****** imageURLs ******* ", imageURLs)
        const API_LIST_PROPERTY_WITH_KESTATES_CONFIG = LIST_PROPERTY_WITH_K_ESTATES(
            PropertyDetails.id, 
            0, 
            status, 
            expectedPrice, 
            imageURLs, 
            tokenInfo
        );
        
        try {
           const response = await httpRequest(API_LIST_PROPERTY_WITH_KESTATES_CONFIG);
        //    console.log("**** response ****** ", response)
           if(response.success){
              setLoader(false);
              setOpen(true);
              setError("Added Successfully")
              setTimeout(() => {
                  navigate(-1)
              }, 2000);
           }

        } catch (error) {
            setOpen(true)
            setLoader(false);
            setError(error.message);
        }
    };


    const AmountSaperator = (amount) => {
        const [integerPart, fractionalPart] = amount.toString().split('.');
        const formattedIntegerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ',');
        const formattedAmount = fractionalPart ? `${formattedIntegerPart}.${fractionalPart}` : formattedIntegerPart;
        return formattedAmount;
      };
      
      const handleInputChange = (event) => {
        const { value } = event.target;
        const newValue = value.replace(/[^\d]/g, '');
        setExpectedPrice(newValue);
      };

      const handleClose = () => {
        setOpen(false);
      };

    return(
        <Container maxWidth={'xl'} >
            <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
                <div className='listing-container' >
                    <Typography  style={{ fontSize:"2rem", fontFamily:"Montserrat", marginBottom:"1rem", fontWeight:"300" }} >
                       List Property With K Estates
                    </Typography>

                    {/* <form onSubmit={handleFormSubmit} style={{  width:'100%' }} > */}

                    <div className="App">
                        <ImageUploading
                            multiple
                            value={images}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                            // write your building UI
                            <div className="upload__image-wrapper" >
                                {/* <button
                                    style={isDragging ? { color: 'red' } : undefined}
                                    onClick={onImageUpload}
                                    {...dragProps}
                                >
                                    Click or Drop here
                                </button>
                                <button onClick={onImageRemoveAll}>Remove all images</button> */}

                                <Button variant="contained"  type="submit" size='small' onClick={onImageUpload} style={{ backgroundColor:'black', marginLeft: '0rem' }} >
                                    Click or Drop here
                                </Button>
                                <Button variant="contained" type="submit" size='small' onClick={onImageRemoveAll} style={{ backgroundColor:'black', marginLeft: '1rem' }} >
                                    Remove all images
                                </Button>

                                
                                <Grid container spacing={1} style={{ marginTop: '1rem', width:'100%' }} className='images-list' >
                                    {
                                        imageList.slice(0,10).map((image, index) => (
                                            <Grid key={index} item xs={6} md={4} lg={3} xl={2} >
                                                <div className='image-controller' >
                                                    <ChangeCircleIcon onClick={() => onImageUpdate(index)} color='info' />
                                                    <RemoveCircleIcon onClick={() => onImageRemove(index)} color='error' />
                                                </div>
                                                <img src={image['data_url']} alt="" style={{ width:'12rem', height:'12rem' }} />
                                            </Grid>
                                    ))}
                                </Grid>
                                <Typography variant='caption' color={'lightgray'} >
                                    Only max 10 images can be shown
                                </Typography>

                                {/* New Requirement */}
                                {/* <div style={{ overflowX: 'auto' }}>
                                    <Typography variant='caption' color={'lightgray'} >
                                        Only max 5 images can be upload
                                    </Typography>
                                    <Grid container spacing={1} style={{ marginTop: '1rem' }} className='images-list'>
                                        {imageList.slice(0, 5).map((image, index) => (
                                        <Grid key={index} item xs={6} sm={4} md={3} lg={2} xl={1}>
                                            <div className='image-controller'>
                                                <ChangeCircleIcon onClick={() => onImageUpdate(index)} color='info' />
                                                <RemoveCircleIcon onClick={() => onImageRemove(index)} color='error' />
                                            </div>
                                            <img src={image['data_url']} alt={`Image ${index}`} style={{ width: '100%', height: 'auto' }} />
                                        </Grid>
                                        ))}
                                    </Grid>
                                </div> */}

                            </div>
                            )}
                        </ImageUploading>
                        </div>
                    
                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <TextField
                                    label="Unit"
                                    value={PropertyDetails.unit_no? PropertyDetails.unit_no : "" }
                                    fullWidth
                                    disabled={true}
                                />
                            </Grid>

                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <TextField
                                    label="Project"
                                    // value ={propertyName}
                                    value={PropertyDetails.title? PropertyDetails.title : ""}
                                    disabled={true}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>                    

                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <TextField
                                    label="Developer"
                                    value={PropertyDetails.developer ? PropertyDetails.developer : ""}
                                    fullWidth
                                    disabled={true}
                                />
                            </Grid>
                            {/* <Grid item xs={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel>No of Bathrooms</InputLabel>
                                    <Select
                                    value={bathrooms}
                                    onChange={(e) => setBathrooms(e.target.value)}
                                    >
                                    {Array.from({ length: 7 }, (_, i) => i + 1).map((num) => (
                                        <MenuItem key={num} value={num}>
                                        {num} Bathroom{num !== 1 ? 's' : ''}
                                        </MenuItem>
                                    ))}
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <TextField
                                    label="Total Area"
                                    value={PropertyDetails.area_size ? PropertyDetails.area_size : "" }
                                    fullWidth
                                    disabled={true}
                                />
                            </Grid>
                           
                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        
                            {/* <Grid item xs={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel>Property Type</InputLabel>
                                    <Select
                                    value={propertyType}
                                    onChange={(e) => {
                                        console.log(e.target)
                                        setPropertyType(e.target.value)
                                    }}
                                    // defaultValue={PropertyDetails.type}
                                    >
                                    <MenuItem value="villa">Villa</MenuItem>
                                    <MenuItem value="apartment">Apartment</MenuItem>
                                    <MenuItem value="townhouse">Townhouse</MenuItem>
                                    <MenuItem value="studio">Studio</MenuItem>
                                    <MenuItem value="duplex">Duplex</MenuItem>
                                    <MenuItem value="mansions">Mansions</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid> */}

                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <TextField
                                    label="Type"
                                    value={PropertyDetails.property_type ? PropertyDetails.property_type : ""}
                                    fullWidth
                                    disabled={true}
                                />
                            </Grid>


                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend">Status</FormLabel>
                                    <RadioGroup
                                        row
                                        value={status}
                                        onChange={(e) => setStatus(e.target.value)}
                                    >
                                    <FormControlLabel
                                        value="Rent"
                                        control={<Radio color="primary" />}
                                        label="Rent"
                                    />
                                    <FormControlLabel
                                        value="Sale"
                                        control={<Radio color="primary" />}
                                        label="Sale"
                                    />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                        </Grid>

                        <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                            {/* <Grid item xs={12} md={12} lg={6} xl={6}>
                                <TextField
                                    label="Add Ref No"
                                    value={refNo}
                                    onChange={(e) => setRefNo(e.target.value)}
                                    fullWidth
                                />
                            </Grid> */}

                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                <FormControl fullWidth>
                                    <InputLabel>No of Bedrooms</InputLabel>
                                    <Select
                                        value={PropertyDetails.bedrooms ? PropertyDetails.bedrooms : "" }
                                        // value={bedrooms}
                                        // onChange={(e) => setBedrooms(e.target.value)}
                                        disabled={true}
                                    >
                                    {Array.from({ length: 7 }, (_, i) => i + 1).map((num) => (
                                        <MenuItem key={num} value={num}>
                                        {num} Bedroom{num !== 1 ? 's' : ''}
                                        </MenuItem>
                                    ))}
                                    <MenuItem value={'studio'}>Studio</MenuItem>
                                    </Select>
                                </FormControl>
                            </Grid>


                            <Grid item xs={12} md={12} lg={6} xl={6}>
                                {/* <TextField
                                    label="Expected Price"
                                    value={AmountSaperator(expectedPrice)}
                                    // value={expectedPrice}
                                    onChange={(event) => {
                                            const { value } = event.target;
                                            const regex = /^[0-9\b]+$/;
                                            if (value === '' || regex.test(value)) {
                                              setExpectedPrice(value.replace(/[^\d]/g, ''));
                                            }
                                    }}
                                    fullWidth
                                /> */}
                                <TextField
                                    label="Expected Price"
                                    value={AmountSaperator(expectedPrice)}
                                    onChange={handleInputChange}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>

                        {
                            loader?
                            <div style={{ display:"flex", alignItems:"center", justifyContent:"center", height:'5rem',  }} >
                                <CircularProgress variant='indeterminate' style={{ color:'black' }} />
                            </div>
                            :
                            <Button variant="contained" type="submit" onClick={fHandleFormSubmit} style={{ backgroundColor:'black', marginTop: '1rem' }} >
                                Submit
                            </Button>
                        }
                        
                    {/* </form> */}
                </div>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={error}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            />
        </Container>
    )
}

export default Listings_Listed_With_K_Estates;
