import React from 'react';
import { Slide, Fade, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { Button, Grid, Typography, Card, CardContent, } from '@mui/material';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import TrendingDownIcon from '@mui/icons-material/TrendingDown';
import StarIcon from '@mui/icons-material/Star';
//Local
import "./index.css";
import { AmountSaperator } from '../Utilities';
import { OwnerProperty } from '../LocalDB';
import { useNavigate } from 'react-router-dom';

const spanStyle = {
    padding: '20px',
    background: '#efefef',
    color: '#000000'
  }
  
  const divStyle = {
    // display: 'flex',
    // alignItems: 'center',
    // justifyContent: 'center',
    // backgroundSize: 'cover',
    // // height: '40rem',
    // // width:"100%"
    // width: '100%',
    // height: '35rem'

    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    backgroundPosition: 'center',
    width: '100%',
    height: '35rem',
    overflow: 'hidden', // To prevent overflowing content
  }

const ListingComponent = ({
    xSlideImagesArr,
    xArea,
    xDescription,
    xNoOfBath,
    xNoOfBeds,
    xPropertyName,
    xRefNumber,
    xOnpressListing,
    xPropertyValue,
    xOnpressListingWithKestates,
    xTrending
}) => {
    const navigate = useNavigate();
    const filterData = OwnerProperty.filter((data) => {
        if(data.listed_with_Kestates){
            return data;
        }
    })
    return(
        // <Grid item xs={12} sm={6} md={4} >
        //     <Slide transitionDuration={100} autoplay={false} >
        //         {xSlideImagesArr.map((slideImage, index)=> (
        //             <div key={index} >
        //                 {/*
        //                     <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}> 
        //                     </div>    
        //                 */}
        //                 <img
        //                     src={`${slideImage.url}`}
        //                     style={{
        //                         width: '100%',
        //                         height: '15rem', // Use 'auto' for height to maintain aspect ratio
        //                         maxWidth: '100%', // Ensure image doesn't exceed its container's width
        //                         // maxHeight: '16rem', // Limit the maximum height of the image
        //                         objectFit: 'cover', // Cover the entire container while maintaining aspect ratio
        //                         borderTopLeftRadius:'0.5rem',
        //                         borderTopRightRadius:'0.5rem'
        //                     }}
        //                     alt="Slide Image"
        //                 />
        //             </div>
        //     ))} 
        //     </Slide>
            
        //     <div style={{ borderStyle:'solid', borderWidth:'0.15rem',borderTopWidth:0, borderColor:'lightgray', padding:'0.5rem', borderBottomRightRadius:'0.5rem', borderBottomLeftRadius:'0.5rem' }} >
        //         <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"space-between" }} >
        //             <Typography variant="h5" gutterBottom>
        //                 {xPropertyName}
        //             </Typography >
        //             <Typography style={{ color:'gray', fontSize: '1.25rem', fontWeight:'bold' }} >
        //                     {/* {xPropertyValue} */}
        //                     {AmountSaperator(175000000)} AED
        //                 <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"flex-start" }}>
        //                     <Typography variant="caption" style={{ color:'Black', fontWeight:'bold', marginRight: '1rem' }} >
        //                        From the last month:
        //                     </Typography >
        //                     <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"flex-start", marginRight: '1rem' }}>
        //                         <Typography variant="subtitle1" style={{ color:'green', fontSize: '1rem', fontWeight:'bold' }} >
        //                           5%
        //                         </Typography >
        //                         <TrendingUpIcon style={{ color:'green', width:'1.5rem', height:'1.5rem' }}  />
        //                     </div>

        //                     {/* <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"flex-start" }}>
        //                         <Typography variant="subtitle1" style={{ color:'red', fontSize: '1.2rem', fontWeight:'bold' }} >
        //                           5%
        //                         </Typography >
        //                         <TrendingDownIcon style={{ color:'red', width:'2rem', height:'2rem' }} />
        //                     </div> */}

        //                 </div>
        //             </Typography >
        //         </div>
        //         <div className='property-detail' >
        //             <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start' }} >
        //                 <BedIcon style={{ color:'gray' }} />
        //                 <Typography style={{ fontSize:'0.8rem', marginLeft: '0.35rem' }} >
        //                     {xNoOfBeds}
        //                 </Typography>
        //             </div>

        //             <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '2rem' }} >
        //                 <BathtubIcon style={{ color:'gray' }} />
        //                 <Typography style={{ fontSize:'0.8rem', marginLeft: '0.35rem' }} >
        //                     {xNoOfBath}
        //                 </Typography>
        //             </div>

        //             <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '2rem' }} >
        //                 <SquareFootIcon style={{ color:'gray' }} />
        //                 <Typography style={{ fontSize:'0.8rem', marginLeft: '0.35rem' }} >
        //                     {xArea} BUA
        //                 </Typography>
        //             </div>

        //             <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '2rem' }} >
        //                 <Typography style={{ fontSize:'0.8rem', marginLeft: '0.35rem', fontWeight:'bold', color:'gray' }} >
        //                     Ref. No
        //                 </Typography>
        //                 <Typography style={{ fontSize:'0.8rem', marginLeft: '0.35rem' }} >
        //                     {xRefNumber}
        //                 </Typography>
        //             </div>
        //         </div>

        //         <div style={{}} >
        //             <Button variant='contained' size='small' color='inherit' style={{ marginTop: '1rem' }} onClick={xOnpressListing} >
        //                 More Details
        //             </Button>

        //             <Button variant='contained' size='small' color='info' style={{ marginTop: '1rem', marginLeft: '1rem' }} onClick={xOnpressListingWithKestates} >
        //                 Listing with K-estate
        //             </Button>
        //         </div>
        //     </div>
        // </Grid>

        // < ---------------------------------------- NEW DESIGN ------------------------------------------ >


    <Grid container spacing={4} style={{ marginTop: '2rem' }}>
        {filterData.map(property => (
            <Grid item xs={12} sm={6} md={4} key={property.id} >
                <Card>
                <Slide transitionDuration={100} autoplay={false} >
                    {property.xSlideImagesArr.map((slideImage, index)=> (
                        <div key={index} style={{  backgroundColor:'black' }} >
                            {/* <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                            </div> */}
                            <img
                                src={`${slideImage.url}`}
                                style={{
                                    width: '100%',
                                    height: '15rem', // Use 'auto' for height to maintain aspect ratio
                                    maxWidth: '100%', // Ensure image doesn't exceed its container's width
                                    // maxHeight: '16rem', // Limit the maximum height of the image
                                    objectFit: 'cover', // Cover the entire container while maintaining aspect ratio
                                    opacity: 0.5,
                                }}
                                alt="Slide Image"
                            />
                            {
                                property.listed_with_Kestates ?
                                    <Button 
                                        variant='outlined' 
                                        disableRipple 
                                        size='small' 
                                        className='listed-label' 
                                        startIcon={<StarIcon />} 
                                        style={{
                                            position:'absolute',
                                            zIndex:1,
                                            borderColor:'white',
                                            color:'white',
                                            margin:'0.75rem'
                                        }}
                                    >
                                        Listed with K-estates
                                    </Button>
                                :
                                null

                            }
                        </div>
                    ))} 
                </Slide>
                <CardContent style={{ display:'flex', flexDirection:'row', alignItems:"center", justifyContent:'space-between', padding:'1rem' }} >
                    <Typography variant="h6" gutterBottom>{property.xPropertyName}</Typography>
                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start' }} >
                        <Typography variant="body1" style={{ fontSize:'1rem', marginLeft: '0.35rem' }}>{property.xPrice} AED</Typography>
                        {
                            property.xTrending == false?
                                <TrendingUpIcon style={{ color:'green', width:'2rem', height:'2rem' }}  />
                                :
                                <TrendingDownIcon style={{ color:'red', width:'2rem', height:'2rem' }}  />
                            
                        }
                    </div>
                </CardContent>
                <CardContent style={{ display:'flex', flexDirection:'row', alignItems:"center", justifyContent:'space-between', padding:'1rem'}} >
                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start' }} >
                        <BedIcon style={{ color:'gray' }} />
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {property.xNoOfBeds}
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '0.5rem' }} >
                        <BathtubIcon style={{ color:'gray' }} />
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {property.xNoOfBath}
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '0.5rem' }} >
                        <SquareFootIcon style={{ color:'gray' }} />
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {property.xArea} BUA
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '0.5rem' }} >
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem', fontWeight:'bold', color:'gray' }} >
                            Unit No.
                        </Typography>
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {property.xRefNumber}
                        </Typography>
                    </div>
                </CardContent>

                {/* <Button 
                    variant="contained" 
                    color="primary" 
                    style={{ margin:'1rem', backgroundColor:'black' }}
                    onClick={() => { navigate("/ListingsDetails", {state: {propertyDetails: property}})}}
                >
                    View Details
                </Button> */}

                    <div style={{padding:'0.5rem', display:'flex'}} >
                        <Button variant='contained' size='small' color='inherit' style={{ marginTop: '1rem', marginRight: '1rem' }} onClick={() => xOnpressListing(property)} >
                            More Details
                        </Button>

                        {/* <Button variant='contained' size='small' color='info' style={{ marginTop: '1rem' }} onClick={() => xOnpressListingWithKestates(property)} >
                            Listing with K-estate
                        </Button> */} 
                       
                        {/* <Button variant='outlined' disableRipple size='small' color='info' style={{ marginTop: '1rem' }} startIcon={<StarIcon />} >
                            Listed with K-estate
                        </Button> */}

                    </div>
                </Card>
            </Grid>
    ))}
  </Grid>


    )
}

export default ListingComponent;