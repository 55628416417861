import { Typography } from '@mui/material';
import React from 'react';
import NorthIcon from '@mui/icons-material/North';
import SouthIcon from '@mui/icons-material/South';
import logo from '../Images/k-estates-icon-logo.png';

const PropertyPriceSliderComp = ({
    xProjectName,
    xDevelopedBy,
    xRate,
    xProgress,
    xMedianPrice,
    xDate
}) => {
    return(
        <div className='property-price-slider' >
            <div style={{backgroundColor:'black', marginRight:'0.75rem', borderRadius:'100%'}} >
                <img src={logo} className='image-logo' />
            </div>
            <div style={{ display:'flex', alignItems:"center", justifyContent:'center' }} >
                <div>
                    {/* <div style={{ display:'flex', alignItems:'center', justifyContent:'flex-start' }} >
                        <Typography style={{ fontSize:'0.65rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                            {xDate}
                        </Typography>
                    </div> */}
                    <Typography  style={{ fontSize:'0.85rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                        {xProjectName}
                    </Typography>
                    <Typography  style={{ fontSize:'0.65rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                        {/* {xDevelopedBy} */}
                        Med. Price AED {xMedianPrice} 
                    </Typography>
                    {/* <Typography style={{ fontSize:'0.65rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                        {xRate}%
                    </Typography> */}
                </div>

                <div style={{ marginLeft:'1rem' }} >
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center" }} >
                        {
                            xProgress?
                            <NorthIcon style={{ height:'1.5rem', width:'2rem', color:'green' }} />
                            :
                            <SouthIcon style={{ height:'1.5rem', width:'2rem', color:'red' }} />
                        }
                        <Typography style={{ fontSize:'1rem', fontWeight: 'bold', color: xProgress? 'green':'red'}} >
                            {xRate}%
                        </Typography>
                    </div>
                    <Typography  style={{ fontSize:'0.65rem', fontWeight: '400', fontFamily:'Montserrat'}} >
                        month to month
                    </Typography>
                </div>
            </div>
          
        </div>
    )
}

export default PropertyPriceSliderComp;