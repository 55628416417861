import React, { useEffect, useState } from 'react';
import "./index.css";
import 'react-slideshow-image/dist/styles.css'
import { useLocation } from 'react-router-dom';
import { Container, Grid, IconButton, Menu, Typography } from '@mui/material';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { MenuItem} from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { useSelector } from 'react-redux';
import moment from 'moment';

// Local 

import TableComponent from '../../Components/TableDataComp';
import { AmountSaperator, getStartDate } from '../../Utilities';
import { httpRequest } from '../Server/Axios';
import { GET_COMMUNITY_TRENDS_API, GET_PROPERTIES_PAYMENT_PLAN, GET_PROPERTY_MONITOR_STATISTICS_API, GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API } from '../Server/Apis';
import LottieAnimationLoader from '../../Components/lottieAnimation';
import { propertyDocumentsUrl, propertyImagesUrl } from '../Server/baseUrls';
import ListingBarChartComp from './ListingBarChartsComp';
import CommunityPriceTrends from './CommunityPriceTrends';
import BarLoader from "../../Images/bar.png";


const ListingsDetails = () => {
    const { state } = useLocation();
    const exchangeRate = 3.677;

    const propertyDetails = state.propertyDetails;
    const tokenInfo = useSelector((state) => state.login.token);

    const [animate, setAnimate] = useState(false);
    const [paymentData, setPaymentData] = useState([]);

    // New State For Property Statistics
    const [sTransactionData, setTransactionData] = useState([]);
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState(false);
    const [loader2, setLoader2] = useState(false);
    const [error2, setError2] = useState(false);
    const [nextInstallmentObj, setNextInstallmentObj] = useState(null)

    // For Area API
    const [priceTrendsChangesDataArea, setPriceTrendsChangesDataArea] = useState([]);
    const [priceTrendsData_Area, setPriceTrendsData_Area] = useState([]);
    const [loaderArea, setLoaderArea] = useState(false);
    const [errorArea, setErrorArea] = useState(false);
    const [sChangeIsArea, setChangeIsArea] = useState(false);

    // For Project API
    const [priceTrendsChangesDataProject, setPriceTrendsChangesDataProject] = useState([]);
    const [priceTrendsData_Project, setPriceTrendsData_Project] = useState([]);
    const [loaderProject, setLoaderProject] = useState(false);
    const [errorProject, setErrorProject] = useState(false);
    const [sChangeIsProject, setChangeIsProject] = useState(false);

    const [currency, setCurrency] = useState('AED');
    const [currencyMenu, setCurrnecyMenu] = useState(null);
    const [propertyValue, setPropertyValue] = useState(0);

    // For Community Trends API
    const [communityLoader, setCommunityLoader] = useState(false);
    const [communityTrendsData, setCommunityTrendsData] = useState([]);


    const handleCurrencyMenu = (event) => {
        setCurrnecyMenu(event.currentTarget);
    };
    
    const hadleCloseCurrencyMenu = () => {
        setCurrnecyMenu(null);
    };

    const handleToggleCurrency = () => {
        setCurrency((prevCurrency) => (prevCurrency === 'AED' ? 'USD' : 'AED'));
        hadleCloseCurrencyMenu()
    };

    const formatPrice = (price) => {   
        if (price == null) return 'AED 0';

        if (currency === 'AED') {
            return `AED ${AmountSaperator(price)}`;
        } else {
            const priceInUSD = price / exchangeRate;
            return `USD ${AmountSaperator(priceInUSD.toFixed(2))}`;
        }
    };

    useEffect(() => {
        // Scroll to the top when the component mounts
        setAnimate(true);
        window.scrollTo(0, 0);
        getProperty_Payment_Plan();
        // Property_Monitor_Statistics_API();
        fLatestTransaction_Statistics_API();
        // Property_Monitor_Statistics_FOR_RENT_API();
        Property_Price_Trends_In_Dubai_As_Per_Project();
        Property_Price_Trends_In_Dubai_As_Per_Area();
        // RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_AND_PROJECT();    

        Property_Monitor_API_For_Community_Trends();
    }, []);

    const getProperty_Payment_Plan = async() => {
        setLoader2(true)
        const API_PROPERTY_PAYMENT_PLAN = GET_PROPERTIES_PAYMENT_PLAN(propertyDetails.id, tokenInfo);
        
        try {
           const response = await httpRequest(API_PROPERTY_PAYMENT_PLAN);
           if(response.success){
            setPaymentData(response.property.property_plans)
            getNextInstallment(response.property.property_plans);
            // setNextPayment(response.payment_plan.amount);
            // setDueDate(response.payment_plan.due_date)
            setLoader2(false)
           }
           else{
            setLoader2(false)
           }
        } catch (error) {
            setLoader2(false)
            setError2(error)
        }
    }

    const getNextInstallment = (xData) => {
        const date = new Date();
        const validInstallments = xData.filter((item) => {
          return item.date && moment(date).format('YYYY-MM-DD') < item.date || item.paid_status == 'Unpaid';
        });
        if (validInstallments.length > 0) {
            setNextInstallmentObj(validInstallments[0]);
        } else {
            setNextInstallmentObj(xData[0] || { message: "No upcoming installment" });
        }
        
      };

    const endDate1 = new Date(); // Current date
    const startDate1 = getStartDate(endDate1);


    function formatDate(date) {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are 0-based in JavaScript
        const year = date.getFullYear();
        
        // return `${day}-${month}-${year}`; 
        return `${year}-${month}-${day}`;
    }

    const fLatestTransaction_Statistics_API = async() => {
        
        const today = new Date();
        const twelveMonthsAgo = new Date(today.getFullYear(), today.getMonth() - 12, today.getDate());

        const endDate = formatDate(today);
        const startDate = formatDate(twelveMonthsAgo);

        // console.log(`EndDate: ${endDate}`);
        // console.log(`StartDate: ${startDate}`);

        setLoader(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_MONITOR_STATISTICS_API(
            // '24-07-2023',
            startDate,
            '',
            // '23-07-2024',
            endDate,
            25,
            '',
            propertyDetails.bedrooms,
            propertyDetails.bedrooms,
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            // propertyDetails.location,
            propertyDetails.title == "ELA"? "VELA": propertyDetails.title 
        );

        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            // console.log("**** Property Monitor Statistics Response ****** ", response);

            if(response.status == 1){
                
                const dubaiTransactionData = response.data.map(item => ({ 
                    id: item.id,
                    area: item.sub_loc_1, 
                    transactionDate: moment(item.evidence_date).format('Do [of] MMMM YYYY'),
                    amount: parseInt(item.total_sales_price) || 0, // Convert to integer, default to 0 if conversion fails,
                    master_development: item.master_development,
                    Community: item.sub_loc_2,
                    Sub_Community: item.sub_loc_3,
                    beds: item.no_beds,
                    property_type: item.property_type,
                    area_sqft: item.unit_bua_sqft,
                    area_sqmt: item.unit_bua_sqm
                }));
                setTransactionData(dubaiTransactionData);
                setLoader(false)
            }
            else{
                setLoader(false);
                setError('There is No Data');
            }

        } catch (error) {
            setLoader(false);
            setError(error);
        }
    }

    const Property_Price_Trends_In_Dubai_As_Per_Area = async() => {
        setLoaderArea(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API(
            "",
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            propertyDetails.location,
            propertyDetails.bedrooms,
        );
        
        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            if(response.status == 1){
                // Extract data for chart
                const filteredData = response.data.filter((item) =>  item.avg_price > 0 );
                const chartData = filteredData.map(item => ({
                    x: item.evidence_month,
                    y: item.avg_price 
                }));
                setPriceTrendsChangesDataArea(filteredData)

                setPriceTrendsData_Area(chartData);

                setLoaderArea(false)

                setChangeIsArea(true)
            }
            else{
                setLoaderArea(false);
                setErrorArea('There is No Data');
                setChangeIsArea(true)
            }

        } catch (error) {
            setLoaderArea(false);
            setErrorArea(error);
            setChangeIsArea(true)
        }
    }

    const CalculatePercentage = (xData, period) => {
        if(period == 2 && xData.length > period){
            // console.log("*********** Working One ********* ", period)
            const first_val = xData[xData.length - 1];
            // console.log("***** First Value ******* ", first_val);
            const second_val = xData[xData.length - (period)];
            // console.log("***** Second Value ******* ", second_val);
            const difference = first_val.avg_price - second_val.avg_price;
            // console.log("***** difference Value ******* ", difference);
            const newValue = difference / second_val.avg_price;
            // console.log("***** newValue Value ******* ", newValue);
            const changesInPercentage = Number(newValue) * 100;
            // console.log("***** Percentage Value ******* ", changesInPercentage);
            return changesInPercentage;
          }
    }

    const Property_Price_Trends_In_Dubai_As_Per_Project = async() => {
        setLoaderProject(true)
        const API_PROPERTY_MONITOR_STATISTICS_CONFIG = GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API(
            "",
            propertyDetails.property_type == "Apartments"? "Apartment": propertyDetails.property_type,
            propertyDetails.title,
            propertyDetails.bedrooms,
        );
        
        try {
            const response = await httpRequest(API_PROPERTY_MONITOR_STATISTICS_CONFIG);
            if(response.status == 1){
                // Extract data for chart
                const filteredData = response.data.filter((item) =>  item.avg_price > 0 );
                const chartData = filteredData.map(item => ({
                    x: item.evidence_month,
                    y: item.avg_price 
                }));
                setPriceTrendsChangesDataProject(filteredData);
                setPropertyValue(CalculatePercentage(filteredData, 2));  
                setPriceTrendsData_Project(chartData);

                setLoaderProject(false)

                setChangeIsProject(true)
            }
            else{
                setLoaderProject(false);
                setErrorProject('There is No Data');
                setChangeIsProject(true)
            }

        } catch (error) {
            setLoaderProject(false);
            setErrorProject(error);
            setChangeIsProject(true)
        }
    }

    const Property_Monitor_API_For_Community_Trends = async() => {
        setCommunityLoader(true)
        const COMMUNITY_TRENDS_API_CONFIG = GET_COMMUNITY_TRENDS_API(
            propertyDetails.location,
        );
        
        try {
            const response = await httpRequest(COMMUNITY_TRENDS_API_CONFIG);
            // console.log("****** Response Community Trends ******* ", response );
            if(response.status == 1){
                // Extract data for chart
                setCommunityTrendsData(response.data);
                setCommunityLoader(false);
            }
            else{
                setCommunityLoader(false);
            }
        } catch (error) {
            setCommunityLoader(false);
        }
    }

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
          const price = payload[0].value;
          const formattedPrice = AmountSaperator(price); // Call your function here
          return (
            <div style={{ backgroundColor:'white', border:'0.5px solid #000', padding:'0.25rem', borderRadius:5 }}>
              {/* <p className="label">{`Area: ${label}`}</p>
              <p className="intro">{`Price: ${formattedPrice}`}</p> */}
              <Typography variant='subtitle2' >
                    {`Area: ${label}`}
                </Typography>
                <Typography variant='subtitle2' >
                    {`Price: ${formattedPrice}`}    
                </Typography>
            </div>
          );
        }
      
        return null;
    };

    const chartDataArea = priceTrendsData_Area.map(item => ({
        month: moment(item.x, 'M-YYYY').format('MMM YY'), // Format the month using Moment.js
        total_value: item.y // Use the total_value as the Y-axis data
    }));

    const chartDataProject = priceTrendsData_Project.map(item => ({
        month: moment(item.x, 'M-YYYY').format('MMM YY'), // Format the month using Moment.js
        total_value: item.y // Use the total_value as the Y-axis data
    }));

    const chartCommunityData = communityTrendsData.map(item => ({
        date: moment(item.evidence_date).format('MMM YY'), // Format the month using Moment.js
        change: item.monthly_change, // Use the total_value as the Y-axis data
        amount: item.price, // Use the total_value as the Y-axis data
    }));

    return( 
        <>
            <div 
                className='top_lead_detail_design' 
                style={{
                    backgroundImage: `url(${propertyDetails.images !== null && propertyDetails.images.length > 0 ? `${propertyImagesUrl+"/"+propertyDetails.images[0].name}` : "https://cdn-ijcmf.nitrocdn.com/wgZJdyCfHniZRfljxBqkJVQJnyFdTIeP/assets/images/optimized/rev-66c19d9/mypropertymanager.ae/wp-content/uploads/2023/03/homepage-hero-banner-min.jpg"})`,
                    width: "100%",
                    minHeight: '25rem',
                    backgroundSize: 'cover', //cover
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: '#000',  // Fallback color
                }} 
            >   
                
            </div>

            <Container maxWidth={'xl'} >
                <div style={{ display:'flex', alignItems:"center", justifyContent:'center', flexDirection:'column', marginTop:'0.5rem', }}>
                  
                    <Grid item /* spacing={2} */ className='property_details_1' marginTop={'3rem'} >
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            <Typography variant="h5"  style={{ fontFamily:'Montserrat'}}>
                                {propertyDetails.title ? propertyDetails.title : ""}
                            </Typography>
                            <Typography variant="h5"  style={{ fontFamily:'Montserrat'}}>
                                {propertyDetails.developer ? propertyDetails.developer : ""} 
                            </Typography>
                            <Typography variant="h5" style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"flex-start" }} >
                                {propertyDetails.price ? AmountSaperator(propertyDetails.price) : 0} AED
                            </Typography >
                        </Grid>
                        <Grid item xs={12} sm={12} md={6} lg={6} xl={6} >
                            {
                                nextInstallmentObj ?
                                (
                                    // nextInstallmentObj.installment_details == "𝐅𝐮𝐥𝐥𝐲 𝐏𝐚𝐢𝐝"?
                                    nextInstallmentObj.installment_details == "Fully Paid"?
                                    null
                                    :
                                    <Typography variant="h5" style={{ display:'flex', flexDirection:"column", alignItems:"flex-end", justifyContent:"flex-start" }} >
                                        <Typography style={{ fontSize: '0.85rem', fontWeight:'normal' }} >
                                            NEXT INSTALLMENT
                                        </Typography >
                                        <Typography style={{ fontSize: '1.25rem', fontWeight:'bold' }} >
                                            AED { nextInstallmentObj? AmountSaperator(nextInstallmentObj.amount) : "--"} 
                                        </Typography>
                                        <Typography style={{ fontSize: '0.85rem', fontWeight:'300' }} >
                                            { nextInstallmentObj && nextInstallmentObj.date !== null  ? moment(nextInstallmentObj.date).format('Do MMMM YYYY') : "--"}
                                        </Typography>
                                    </Typography >
                                )
                                :
                                null
                            }
                        </Grid>
                    </Grid>

                    <Grid item /* spacing={2} */ className='property_details_2' marginTop={'3rem'}  >
                        <Grid item xs={12} sm={6} md={6} lg={4} xl={4} className='property_details_2_First_Grid' >
                            <Typography style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'normal', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                PROPERTY DETAILS
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Project: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.sub_title ? propertyDetails.sub_title : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                UNIT NO: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.unit_no ? propertyDetails.unit_no : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                BUA: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.area_size ? propertyDetails.area_size : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Bedroom <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.bedrooms ? propertyDetails.bedrooms : " -- "} </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Handover: <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> {propertyDetails.hand_over ? propertyDetails.hand_over : " -- "}  </Typography>
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Floor Plan: 
                                {
                                    propertyDetails.floor_plan ? 
                                        <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}> <a href={`${propertyDocumentsUrl}/${propertyDetails.floor_plan}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}> {propertyDetails.floor_plan ? "View" : " -- "} </a> </Typography>
                                        :
                                        "  Not Available"
                                }
                            </Typography>
                            <Typography style={{ display:'flex', alignItems:"center", flexDirection:'row', fontSize:'0.85rem', fontWeight:"500", fontFamily:'Montserrat', marginBottom:'0.5rem'}}>
                                Title Deed / Contract:
                                {
                                    propertyDetails.title_deed ? 
                                        <Typography style={{ fontSize:'0.85rem', fontWeight:"300", fontFamily:'Montserrat', marginLeft:'0.75rem'}}>  <a href={`${propertyDocumentsUrl}/${propertyDetails.title_deed}`} target="_blank" rel="noopener noreferrer" style={{ color: 'black', textDecoration: 'underline' }}> {propertyDetails.title_deed ? "View" : " -- "} </a> </Typography>
                                        :
                                        "  Not Avaialble"
                                }
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} lg={8} xl={8} className='property_details_2_Second_Grid' >
                            {
                                loader2?
                                <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                    <LottieAnimationLoader />
                                </div>
                                :
                                (
                                    !loader2 && paymentData.length == 0 ?
                                    <div style={{ border:"solid 0.75px lightgray",  display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:400 }} >
                                        <Typography variant="subtitle2" style={{ fontSize: '0.75rem', fontFamily: 'Montserrat', color:'gray'}}>
                                            No Data Available
                                        </Typography>
                                    </div>
                                    :
                                    <>
                                        <TableComponent xData={paymentData} />
                                    </>
                                )
                            }
                        </Grid>
                    </Grid>
                    
                    <div className='detail-container'>
                        <div style={{ marginTop: '2.5rem' }}>

                            {
                                !loaderArea && chartDataArea.length == 0 ?
                                    null
                                    :
                                    <Typography  style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                        Average Residential Sales Price
                                    </Typography>
                            }
                            
                            <Grid container marginTop={'2rem'} marginBottom={'2rem'}>
                                
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom:'1rem' }} >
                                    <Typography variant="subtitle1"  style={{ fontSize:'1.35rem', fontWeight: '500', fontFamily:'Montserrat' }}>
                                        {
                                            !loaderArea && chartDataArea.length == 0 ?
                                            null
                                            :
                                            (
                                                propertyDetails.location?propertyDetails.title.toUpperCase()+", "+propertyDetails.location.toUpperCase():" "
                                            )
                                        }
                                    </Typography>
                                    {
                                            loaderArea?
                                            <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <LottieAnimationLoader />
                                            </div>
                                            :
                                            (
                                            !loaderArea && chartDataArea.length == 0 ?
                                                null
                                                :
                                                <ListingBarChartComp xRawData={chartDataArea} xChanges={sChangeIsArea} xPriceChangesData={priceTrendsChangesDataArea} />
                                        )
                                    }
                                </Grid>

                                {/* <Grid item xs={12} sm={12} md={12} lg={6} xl={6} style={{ marginBottom:'1rem'}} >
                                    <Typography variant="subtitle1" style={{ fontSize: '1.35rem', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                        {propertyDetails.title?propertyDetails.title.toUpperCase():" "}
                                    </Typography>
                                    {
                                        loaderProject?
                                        <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                            <LottieAnimationLoader />
                                        </div>
                                        :
                                        (
                                            !loaderProject && chartDataProject.length == 0 ?
                                            <div style={{ display:"flex", flexDirection:"column", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <img src={BarLoader} height={300} width={'90%'}  />
                                                <Typography variant="subtitle2" style={{ fontSize: '0.75rem', fontFamily: 'Montserrat', color:'gray'}}>
                                                    No Data Available
                                                </Typography>
                                            </div>
                                            :
                                            <ListingBarChartComp xRawData={chartDataProject} xChanges={sChangeIsProject} xPriceChangesData={priceTrendsChangesDataProject} />
                                        )
                                    }
                                </Grid> */}
                            </Grid>



                            {
                                !communityLoader && chartCommunityData.length == 0 ?
                                    null
                                    :
                                    <Typography  style={{ backgroundColor:'black', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat', color:'white', letterSpacing:2, marginBottom:'1rem', lineHeight:'2rem', paddingLeft:'0.5rem' }} >
                                        Community Price Trends
                                    </Typography>
                            }

                            <Grid container marginTop={'2rem'} marginBottom={'2rem'}>
                                <Grid item xs={12} sm={12} md={12} lg={12} xl={12} style={{ marginBottom:'1rem' }} >
                                    {
                                            communityLoader?
                                            <div style={{ border:"solid 0.75px lightgray",  display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                                                <LottieAnimationLoader />
                                            </div>
                                            :
                                            (
                                            !communityLoader && chartCommunityData.length == 0 ?
                                                null
                                                :
                                                <div style={{ flexDirection:"column", padding: '20px', display:"flex", alignItems:"center", justifyContent:"center", }}>
                                                    {
                                                        communityTrendsData && communityTrendsData.length > 0 &&
                                                        <h4>{moment(communityTrendsData[0].evidence_date).format("MMM YYYY")} to {moment(communityTrendsData[communityTrendsData.length - 1].evidence_date).format("MMM YYYY")}</h4>
                                                    }
                                                    <h5>Change in Residential Sales Price</h5>
                                                    <h6>{propertyDetails.location}</h6>
                                                    <CommunityPriceTrends xCommunityChartData={chartCommunityData} xCommunityFullData={communityTrendsData} />
                                                </div>
                                        )
                                    }
                                </Grid>
                            </Grid>

                        </div>

                    </div>

                </div>
            </Container>

            <Typography  style={{ backgroundColor:'#222', fontSize:'0.9rem', fontWeight:'600', fontFamily:'Montserrat',color:'white', letterSpacing:2, lineHeight:'2rem', padding:'0.5rem' }} >
                LATEST TRANSACTION FOR {propertyDetails.bedrooms?propertyDetails.bedrooms+" BD ":" "}{propertyDetails.property_type?propertyDetails.property_type.toUpperCase():" "}
            </Typography>
            <TableContainer component={Paper} style={{ marginBottom:'5rem', marginTop:'-0.5%' }} >
                <Table>
                    <TableHead>
                    <TableRow sx={{ backgroundColor: '#222', color: '#fff' }}>
                        <TableCell align='left' style={{ color: 'white' }} >Evidence Date</TableCell>
                        <TableCell align='left' style={{ color: 'white' }} >Master Development</TableCell>
                        <TableCell align='left' style={{ color: 'white' }} >Community</TableCell>
                        {/* <TableCell align='left' style={{ color: 'white' }} >Property</TableCell> */}
                        <TableCell align='center' style={{ color: 'white' }} >Transaction Date</TableCell>
                        <TableCell align='center' style={{ color: 'white' }} >Area in sqf</TableCell>
                        <TableCell align='center' style={{ color: 'white' }} >Area in sqm</TableCell>
                        <TableCell align='center' style={{ color: 'white' }} >
                            Price in {currency == 'AED'?"AED":"USD"}
                            <IconButton
                                aria-label="more"
                                aria-controls="long-menu"
                                aria-haspopup="true"
                                onClick={handleCurrencyMenu}
                            >
                                <ArrowDropDownIcon color='white' style={{ color:"white" }} />
                            </IconButton>
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {sTransactionData.map((transaction, index) => (
                        <TableRow style={{ backgroundColor: index % 2 == 0 ? 'white' : 'lightgray'  }} key={transaction.id}>
                            <TableCell align='left' >{transaction.transactionDate}</TableCell>
                            <TableCell align='left' >{transaction.area}</TableCell>
                            <TableCell align='left' >{transaction.Community}</TableCell>
                            {/* <TableCell align='left' >{transaction.Sub_Community}</TableCell> */}
                            <TableCell align='center' >{transaction.transactionDate}</TableCell>
                            <TableCell align='center' >{AmountSaperator(transaction.area_sqft)} sqf</TableCell>
                            <TableCell align='center' >{AmountSaperator(transaction.area_sqmt)} sqm</TableCell>
                            <TableCell align='center' >
                                {transaction.amount == null ? 
                                    'AED 0' 
                                    : 
                                    // `AED ${AmountSaperator(transaction.amount)}`
                                    `${formatPrice(transaction.amount)}`
                                }
                                </TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>

                {
                    loader &&
                    <div style={{ width: "100%", height:'40rem', display: 'flex', alignItems: "center", alignItems: "center", justifyContent: "center" }} >
                        <div style={{ display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                            <LottieAnimationLoader />
                        </div>
                    </div>
                }

                {
                    !loader && sTransactionData.length == 0 &&
                    <div style={{ display:"flex", alignItems:"center", justifyContent:"center", height:400 }} >
                        <Typography variant="subtitle2" style={{ color:'gray', fontSize: '1.15rem', fontFamily: 'Montserrat', marginTop: '2rem', marginBottom: '2rem' }}>
                            There are limited transactions in this area - No Data Available
                        </Typography>
                    </div>
                }
            </TableContainer>
            <Menu
                id="menu-appbar"
                anchorEl={currencyMenu}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                }}
                open={Boolean(currencyMenu)}
                onClose={hadleCloseCurrencyMenu}
                sx={{
                    boxShadow:'none'
                }}
                >
                {
                    currency == 'AED'?
                    <MenuItem onClick={handleToggleCurrency} >
                        <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} > USD</Typography>
                    </MenuItem>
                    :
                    <MenuItem onClick={handleToggleCurrency} >
                        <Typography variant='subtitle2' textAlign="center" style={{ fontFamily:'Montserrat', color:'black' }} >AED</Typography>
                    </MenuItem>
                }
            </Menu>
        </>
    )   
}

export default ListingsDetails;