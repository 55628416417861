// import React, { useEffect, useState } from "react";
// import ClearIcon from '@mui/icons-material/Clear';
// import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
// import SaveIcon from '@mui/icons-material/Save';
// import axios from "axios";
// import { BaseUrl } from "../Components/BaseUrl";
// import { useSelector } from "react-redux";
// import BeatLoader from "react-spinners/BeatLoader";
// import CurrencyInput from 'react-currency-input-field';
// import AddIcon from '@mui/icons-material/Add';
// import CloseIcon from '@mui/icons-material/Close';
// import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
// import { baseUrl } from "../Pages/Server/baseUrls";
// import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
// import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';

// function TableRows({ rows, tableRowRemove, onValUpdate, errors,moveRowUp, moveRowDown }) {
 
  
//   return rows.map((rowsData, index) => {
  
    
//     const { date, number_of_installment, paymnet_perccentage, amount,paid_status, installment_details } = rowsData;

//     const handleValueChange = (index,value, name) => {

  
      
//       const syntheticEvent = {
//         target: {
//           name: 'amount',
//           value: value,
//         },
//       };
//     onValUpdate(index,syntheticEvent)
//     }

  


//     return (
//       <tr key={index}>
//         <td>
//           <input
//             type="date"
//             required
//             value={date}
//             onChange={(event) => onValUpdate(index, event)}
//             name="date"
//             className="form-control"
//           />
//           {errors[index]?.date && <span className="text-danger">Date is required</span>}
//         </td>
//         <td>
//           <input
//             type="text"
//             required
//             value={number_of_installment}
//             onChange={(event) => onValUpdate(index, event)}
//             name="number_of_installment"
//             className="form-control"
//           />
//           {errors[index]?.number_of_installment && <span className="text-danger">Number of installment is required</span>}
//         </td>
//         <td>
//           <input
//             type="text"
//             required
//             value={paymnet_perccentage}
//             onChange={(event) => onValUpdate(index, event)}
//             name="paymnet_perccentage"
//             className="form-control"
//           />
//           {errors[index]?.paymnet_perccentage && <span className="text-danger">Payment percentage is required</span>}
//         </td>
//         <td>
//           {/* <input
//             type="text"
//             value={amount}
//             onChange={(event) => onValUpdate(index, event)}
//             name="amount"
//             className="form-control"
//           /> */}
//            <CurrencyInput
//                         style={{width:'100%',padding: '7px',boxSizing: 'border-box',border: '1px solid #ccc', borderRadius: '4px',
//                         fontSize: '16px',  transition: 'border-color 0.3s'}}
//                         id="input-example"
//                         name="amount"
//                         // label="Price"
//                         // placeholder="Price"
//                         value={amount}
//                         decimalsLimit={2}
//                         onValueChange={(value,name)=>handleValueChange(index,value,name)}
                       
//                         />
//           {errors[index]?.amount && <span className="text-danger">Amount is required</span>}
//         </td>
//         <td>
//           <select name="paid_status" className="form-select" value={paid_status}  onChange={(event) => onValUpdate(index, event)}>
//             <option value="Paid">Paid</option>
//             <option value="Unpaid">Unpaid</option>

//           </select>
//         </td>
//         <td>
//           <input
//             type="text"
//             value={installment_details}
//             onChange={(event) => onValUpdate(index, event)}
//             name="installment_details"
//             className="form-control"
//           />
//           {errors[index]?.installment_details && <span className="text-danger">Installment details are required</span>}
//         </td>
//         <td>
//         <div className="d-flex justify-content-between">
//           {index == 0?<ArrowUpwardIcon disabled="true" style={{color:'lightgray'}}   />:
//             <ArrowUpwardIcon onClick={() => moveRowUp(index)} style={{ cursor: 'pointer', marginRight: '10px' }}     />
//           }
//           {index == rows.length - 1?            
//              <ArrowDownwardIcon  style={{ color:'lightgray' }}    />
//                :
//             <ArrowDownwardIcon onClick={() => moveRowDown(index)} style={{ cursor: 'pointer', marginRight: '10px' }}    />
// }
//             <RemoveCircleOutlineIcon onClick={() => tableRowRemove(index)} style={{ cursor: 'pointer' }} />
//           </div>
//         </td>
//       </tr>
//     );
//   });
// }

// const PaymentPlan = ({ property_id, xCLosePayment, payment_plans }) => {
//   const token = useSelector((state) => state.login.token);
//   const axiosConfig = {
//     headers: {
//       Authorization: `Bearer ${token}`, // Include the token as a Bearer token
//     },
//   };

//   const [rows, initRow] = useState([]);
//   const [isEdit, setIsEdit] = useState(false);
//   const [loading, setLoading] = useState(false);
//   const [errors, setErrors] = useState([]);

//   useEffect(() => {
//     if (payment_plans && payment_plans.length > 0) {
//       initRow(payment_plans);
//     } else {
//       initRow([]);
//     }
//     setIsEdit(false);
//   }, [payment_plans]);

//   const addRowTable = () => {
//     const data = {
//       date: "",
//       paid_status:"Unpaid",
//       number_of_installment: "",
//       paymnet_perccentage: "",
//       amount: "",
//       installment_details: ""
//     };
//     initRow([...rows, data]);
//   };

//   const tableRowRemove = (index) => {
//     const dataRow = [...rows];
//     dataRow.splice(index, 1);
//     initRow(dataRow);
//   };

//   const onValUpdate = (i, event) => {
//     const { name, value } = event.target;
//     const data = [...rows];
//     data[i][name] = value;
//     initRow(data);
//   };

  
//   const moveRowUp = (index) => {
//     if (index > 0) {
//       const data = [...rows];
//       const temp = data[index];
//       data[index] = data[index - 1];
//       data[index - 1] = temp;
//       initRow(data);
//     }
//   };
  
//   const moveRowDown = (index) => {
//     if (index < rows.length - 1) {
//       const data = [...rows];
//       const temp = data[index];
//       data[index] = data[index + 1];
//       data[index + 1] = temp;
//       initRow(data);
//     }
//   };
  

//   const validateRows = () => {
//     const newErrors = rows.map(row => {
//       const rowErrors = {};
      
//       if (!row.number_of_installment) rowErrors.number_of_installment = "Number of installment is required";
//       if (!row.paymnet_perccentage) rowErrors.paymnet_perccentage = "Payment percentage is required";
//       if (!row.amount) rowErrors.amount = "Amount is required";
//       if (!row.installment_details) rowErrors.installment_details = "Installment details are required";
//       return rowErrors;
//     });
//     setErrors(newErrors);
//     return newErrors.every(rowErrors => Object.keys(rowErrors).length === 0);
//   };

//   const handleSubmit = () => {
  
//     if (validateRows()) {
//       setLoading(true);
//       const data = { property_id: property_id, payments: rows };
//       axios.post(baseUrl + "/api/paymentplan", data, axiosConfig)
//         .then((res) => {
//           if (res.data.success) {
//             setLoading(false);
//             xCLosePayment();
//           }
//         })
//         .catch((error) => {
//           setLoading(false);
//           console.error("Error:", error);
//         });
//     }
//   };

//   const handleCancel = () => {
//     setIsEdit(false);
//     if (payment_plans && payment_plans.length > 0) {
//       initRow(payment_plans);
//     } else {
//       initRow([]);
//     }
//   };

//   return (
//     <>
//       <table className="table table-bordered">
//         <thead>
//           <tr>
//             <th>Date</th>
//             <th>Number of installment</th>
//             <th>Payment percentage</th>
//             <th>Amount</th>
//             <th>Status</th>
//             <th>Installment details</th>
//             <th>
//               {!isEdit ? (
//                 <button className="btn btn-primary" onClick={() => setIsEdit(true)}><ModeEditOutlineIcon />Edit Plan</button>
//               ) : (
//                 <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
//                   <button className="btn btn-danger btn-sm" onClick={handleCancel}><CloseIcon /> Cancel</button>
//                   <button className="btn btn-sm btn-info ml-2" onClick={addRowTable}><AddIcon/> Add row</button>
//                 </div>
//               )}
//             </th>
//           </tr>
//         </thead>
//         <tbody>
//           {!isEdit ? (
//             payment_plans.map((p_p, index) => (
//               <tr key={index}>
//                 <td>{p_p.date?p_p.date:"-"}</td>
//                 <td>{p_p.number_of_installment}</td>
//                 <td>{p_p.paymnet_perccentage}</td>
//                 <td>{p_p.amount &&
//                   p_p.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
//                 }</td>
//                 <td>{p_p.paid_status}</td>
//                 <td>{p_p.installment_details}</td>
//               </tr>
//             ))
//           ) : (
//             rows.length < 1 ? (
//               <tr style={{ alignContent: 'center', alignItems: 'center' }}>
//                 <td colSpan={6}>
//                   No Payment plan
//                 </td>
//               </tr>
//             ) : (
//               <TableRows
//                 rows={rows}
//                 tableRowRemove={tableRowRemove}
//                 onValUpdate={onValUpdate}
//                 errors={errors}
//                 moveRowUp={moveRowUp}
//                 moveRowDown={moveRowDown}
//               />
//             )
//           )}
//         </tbody>
//       </table>
//       <div className="row">
//         <div className="form-group" style={{ margin: '20px' }}>
//           {rows && rows.length && isEdit > 0 ? (
//             <button className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
//               {loading ? (
//                 <>
//                   <BeatLoader color="white" size={5} />
//                   Processing
//                 </>
//               ) : (
//                 <>
//                   <SaveIcon /> Save Payment plan
//                 </>
//               )}
//             </button>
//           ) : null}
//         </div>
//       </div>
//     </>
//   );
// }

// export default PaymentPlan;

///// CODE REFURBISHED 

import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import axios from "axios";
import CurrencyInput from "react-currency-input-field";
import { BaseUrl } from "../Components/BaseUrl";
import { baseUrl } from "../Pages/Server/baseUrls";
import BeatLoader from "react-spinners/BeatLoader";
import {
  Clear as ClearIcon,
  RemoveCircleOutline as RemoveCircleOutlineIcon,
  Save as SaveIcon,
  Add as AddIcon,
  Close as CloseIcon,
  ModeEditOutline as ModeEditOutlineIcon,
  ArrowUpward as ArrowUpwardIcon,
  ArrowDownward as ArrowDownwardIcon,
} from "@mui/icons-material";

const MAX_FILE_SIZE = 5 * 1024 * 1024; // 5 MB

// Table Rows Component
function TableRows({ rows, onRowUpdate, onRowRemove, moveRowUp, moveRowDown, errors }) {
  const handleFileChange = (event, index) => {
    const file = event.target.files[0];
    if (file && file.size > MAX_FILE_SIZE) {
      alert("File size exceeds 5 MB. Please upload a smaller file.");
      event.target.value = ""; // Reset the input
    } else {
      onRowUpdate(index, { name: "receipt", value: file });
    }
  };

  return rows.map((row, index) => {
    const {
      date,
      number_of_installment,
      paymnet_perccentage,
      amount,
      paid_status,
      installment_details,
      receipt,
    } = row;

    return (
      <tr key={index}>
        <td>
          <input
            type="date"
            value={date}
            name="date"
            className="form-control"
            onChange={(e) => onRowUpdate(index, e.target)}
          />
          {errors[index]?.date && <span className="text-danger">Date is required</span>}
        </td>
        <td>
          <input
            type="text"
            value={number_of_installment}
            name="number_of_installment"
            className="form-control"
            onChange={(e) => onRowUpdate(index, e.target)}
          />
          {errors[index]?.number_of_installment && <span className="text-danger">Required</span>}
        </td>
        <td>
          <input
            type="text"
            value={paymnet_perccentage}
            name="paymnet_perccentage"
            className="form-control"
            onChange={(e) => onRowUpdate(index, e.target)}
          />
          {errors[index]?.paymnet_perccentage && <span className="text-danger">Required</span>}
        </td>
        <td>
          <CurrencyInput
            id={`amount-${index}`}
            value={amount}
            name="amount"
            decimalsLimit={2}
            className="form-control"
            onValueChange={(value) => onRowUpdate(index, { name: "amount", value })}
          />
          {errors[index]?.amount && <span className="text-danger">Required</span>}
        </td>
        <td>
          <select
            name="paid_status"
            value={paid_status}
            className="form-select"
            onChange={(e) => onRowUpdate(index, e.target)}
          >
            <option value="Paid">Paid</option>
            <option value="Unpaid">Unpaid</option>
          </select>
        </td>
        <td>
          <input
            type="text"
            value={installment_details}
            name="installment_details"
            className="form-control"
            onChange={(e) => onRowUpdate(index, e.target)}
          />
          {errors[index]?.installment_details && <span className="text-danger">Required</span>}
        </td>
        <td>
          <input type="file" className="form-control" onChange={(e) => handleFileChange(e, index)} />
          {receipt && <span>{receipt.name || "File selected"}</span>}
        </td>
        <td>
          <div className="d-flex">
            <ArrowUpwardIcon
              style={{ cursor: index === 0 ? "not-allowed" : "pointer", color: index === 0 ? "gray" : "black" }}
              onClick={() => index > 0 && moveRowUp(index)}
            />
            <ArrowDownwardIcon
              style={{
                cursor: index === rows.length - 1 ? "not-allowed" : "pointer",
                color: index === rows.length - 1 ? "gray" : "black",
              }}
              onClick={() => index < rows.length - 1 && moveRowDown(index)}
            />
            <RemoveCircleOutlineIcon style={{ cursor: "pointer" }} onClick={() => onRowRemove(index)} />
          </div>
        </td>
      </tr>
    );
  });
}

// Main Payment Plan Component
const PaymentPlan = ({ property_id, xCLosePayment, payment_plans }) => {
  const [rows, setRows] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);
  const token = useSelector((state) => state.login.token);

  const axiosConfig = { headers: { Authorization: `Bearer ${token}` } };

  useEffect(() => {
    if (payment_plans && payment_plans.length > 0) {
      const formattedPlans = payment_plans.map((item) => ({
        ...item,
        receipt: item.receipt == null ? "" : item.receipt, // Ensure receipt is set to an empty string if null
      }));
      setRows(formattedPlans); // Initialize rows without duplication
    } else {
      setRows([]);
    }
    setIsEdit(false); // Reset edit mode
  }, [payment_plans]);
  
  const addRow = () => {
    const newRow = {
      date: "",
      // id: property_id+2,
      paid_status: "Unpaid",
      number_of_installment: "",
      paymnet_perccentage: "",
      amount: "",
      installment_details: "",
      receipt: "", // Default to empty
    };
    setRows((prevRows) => [...prevRows, newRow]); // Add the new row while retaining existing rows
  };

  const validateRows = () => {
    const newErrors = rows.map((row) => ({
      date: !row.date ? "Required" : "",
      number_of_installment: !row.number_of_installment ? "Required" : "",
      paymnet_perccentage: !row.paymnet_perccentage ? "Required" : "",
      amount: !row.amount ? "Required" : "",
      installment_details: !row.installment_details ? "Required" : "",
    }));
    setErrors(newErrors);
    return newErrors.every((error) => Object.values(error).every((msg) => !msg));
  };

  const handleSubmit = async () => {
    if (validateRows()) {
      setLoading(true);
  
      try {
        const formData = new FormData();
  
        rows.forEach((row, index) => {
          formData.append(`payments[${index}][date]`, row.date);
          row.id && formData.append(`payments[${index}][id]`, row.id);
          formData.append(`payments[${index}][number_of_installment]`, row.number_of_installment);
          formData.append(`payments[${index}][paymnet_perccentage]`, row.paymnet_perccentage);
          formData.append(`payments[${index}][amount]`, row.amount);
          formData.append(`payments[${index}][paid_status]`, row.paid_status);
          formData.append(`payments[${index}][installment_details]`, row.installment_details);
  
          if (row.receipt instanceof File) {
            formData.append(`payments[${index}][receipt]`, row.receipt, row.receipt.name);
          }
        });
  
        formData.append("property_id", property_id);

        // console.log("******* formData ***** ", formData);

        const response = await axios.post(`${baseUrl}/api/paymentplan`, formData, {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        });
  
        if (response.data.success) {
          xCLosePayment();
        }
      } catch (err) {
        console.error("Error submitting payment plan:", err.response?.data || err.message);
      } finally {
        setLoading(false);
      }
    }
  };

  const resetChanges = () => {
    setIsEdit(false);
    setRows(payment_plans);
  };

  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Installments</th>
            <th>Percentage</th>
            <th>Amount</th>
            <th>Status</th>
            <th>Details</th>
            <th>Receipt</th>
            <th>
              {!isEdit ? (
                <button className="btn btn-primary" onClick={() => setIsEdit(true)}>
                  <ModeEditOutlineIcon /> Edit
                </button>
              ) : (
                <div className="d-flex">
                  <button className="btn btn-danger" onClick={resetChanges}>
                    <CloseIcon /> Cancel
                  </button>
                  <button className="btn btn-info ml-2" onClick={addRow}>
                    <AddIcon /> Add
                  </button>
                </div>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {isEdit ? (
            rows.length > 0 ? (
              <TableRows
                rows={rows}
                onRowUpdate={(index, { name, value }) =>
                  setRows((prev) => prev.map((row, i) => (i === index ? { ...row, [name]: value } : row)))
                }
                onRowRemove={(index) => setRows((prev) => prev.filter((_, i) => i !== index))}
                moveRowUp={(index) =>
                  setRows((prev) =>
                    prev.map((row, i) =>
                      i === index - 1 ? prev[index] : i === index ? prev[index - 1] : row
                    )
                  )
                }
                moveRowDown={(index) =>
                  setRows((prev) =>
                    prev.map((row, i) =>
                      i === index + 1 ? prev[index] : i === index ? prev[index + 1] : row
                    )
                  )
                }
                errors={errors}
              />
            ) : (
              <tr>
                <td colSpan="8" className="text-center">
                  No rows available
                </td>
              </tr>
            )
          ) : (
            payment_plans.map((plan, index) => (
              <tr key={index}>
                <td>{plan.date}</td>
                <td>{plan.number_of_installment}</td>
                <td>{plan.paymnet_perccentage}</td>
                <td>{plan.amount}</td>
                <td>{plan.paid_status}</td>
                <td>{plan.installment_details}</td>
                <td>{plan.receipt? plan.receipt : "No file uploaded"}</td>
              </tr>
            ))
          )}
        </tbody>
      </table>
      {isEdit && (
        <div className="text-center mt-4">
          <button className="btn btn-success" onClick={handleSubmit}>
            <SaveIcon /> Save Changes
          </button>
        </div>
      )}
      {loading && <BeatLoader />}
    </>
  );
};

export default PaymentPlan;
