
import { httpRequest } from "./Axios";



export const GET_API_LOGIN = (email, password) => {
  return {
    method: "POST",
    url: `/api/login`,
    data: {
      email,
      password,
    },
  };
};

// Just for Example one
export const GET_LIST_OF_CLIENT_PROPERTIES = (xPage, xToken) => {
  return {
    method: "GET",
    url: `/api/myproperties?page=${xPage}&perPage=100`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
  };
};

// Poperty Monitor
export const GET_LIST_OF_PROPERTIES_FOR_SLIDER = () => {
  return {
    method: "GET",
    url: `https://ext-api.propertymonitor.com/pm/v1/ticker`,
    headers: {
      // 'company-key': '17316d8d923912711cab9171e34403a6d35bbca3' // old one
      'company-key': '30656acb9d9bbb20bdb73980453c094c5abcd832' // New One 29th May 2024
    },
  };
};

export const GET_PROPERTIES_PAYMENT_PLAN = (xId, xToken) => {
  return {
    method: "GET",
    url: `/api/myproperties/${xId}`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
  };
};

export const GET_TOTAL_NUMBER_OF_PROPERTIES = ( xToken ) => {
  return {
    method: "GET",
    url: `/api/dashboard`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
  };
};

export const LIST_PROPERTY_WITH_K_ESTATES = (
    xId,
    xListed,
    xStatus,
    xExpectedPrice,
    xImages,
    xToken,
  ) => {

  return {
    method: "POST",
    url: `/api/list_with_k`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
    data : {
      'property_id': xId,
      'listed': xListed,
      'status': xStatus,
      'price': xExpectedPrice,
      'images': xImages
    }
  };
};

// Poperty Monitor
export const GET_PROPERTY_MONITOR_STATISTICS_API = (
  xStartDate,
  xStatus,
  xEndDate,
  xLimit,
  xTransactionType,
  xMinBed,
  xMaxBed,
  xPropertyType,
  xLocation
) => {
  return {
    method: "GET",
    // url: `https://demoapi.propertymonitor.com/pm/v1/pmiq?startDate=${xStartDate}&category=${xStatus}&endDate=${xEndDate}&limit=${xLimit}&transactionType=${xTransactionType}&minBeds=${xMinBed}&maxBeds=${xMaxBed}&propertyType=${xPropertyType}`,
    url: `https://ext-api.propertymonitor.com/pm/v1/pmiq?startDate=${xStartDate}&category=${xStatus}&endDate=${xEndDate}&limit=${xLimit}&transactionType=${xTransactionType}&minBeds=${xMinBed}&maxBeds=${xMaxBed}&propertyType=${xPropertyType}&location=${xLocation}`,
    headers: {
      // 'company-key': '17316d8d923912711cab9171e34403a6d35bbca3', //Old One
      'company-key': '30656acb9d9bbb20bdb73980453c094c5abcd832', // New One 29th May 2024
      'x-api-key': 'FQcomdvN8J2ss6TCuYuOVamJkimjxRcc9T1t1C1D', 
      'Accept': 'application/json'
    },
  };
};

// Poperty Monitor
export const GET_PROPERTY_PRICE_TRENDS_IN_DUBAI_API = (
  xMasterDevelopment,
  xPropertyType,
  xLocation
) => {
  return {
    method: "GET",
    url: `https://ext-api.propertymonitor.com/pm/v1/price-volume-trend?propertyType=${xPropertyType}&masterDevelopment=${xMasterDevelopment}&location=${xLocation}`,
    headers: {
      // 'company-key': '17316d8d923912711cab9171e34403a6d35bbca3', // Old One
      'company-key': '30656acb9d9bbb20bdb73980453c094c5abcd832', // New One 29th May 2024
      'x-api-key': 'FQcomdvN8J2ss6TCuYuOVamJkimjxRcc9T1t1C1D', 
      'Accept': 'application/json'
    },
  };
};

// Poperty Monitor
export const GET_RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_AND_PROJECT_API = (
  xMasterDevelopment,
  xPropertyType,
  xLocation,
  xMinBed,
  xMaxBed,
) => {
  return {
    method: "GET",
    // url: `https://demoapi.propertymonitor.com/pm/v1/pmiq?limit=10&masterDevelopment=${xMasterDevelopment}&propertyType=${xPropertyType}&location=${xLocation}&minBeds=${xMinBed}&maxBeds=${xMaxBed}`,
    url: `https://ext-api.propertymonitor.com/pm/v1/pmiq?limit=10&masterDevelopment=Expo%20City&propertyType=Apartment&location=Expo%20City&minBeds=3&maxBeds=3`,
    headers: {
      // 'company-key': '17316d8d923912711cab9171e34403a6d35bbca3', // Old One
      'company-key': '30656acb9d9bbb20bdb73980453c094c5abcd832', // New One 29th May 2024
      'x-api-key': 'FQcomdvN8J2ss6TCuYuOVamJkimjxRcc9T1t1C1D', 
      'Accept': 'application/json'
    },
  };
};

// Poperty Monitor
export const GET_COMMUNITY_TRENDS_API = (
  xLocation
) => {
  return {
    method: "GET",
    url: `https://api.propertymonitor.com/pm/v1/community-historic-trends?masterDevelopment=${xLocation}`,
    headers: {
      'company-key': '30656acb9d9bbb20bdb73980453c094c5abcd832', // New One 29th May 2024
      'x-api-key': 'IsCgnayQ3s6roRTdv8jQo5CFASu8lOre9OTP3dxk', 
      'Accept': 'application/json'
    },
  };
};


export const GET_USER_DETAIL_API = ( xToken ) => {
  return {
    method: "GET",
    url: `/api/myaccount`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
  };
};

export const POST_UPDATE_USER_DETAIL_API = ( 
    xToken,
    xName,
    xEmail,
    xNumber,
    xAddress,
    xCountryId,
    xDob,
    xProfile_Image,
  ) => {
  return {
    method: "POST",
    url: `/api/update-profile`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
    data : {
      'name': xName,
      'email': xEmail,
      'phone': xNumber,
      'address': xAddress,
      "country_id":xCountryId,
      'dob': xDob,
      "profile_image": xProfile_Image,
    }
  };
};

export const GET_LIST_OF_COUNTRIES = ( xToken ) => {
  return {
    method: "GET",
    url: `/api/countries`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
  };
};

export const EMAIL_VERIFICATION_API = (
    xEmail,
    xToken,
  ) => {

  return {
    method: "POST",
    url: `/api/reset-password`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
    data : {
      'email': xEmail
    }
  };
};

export const CHECK_OTP_API = (
  xEmail,
  xCode
) => {

return {
  method: "POST",
  url: `/api/check-otp`,
  headers: {
    // Authorization: `Bearer ${xToken}`
  },
  data : {
    'email': xEmail,
    "code": xCode
  }
};
};

export const UPDATE_PASSWORD_API = (
    xEmail,
    xPassword,
    xConfirmPass,
  ) => {

  return {
    method: "POST",
    url: `/api/change-password`,
    headers: {
      // Authorization: `Bearer ${xToken}`
    },
    data : {
      'email': xEmail,
      "password":xPassword,
      "c_password":xConfirmPass
    }
  };
};



// Here are all Manuall API's

export const ALL_LATEST_TRANSACTION_MANUALLY = (
    xStartDate,
    xEndDate,
    xNoOfBedrooms,
    xPropertyType,
    xLocation,
    xToken
  ) => {
  
  return {
    method: "GET",
    url: `/api/alltransactions?startDate=${xStartDate}&endDate=${xEndDate}&noOfBedrooms=${xNoOfBedrooms}&propertyType=${xPropertyType}&location=${xLocation}`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
  };
};

export const GET_RESIDENTIAL_PROPERTY_PRICE_AS_PER_AREA_API_MANUALLY = (
  xLocation,
  xPropertyType_Id,
  xToken
) => {
  return {
    method: "GET",
    url: `/api/alllocationresidentials?location=${xLocation}&property_type_id=${xPropertyType_Id}`,
    headers: {
      Authorization: `Bearer ${xToken}`
    },
    // data : {
    //   'location': xLocation,
    //   'property_type_id': xPropertyType_Id
    // }
  };
};

export const GET_RESIDENTIAL_PROPERTY_PRICE_AS_PER_PROJECT_API_MANUALLY = (
  xProject,
  xPropertyType_Id,
  xToken
) => {
  return {
    method: "GET",
    url: `/api/allprojectresidentials?project=${xProject}&property_type_id=${xPropertyType_Id}`,
    headers: {
      Authorization: `Bearer ${xToken}` 
    },
    // data : {
    //   'project': xProject,
    // }
  };
};
