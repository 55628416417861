import React from 'react';
import { 
  PieChart, 
  Pie, 
  Cell,
  AreaChart, 
  Area, 
  LineChart, 
  Line, 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  CartesianGrid, 
  Tooltip, 
  Legend, 
  ResponsiveContainer, 
} from 'recharts';


export const PropertyBarGraph = ({ data , dataKey}) => {
  return (

    <ResponsiveContainer width="100%" height={400}>
        <BarChart data={data} margin={{ top: 20, right: 30, left: 0, bottom: 5 }}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="area" />
            <YAxis  />
            <Tooltip />
            <Legend />
            <Bar dataKey={dataKey} fill="#8884d8" barSize={50} />
        </BarChart>
    </ResponsiveContainer>
  );
}

export const PropertyAreaChart = ({ data, xDataKey }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <AreaChart
        // width={800}
        // height={400}
        data={data}
        margin={{ top: 20, right: 30, left: 5, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xDataKey}/>
        <YAxis />
        <Tooltip />
        <Legend />
        <Area type="monotone" dataKey="properties" stackId="1" stroke="#8884d8" fill="#8884d8" name="Properties" />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export const CommercialPropertyLineChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={400}>
      <LineChart
        // width={800}
        // height={400}
        data={data}
        margin={{ top: 20, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="year" />
        <YAxis />
        <Tooltip />
        <Legend />
        <Line type="monotone" dataKey="retail" stroke="#8884d8" name="Retail" />
        <Line type="monotone" dataKey="office" stroke="#82ca9d" name="Office" />
        <Line type="monotone" dataKey="industrial" stroke="#ffc658" name="Industrial" />
        {/* Add more lines for other property types as needed */}
      </LineChart>
    </ResponsiveContainer>
  );
}

const COLORS = ['#66A5AD', '#C4DFE6'];

export const PieChartComponent = ({ activeClients, inactiveClients }) => {
  const totalClients = activeClients + inactiveClients;

  const data = [
    { name: 'Active Clients', value: activeClients },
    { name: 'Inactive Clients', value: inactiveClients },
  ];

  const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, percent, index }) => {
    const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
    const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
    const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));

    return (
      <text x={x} y={y} fill="black" textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
        {`${(percent * 100).toFixed(0)}% (${data[index].value})`}
      </text>
    );
  };

  return (
    <PieChart width={400} height={400}>
      <Pie
        data={data}
        // cx={200}
        // cy={200}
        // labelLine={false}
        // outerRadius={120}
        // fill="#8884d8"
        // dataKey="value"

        innerRadius={60}
        outerRadius={80}
        cx="50%"
        cy="50%"
        fill="#8884d8"
        paddingAngle={5}
        dataKey="value"

        label={renderCustomizedLabel}
      >
        {data.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
      <Tooltip />
      <Legend />
    </PieChart>

  //   <PieChart>  
  //     <Pie
  //         data={data}
  //         innerRadius={60}
  //         outerRadius={80}
  //         cx="50%"
  //         cy="50%"
  //         fill="#8884d8"
  //         paddingAngle={5}
  //         dataKey="value"
  //         label={renderCustomizedLabel}
  //         labelLine={false}
  //     >
  //       {data.map((entry, index) => (
  //         <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
  //       ))}
  //     </Pie>
  // </PieChart>
  );
};

