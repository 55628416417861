import React from 'react';
import './policy.css';

const PrivacyPolicy = () => {
  return (
    <div className="privacy-policy">
      <h1>PRIVACY POLICY</h1>
      <section>
        <h2>Protecting Your Privacy</h2>
        <p>At K Estates, safeguarding our clients’ privacy is a top priority. This is intended to provide you with clear information about the data we collect from you and how we utilize it.</p>
      </section>
      <section>
        <h2>Information We Collect</h2>
        <p>In the normal course of conducting business, we gather, retain, and utilize information about your financial situation to serve you with the highest professional standards. This data may be collected through various means, including written communication, in-person interactions, telephone conversations, electronic exchanges, and applications or forms. It may encompass details about your transactions with us, our affiliates, and other relevant parties. This includes information regarding account balances, counterparties to transactions, data received from credit reporting agencies (such as credit reports), and information obtained from online sources.</p>
      </section>
      <section>
        <h2>Information We Disclose</h2>
        <p>Your information is your personal asset, and we do not sell it. We will only share your information with our affiliates when you make a specific request. Additionally, we may disclose information to third parties on a need-to-know basis to enhance our client relationships. This is done in accordance with applicable laws and regulations. The information shared is limited to what is required for these third parties to perform their designated tasks. For example, we may share information with data processing providers for tasks like statement mailing and check printing, as well as with securities broker-dealers when executing orders. In some cases, we may be legally compelled to disclose your information or comply with requests made by government entities.</p>
      </section>
      <section>
        <h2>Security of Your Information</h2>
        <p>We take the security of your personal information seriously. To protect your data, we maintain stringent physical, electronic, and procedural safeguards. We have established internal policies that strictly prohibit the unauthorized use or disclosure of your information. Access to your data is restricted to employees who require it to manage your financial affairs or provide services to you. Our employees and account management practices are governed by comprehensive regulatory procedures and our internal K ESTATES procedures. We ensure the confidentiality of your information through employee training and operational protocols.</p>
        <p>The security of transactions conducted on our website is of paramount importance. To use forms on our site, your browser software must be equipped with 128-bit encryption, the most powerful and secure form of encryption currently available in North American internet browsers. This encryption method ensures that your data remains protected during transmission.</p>
      </section>
      <section>
        <h2>Contact Us</h2>
        <p>If you have any inquiries or concerns regarding our Privacy Policy, please reach out to our Customer Care team at info@kestates.ae, located at our Dubai brokerage office.</p>
      </section>
      <p>Thank you for choosing K Estate as your trusted partner. Your privacy and security are of the utmost importance to us, and we are committed to maintaining the highest standards of data protection.</p>
    </div>
  );
};

export default PrivacyPolicy;
