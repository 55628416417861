import React, { useState } from 'react';
import { Slide, Fade, Zoom } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css'
import { TextField, Typography } from '@mui/material';
import BedIcon from '@mui/icons-material/Bed';
import BathtubIcon from '@mui/icons-material/Bathtub';
import SquareFootIcon from '@mui/icons-material/SquareFoot';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import { Grid, Radio, RadioGroup, FormControlLabel } from '@mui/material';

//Local
import "./index.css";
import { AmountSaperator } from '../Utilities';

  const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: '30rem',
    width:"100%"
  }

const ListingModalComponent = ({
    xSlideImagesArr,
    xArea,
    xNoOfBath,
    xNoOfBeds,
    xPropertyName,
    xRefNumber,
}) => {
    const [propertyType, setPropertyType] = useState('buy');
    const [expectedAmount, setExpectedAmount] = useState('');

    const handlePropertyTypeChange = (event) => {
        setPropertyType(event.target.value);
    };

    const handleExpectedAmountChange = (event) => {
        setExpectedAmount(event.target.value);
    };

    return(
        <div className="slide-modal-container">
            <Slide transitionDuration={100} autoplay={false} >
            {xSlideImagesArr.map((slideImage, index)=> (
                <div key={index}>
                    {/* <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})` }}>
                    </div> */}
                     <img
                        src={`${slideImage.url}`}
                        // style={{
                        //     width: '100%',
                        //     height: '15rem', // Use 'auto' for height to maintain aspect ratio
                        //     maxWidth: '100%', // Ensure image doesn't exceed its container's width
                        //     maxHeight: '16rem', // Limit the maximum height of the image
                        //     objectFit: 'cover', // Cover the entire container while maintaining aspect ratio
                        // }}
                        alt="Slide Image"
                        className='image-style'
                    />
                </div>
            ))} 
            </Slide>
            
            <div style={{ marginTop: '1rem',  }} >
                <div className='property-details' >
                    <Typography variant="h5" style={{ fontSize: '1.5rem', fontWeight:'bold' }} gutterBottom>
                        {xPropertyName}
                    </Typography >
                    <Typography style={{ color:'gray', fontSize: '1.25rem', fontWeight:'bold' }} >
                        {AmountSaperator(175000000)} AED
                        <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"flex-start" }}>
                            <Typography variant="caption" style={{ color:'Black', fontWeight:'bold', marginRight: '1rem' }} >
                               From the last month:
                            </Typography >
                            <div style={{ display:'flex', flexDirection:'row', alignItems:'center', justifyContent:"flex-start",}}>
                                <Typography variant="subtitle1" style={{ color:'green', fontSize: '1rem', fontWeight:'bold' }} >
                                  5%
                                </Typography >
                                <TrendingUpIcon style={{ color:'green', width:'1.75rem', height:'1.75rem' }}  />
                            </div>
                        </div>
                    </Typography >
                </div>
                <div className='property-features' >
                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start' }} >
                        <BedIcon style={{ color:'gray' }} />
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {xNoOfBeds}
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '0.5rem' }} >
                        <BathtubIcon style={{ color:'gray' }} />
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {xNoOfBath}
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '0.5rem' }} >
                        <SquareFootIcon style={{ color:'gray' }} />
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {xArea} BUA
                        </Typography>
                    </div>

                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start', marginLeft: '0.5rem' }} >
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem', fontWeight:'bold', color:'gray' }} >
                            Ref. No
                        </Typography>
                        <Typography style={{ fontSize:'0.85rem', marginLeft: '0.35rem' }} >
                            {xRefNumber}
                        </Typography>
                    </div>
                </div>

            </div>

            <Grid container spacing={2} style={{ paddingTop: '1rem' }}>
                <Grid item xs={12}>
                    <RadioGroup row aria-label="property-type" name="property-type" value={propertyType} onChange={handlePropertyTypeChange}>
                        <FormControlLabel value="buy" control={<Radio />} label="Buy" />
                        <FormControlLabel value="rent" control={<Radio />} label="Rent" />
                    </RadioGroup>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Expected Price"
                        variant="outlined"
                        
                        value={expectedAmount}
                        onChange={handleExpectedAmountChange}
                    />
                </Grid>
            </Grid>
      </div>
    )
}

export default ListingModalComponent;