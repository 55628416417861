import React, { useEffect, useState } from 'react';
import {
  BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer
} from 'recharts';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';

// Local

import { Box, Typography } from '@mui/material';
import { AmountSaperator } from '../../Utilities';

// function check 2024
const calculateChanges = (data, period) => {

  if(period == 2 && data.length > period){

    const first_val = data[data.length - 1];
    
    const second_val = data[data.length - (period)];

    const difference = first_val.avg_price - second_val.avg_price;

    const newValue = difference / second_val.avg_price;

    const changesInPercentage = Number(newValue) * 100;

    return changesInPercentage;
  }
  else if(period == 3 && data.length > period ){
    
    const first_val = data[data.length - 1].avg_price;

    const second_val = (data[data.length - (2)].avg_price + data[data.length - (3)].avg_price + data[data.length - (4)].avg_price) / 3 ;

    const difference = first_val - second_val;

    const newValue = difference / second_val;

    const changesInPercentage = Number(newValue) * 100;

    return changesInPercentage;
  }
  else if(period == 6 && data.length > period ){

    
    const first_val = data[data.length - 1].avg_price;

    const second_val = (data[data.length - (2)].avg_price + data[data.length - (3)].avg_price + data[data.length - (4)].avg_price + data[data.length - (5)].avg_price + data[data.length - (6)].avg_price + data[data.length - (7)].avg_price) / 6 ;

    const difference = first_val - second_val;

    const newValue = difference / second_val;

    const changesInPercentage = Number(newValue) * 100;

    return changesInPercentage;
  }
  else if(period == 12 && data.length > period ){

    const first_val = data[data.length - 1].avg_price;

    const second_val = (data[data.length - (2)].avg_price + data[data.length - (3)].avg_price + data[data.length - (4)].avg_price + data[data.length - (5)].avg_price + data[data.length - (6)].avg_price + data[data.length - (7)].avg_price + data[data.length - (8)].avg_price + data[data.length - (9)].avg_price + data[data.length - (10)].avg_price + data[data.length - (11)].avg_price + data[data.length - (12)].avg_price + data[data.length - (13)].avg_price) / 12 ;

    const difference = first_val - second_val;

    const newValue = difference / second_val;

    const changesInPercentage = Number(newValue) * 100;
    return changesInPercentage;
  }
}


const ListingBarChartComp = ({xRawData, xChanges, xPriceChangesData}) => {

  const [lastMonth, setLastMonth] = useState(0);
  const [last3Months, setLast3Months] = useState(0);
  const [last6Months, setLast6Months] = useState(0);
  const [last12Months, setLast12Months] = useState(0);

  // console.log("******* xPriceChangesData ******* ", xPriceChangesData)

  useEffect(() => {
    if (xPriceChangesData.length > 1) {
      setLastMonth(calculateChanges(xPriceChangesData, 2));
    }
    if (xPriceChangesData.length > 3) {
      setLast3Months(calculateChanges(xPriceChangesData, 3));
    }
    if (xPriceChangesData.length > 6) {
      setLast6Months(calculateChanges(xPriceChangesData, 6));
    }
    if (xPriceChangesData.length >= 12) {
      setLast12Months(calculateChanges(xPriceChangesData, 12));
    }
  }, [xPriceChangesData]);

  const CustomTooltipForPerformingAreas = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
        const price = payload[0].value;
        const formattedPrice = AmountSaperator(price); // Call your function here
        return (
            <div style={{ backgroundColor:'white', border:'0.5px solid #000', padding:'0.25rem', borderRadius:5 }}>
            <Typography variant='subtitle2' >
                {`Month: ${label}`}
            </Typography>
            <Typography variant='subtitle2' >
                {`Avg. Price: ${formattedPrice}`}
            </Typography>
            </div>
        );
    }
    
    return null;
  };


  return (
    <>
        <ResponsiveContainer width="100%" height={400}>
          <BarChart data={xRawData} margin={{ top: 20, right: 10, left: 5, bottom: 5 }}  >
            <CartesianGrid strokeDasharray="1 1" />
            <XAxis 
              dataKey={"month"} 
              fontSize={'0.65rem'} 
              fontFamily='Montserrat' 
              interval={0}
              tick={({ x, y, payload }) => (
                  <text 
                    x={x} 
                    y={y} 
                    dy={16} 
                    textAnchor="middle" 
                    fill="#666" 
                    fontSize={'0.65rem'}
                    fontFamily='Montserrat'
                  >
                    {payload.value.length > 10 ? `${payload.value.substring(0, 8)}...` : payload.value}
                  </text>
              )}
            />
            <YAxis 
                fontSize={'0.65rem'} 
                tickFormatter={(value) => `AED ${AmountSaperator(value)}`}
            />
            <Tooltip content={CustomTooltipForPerformingAreas} />
            <Legend />
            <Bar dataKey="total_value" name="Months" fill="#0F2027" barSize={20} />
          </BarChart>

        </ResponsiveContainer>

        
        {
          /**
           * 
           * <Box display="flex" justifyContent="space-around" mt={4}>
          <Box textAlign="center">
            <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last month</Typography>
            {
                lastMonth > 0 && lastMonth < 30?
                  <ArrowCircleUpIcon style={{ color: "green" }} />
                  :
                  (
                    lastMonth == undefined || lastMonth > 30 || lastMonth < -30?
                      <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                      :
                      <ArrowCircleDownIcon style={{ color: "red" }} />
                  )
            }
            <Typography 
              variant="subtitle2" 
              style={{ fontFamily:'Montserrat' }} 
              color={
                lastMonth > 0 && lastMonth < 30 ? "green" 
                : 
                ( 
                  lastMonth == undefined || lastMonth > 30 || lastMonth < -30 ? 
                    "lightgray" 
                    :
                    "red" 
                )}
              >
                Change<br />{
                  lastMonth && lastMonth > -30 && lastMonth < 30 ? Number(lastMonth).toFixed(2)+"%" : "N/A"}  
              </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last 3 months</Typography>
            {
                last3Months > 0 && last3Months < 30 ?
                  <ArrowCircleUpIcon style={{ color: "green" }} />
                  :
                  (
                    last3Months == undefined || last3Months > 30 || last3Months < -30?
                      <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                      :
                      <ArrowCircleDownIcon style={{ color: "red" }} />
                  )
            }
              <Typography 
                variant="subtitle2" 
                style={{ fontFamily:'Montserrat' }} 
                color={
                  last3Months > 0 && last3Months < 30 ? "green" 
                  : 
                  ( 
                    last3Months == undefined || last3Months > 30 || last3Months < -30 ? 
                      "lightgray" 
                      :
                      "red" 
                  )}
                >
                  Change<br />{
                    last3Months && last3Months > -30 && last3Months < 30 ? Number(last3Months).toFixed(2)+"%" : "N/A"}  
                </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last 6 months</Typography>
            {
                last6Months > 0 && last6Months < 30 ?
                  <ArrowCircleUpIcon style={{ color: "green" }} />
                  :
                  (
                    last6Months == undefined || last6Months > 30 || last6Months < -30?
                      <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                      :
                      <ArrowCircleDownIcon style={{ color: "red" }} />
                  )
            }
              <Typography 
                variant="subtitle2" 
                style={{ fontFamily:'Montserrat' }} 
                color={
                  last6Months > 0 && last6Months < 30 ? "green" 
                  : 
                  ( 
                    last6Months == undefined || last6Months > 30 || last6Months < -30 ? 
                      "lightgray" 
                      :
                      "red" 
                  )}
                >
                  Change<br />{
                    last6Months && last6Months > -30 && last6Months < 30 ? Number(last6Months).toFixed(2)+"%" : "N/A"}  
              </Typography>
          </Box>
          <Box textAlign="center">
            <Typography variant="subtitle2" style={{ fontFamily:'Montserrat' }} color="textSecondary">Last 12 months</Typography>
            {
                last12Months > 0 && last12Months < 30 ?
                  <ArrowCircleUpIcon style={{ color: "green" }} />
                  :
                  (
                    last12Months == undefined || last12Months > 30 || last12Months < -30 ? 
                      <ArrowCircleDownIcon style={{ color: "lightgray" }} />
                      :
                      <ArrowCircleDownIcon style={{ color: "red" }} />
                  )
            }
                <Typography 
                    variant="subtitle2" 
                    style={{ fontFamily:'Montserrat' }} 
                    color={
                      last12Months > 0 && last12Months < 30 ? "green" 
                      : 
                      ( 
                        last12Months == undefined || last12Months > 30 || last12Months < -30 ? 
                          "lightgray" 
                          :
                          "red" 
                      )}
                    >
                      Change<br />{
                        last12Months && last12Months > -30 && last12Months < 30 ? Number(last12Months).toFixed(2)+"%" : "N/A"}  
                  </Typography>
          </Box>
        </Box>
           * 
           */
        }
        <Typography variant="subtitle2" style={{ fontFamily:'Montserrat', marginTop: "3rem", textAlign:"center", fontSize: '0.65rem' }} color="textSecondary">* The changes are based on Property Monitor data</Typography>
    </>
  );
};

export default ListingBarChartComp;
