import React, { useEffect, useState } from 'react';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Container,
    Avatar,
    FormHelperText
} from '@mui/material';
import ImageCompressor from 'image-compressor.js';

import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './AddPropertyComp.css';
import ImageUploading from 'react-images-uploading';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation } from 'react-router-dom';
import { AmountSaperator } from '../Utilities';
import axios from 'axios';
import { BaseUrl } from '../Components/BaseUrl';
import { useSelector } from 'react-redux';
import { BaseImageUrl } from '../Components/BaseImageUrl';
import { Image } from 'antd';
import { baseUrl, profileImageBaseUrl, propertyImagesUrl } from '../Pages/Server/baseUrls';
import BeatLoader from "react-spinners/BeatLoader";
import SaveIcon from '@mui/icons-material/Save';
import CurrencyInput from 'react-currency-input-field';

const UpdatePropertyComp = ({id,owner,locations,developers,property_types,xCLoseModal, xImages,updateproperty,onRemoveImage }) => {

    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
        },
    };

    useEffect(() => {
        if (updateproperty && updateproperty != null) {
        
        
        setPropertyName(updateproperty.title ? updateproperty.title : '' );
        setSubPropertyName(updateproperty.sub_title ? updateproperty.sub_title : '' );
        setBedrooms(updateproperty.bedrooms !="" ? updateproperty.bedrooms : '' )
        setBathrooms(updateproperty.bathrooms ? updateproperty.bathrooms : '' )
        setTotalArea(updateproperty.area_size ? updateproperty.area_size : '' )
        setUnitno(updateproperty.unit_no ? updateproperty.unit_no : '' )
        setListingType(updateproperty.status ? updateproperty.status : '' )
        setPropertyType(updateproperty.property_type_id ? updateproperty.property_type_id : '')
        setExpectedPrice(updateproperty.price ? updateproperty.price : '' )
        setLocation(updateproperty.location_id?updateproperty.location_id:"")
        setDeveloper(updateproperty.developer_id?updateproperty.developer_id:"")
        setPropertyImages(updateproperty.images && updateproperty.images.length > 0?updateproperty.images:[])
        setHandover(updateproperty.hand_over ? updateproperty.hand_over : '')

        // if (updateproperty.images && updateproperty.images.length > 0) {
        //     setImages(updateproperty.images);
        //     setShowImages(updateproperty.images)
        //   } else {
        //       setImages([]);
        //       setShowImages([])
        //   }
        setImages([]);
              setShowImages([])

          if (updateproperty.owners && updateproperty.owners.length > 0) {
            setSelectedUsers(updateproperty.owners);
          } else {
            setSelectedUsers([]);
          }

          
        } else {
          console.log("no proper");
        }
      }, [updateproperty]);

    // useEffect(() => {
    //     if (xImages && xImages.length > 0) {
    //       setImages(xImages);
    //       setShowImages(xImages)
    //     } else {
    //         setImages([]);
    //         setShowImages([])
    //     }
    //   }, [xImages]);

  

    const { state } = useLocation();
    const [propertyImages, setPropertyImages] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [subPropertyName, setSubPropertyName] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [totalArea, setTotalArea] = useState('');
    const [location, setLocation] = useState('');
    const [developer,setDeveloper] = useState('')
    const [listingType, setListingType] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [expectedPrice, setExpectedPrice] = useState('');
    const [showimages, setShowImages] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    // const [showSelectedUsers, setShowSelectedUsers] = useState([]);
    const [selectedData,setSelectdData] = useState([])
    const [owners,setOwners] = useState([])
    const [property,setProperty] = useState({})
    const [errors, setErrors] = useState({});
   const [loading,setLoading] = useState(false);
   const [unitno, setUnitno] = useState('');
   const [handover,setHandover] = useState("")
   const [titledeed,setTitledeed] = useState(null)

   const [floorplan,setFloorplan] = useState(null)
  

   useEffect(() =>{
       // getPropertyData();
    },[id]);

    const getPropertyData = () => {
        axios.get(baseUrl+"/api/properties/"+id,axiosConfig)
        .then((res) =>{
            if(res.data.success){
                // console.log("********* REESPONSE  ******* ", res.data)
                setProperty(res.data.property)
                setPropertyName(res.data.property.title)
                setPropertyType(res.data.property.property_type_id )
                setExpectedPrice(res.data.property.price)
                setListingType(res.data.property.status)
                setTotalArea(res.data.property.area_size)
                setLocation(res.data.property.location_id)
                // setShowSelectedUsers(res.data.property.owners)
                setBedrooms(res.data.property.bedrooms)
                setBathrooms(res.data.property.bathrooms)
                setDeveloper(res.data.property.developer_id)
                const owners = res.data.property.owners
                const ids = owners.map(item => item.id);
                setSelectedUsers(owners)
                setUnitno(res.data.property.unit_no)
            }
        }).catch((err) =>{
            console.log("err",err);
        })
    }

    const handleUserChange = (event) => {
      setSelectedUsers(event.target.value);
    };
    
    const compressImages = async () => {
        const compressedImages = [];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            const options = {
                maxWidth: 800,
                maxHeight: 600,
                quality: 0.7, // Adjust compression quality as needed (0.7 means 70%)
                success(result) {
                    compressedImages.push(result);
                },
                error(e) {
                    console.error(e.message);
                },
            };

            await new ImageCompressor(file, options).compress();
        }
    }

    const maxNumber = 69;
  
    const onChange = (imageList, addUpdateIndex) => {
        const newimages = imageList.map(option => option.file);
       
      setShowImages(imageList);
      setImages(newimages)
    };
    

    // useEffect(() => {
    //     // Map the URLs to objects with 'data_url' key
    //     const imageURLs = PropertyDetails.xSlideImagesArr.map((e) => ({
    //       data_url: e.url
    //     }));
    //     // setImages(imageURLs);
    //     // setPropertyName(PropertyDetails.xPropertyName);
    //     // setBedrooms(PropertyDetails.xNoOfBeds)
    //     // setBathrooms(PropertyDetails.xNoOfBath)
    //     // setTotalArea(PropertyDetails.xArea)
    //     // setListingType(PropertyDetails.status)
    //     // setPropertyType({ name:PropertyDetails.type, value: undefined })
    //     // setExpectedPrice(PropertyDetails.xPrice)
    //     // setPropertyType(PropertyDetails.type);
    //   }, [PropertyDetails]); // Make sure to include PropertyDetails in the dependency array
    
  
    const handleImageUpload = (event) => {
      const files = Array.from(event.target.files);
      setPropertyImages(files);
    };
  
    const handleFormSubmit = () => {
      // Implement form submission logic here
      console.log('Form submitted:', {
        propertyImages,
        propertyName,
        bedrooms,
        bathrooms,
        totalArea,
        location,
        listingType,
        propertyType,
        expectedPrice,
      });
    };

    const dubaiAreas = [
        { value:'dubai_marina', label:'Dubai Marina' },
        { value:'dubai_hills', label:'Dubai Hills' },
        { value:'albarsha_1', label:'Al Barsha 1' },
        { value:'albarsha_2', label:'Al Barsha 2' },
        { value:'albarsha_3', label:'Al Barsha 3' },
        { value:'jbr', label:'Jumeirah Beach' },
    ]

     // Sample data
    const users = [
        { id: 1, name: 'User 1' },
        { id: 2, name: 'User 2' },
        { id: 3, name: 'User 3' },
        // Add more users as needed
    ];

  

    const handleAutocompleteChange= (event, value) =>{
        // console.log("**** Value **** ", value)
        const ids = value.map(option => option);
        setSelectedUsers(ids);
        // setShowSelectedUsers(value)
    }

    const handleHandover = (e) =>{
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (file && !allowedTypes.includes(file.type)){
            const newErrors = {};
            newErrors.handover = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
            setErrors(newErrors);
            e.target.value = null;
            setHandover(null)
            return 
        }else{
            setHandover(file)
        }
     }
 
     const handleTtitledeed = (e) =>{
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (file && !allowedTypes.includes(file.type)){
            const newErrors = {};
            newErrors.titledeed = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
            setErrors(newErrors);
            e.target.value = null;
            setTitledeed(null)
            return 
        }else{
            setTitledeed(file)
        }
      }
 
      const handleFloorplan = (e) =>{
        const file = e.target.files[0];
        const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
        if (file && !allowedTypes.includes(file.type)){
            const newErrors = {};
            newErrors.floorplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
            setErrors(newErrors);
            e.target.value = null;
            setFloorplan(null)
            return 
        }else{
            setFloorplan(file)
        }
      }

    const validateForm = () => {
        const newErrors = {};
        if (!propertyName) newErrors.propertyName = 'Property name is required';
        // if (!bedrooms) newErrors.bedrooms = 'Number of bedrooms is required';
        // if (!bathrooms) newErrors.bathrooms = 'Number of bathrooms is required';
        if (!totalArea) newErrors.totalArea = 'Total area is required';
        if (!location) newErrors.location = 'Location is required';
        if (!developer) newErrors.developer = 'Developer is required';
        if (!expectedPrice) newErrors.expectedPrice = 'Expected price is required';
        if (!propertyType) newErrors.propertyType = 'Property type is required';
        if (!listingType) newErrors.listingType = 'Listing type is required';
        if (!listingType) newErrors.selectedUsers = 'User is required';
        if (!unitno) newErrors.unitno = 'Unitno  is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

   const ImageUpdate = (img) =>{
    console.log("d",img);
   }

   const ImageRemove =(img) =>{
   
    const data = {property_id:img.property_id,image_id:img.id}
       axios.post(baseUrl+"/api/deletepropertyImage",data,axiosConfig)
       .then((res) =>{
        if(res.data.success){
            const images = propertyImages.filter(image => image.id !== img.id);
            setPropertyImages(images)
            onRemoveImage(images);
            
        }
        console.log("re",res.data);
       }).catch((err) =>{
        console.log("err",err);
       })
   }
    const handleSubmit = async () => {
      
        if (!validateForm()) return;
    
        setLoading(true);
    
        const formData = new FormData();
        // images.forEach((file, index) => {
        //     formData.append(`images[${index}]`, file);
        // });

     
    
        selectedUsers.forEach((file, index) => {
            formData.append(`owners[${index}]`, file.id);
          });

        formData.append("id",id)
        formData.append("title",propertyName)
        formData.append("sub_title", subPropertyName)
        formData.append("bedrooms",bedrooms)
        formData.append("bathrooms",bathrooms)
        formData.append("area_size",totalArea)
        formData.append("location_id",location)
        formData.append("property_type_id",propertyType)
        formData.append("status",listingType)
        formData.append("price",expectedPrice)
        formData.append("developer_id",developer)
        formData.append("unit_no",unitno)
        formData.append('hand_over', handover); 
        formData.append('titledeed', titledeed); 
        formData.append('floorplan', floorplan); 
        // formData.append('old_handover', updateproperty.hand_over); 
        formData.append('old_titledeed', updateproperty.title_deed); 
        formData.append('old_floorplan', updateproperty.floor_plan); 

            // const formData = new FormData();
            // formData.append('images', images);
            // xCLoseModal()
        axios.post(baseUrl+"/api/update-properties",formData,axiosConfig)
        .then((res) =>{
            if(res.data.success){
            setLoading(false)
            setFloorplan(null)
            setHandover(null)
            setTitledeed(null)
            xCLoseModal()
            let hasFiles = false;
            const imagesFormData = new FormData();
            images.forEach((file, index) => {
                if(file && file != undefined){
                    imagesFormData.append(`images[${index}]`, file);
                    hasFiles = true;
                }
            });

           
            imagesFormData.append("property_id", res.data.property_id);
            if (hasFiles) {
            axios.post(baseUrl + "/api/upload-files", imagesFormData, axiosConfig)
            .then((res) =>{
                setImages([])
                setShowImages([])
                xCLoseModal()
                console.log("images upload",res.data);
            }).catch((err) =>{
                console.log("err:",err);
            })
        }else{
            console.log("no images");

        }
            }
            
             else {
                // Handle response with success: false
                setLoading(false);
                console.log("Server response was not successful:", res.data);
            }
        }).catch((err) =>{
            setLoading(false)
            console.log("err",err);
        });
    
    }


    return(
        // <Container maxWidth={'xl'} >
            <div>
                <div className='' >
                    <div className="">
                        <ImageUploading
                            multiple
                            value={showimages}
                            onChange={onChange}
                            maxNumber={maxNumber}
                            dataURLKey="data_url"
                        >
                            {({
                                imageList,
                                onImageUpload,
                                onImageRemoveAll,
                                onImageUpdate,
                                onImageRemove,
                                isDragging,
                                dragProps,
                            }) => (
                            // write your building UI
                            <div className="upload__image-wrapper" >
                                <Button variant="contained" color="primary" type="submit" size='small' onClick={onImageUpload} style={{ marginLeft: '0rem' }} >
                                    Click or Drop here
                                </Button>
                                <Button variant="contained" color="primary" type="submit" size='small' onClick={onImageRemoveAll} style={{ marginLeft: '1rem' }} >
                                    Remove all images
                                </Button>

                                
                                <Grid container spacing={1} style={{ marginTop: '1rem', width:'100%' }} className='images-list' >
                                    {
                                        imageList.slice(0,10).map((image, index) => {
                                            return(
                                                <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={3.5} margin={1} >
                                                    <div className='image-controller' >
                                                        {/* <ChangeCircleIcon onClick={() => onImageUpdate(index)} color='info' /> */}
                                                        <RemoveCircleIcon onClick={() => onImageRemove(index)} color='error' />
                                                    </div>
                                                    {
                                                        image['data_url']?
                                                        <img src={image['data_url']} alt="" style={{ width:'280px', height:'180px' }} />
                                                        :
                                                        <img src={propertyImagesUrl+"/"+image.name} alt="" style={{ width:'280px', height:'180px' }} />
                                                    }
                                                </Grid>
                                            )
                                        })}
                                            {propertyImages?.map((img,index) =>(
                                                <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={3.5} margin={1} >
                                                <div className='image-controller' >
                                                <div className='image-controller' >
                                                        {/* <ChangeCircleIcon onClick={() => ImageUpdate(img)} color='info' /> */}
                                                        <RemoveCircleIcon  onClick={() => ImageRemove(img)} color='error' onMouseOver={(e) => {
            e.currentTarget.style.cursor = 'pointer'; // Change cursor on hover
          }}  />
                                                    </div>
                            <Image key={index}
                            preview={false}
                            width={280}
                            height={180}
                            src={propertyImagesUrl+"/"+img.name}
                          />
                             </div>
                        </Grid>
                        ))
                     
                        }
                                </Grid>
                                <Typography variant='caption' color={'lightgray'} >
                                    Only max 10 images can be shown
                                </Typography>
                               
                            </div>
                            )}
                        </ImageUploading>

                        <div style={{ display: 'flex', gap: '20px' }}>
                        {/* {updateproperty?.images?.map((img,index) =>(
                            <Image key={index}
                            preview={false}
                            width={250}
                            height={150}
                            src={propertyImagesUrl+"/"+img.name}
                          />
                        ))} */}
                        </div>
                    </div>
                    
                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <TextField
                            label="Unit no"
                            value={unitno}
                            onChange={(e) => setUnitno(e.target.value)}
                            fullWidth
                            error={!!errors.unitno}
                            helperText={errors.unitno}
                        />
                    </Grid>
                  

                        <Grid item xs={12} md={12} lg={3} xl={3}>
                            <TextField
                                label="Total Area"
                                value={totalArea}
                                onChange={(e) => setTotalArea(e.target.value)}
                                fullWidth
                                error={!!errors.totalArea}
                                helperText={errors.totalArea}
                            />
                        </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                label="Project"
                                value={propertyName}
                                onChange={(e) => setPropertyName(e.target.value)}
                                fullWidth
                                error={!!errors.propertyName}
                                helperText={errors.propertyName}
                            />
                        </Grid>
                    </Grid> 

                          <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Phase or building"
                            value={subPropertyName}
                            onChange={(e) => setSubPropertyName(e.target.value)}
                            fullWidth
                            error={!!errors.propertyName}
                            helperText={errors.propertyName}
                        />
                    </Grid>
                </Grid>   

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <InputLabel>Property Type</InputLabel>
                                <Select
                                value={propertyType}
                                onChange={(e) => {
                                    // console.log(e.target)
                                    setPropertyType(e.target.value)
                                }}
                                // defaultValue={PropertyDetails.type}
                                >
                                    
                                    {
                                    property_types.map((e, index)=>{
                                        return(
                                            <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                                <FormHelperText className='text-danger'>{errors.propertyType}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl component="fieldset">
                                <FormLabel component="legend">Status</FormLabel>
                                <RadioGroup
                                row
                                value={listingType}
                                onChange={(e) => setListingType(e.target.value)}
                                >
                                <FormControlLabel
                                    value="Rent"
                                    control={<Radio color="primary" />}
                                    label="Rent"
                                />
                                <FormControlLabel
                                    value="Sale"
                                    control={<Radio color="primary" />}
                                    label="Sale"
                                />
                                 <FormControlLabel
                                    value="Off_plan"
                                    control={<Radio color="primary" />}
                                    label="Off Plan"
                                />
                                 <FormControlLabel
                                    value="Sold"
                                    control={<Radio color="primary" />}
                                    label="Sold"
                                />
                                    <FormControlLabel
                                    value="Ready"
                                    control={<Radio color="primary" />}
                                    label="Ready"
                                />
                                </RadioGroup>
                                <FormHelperText className='text-danger'>{errors.propertyType}</FormHelperText>

                            </FormControl>
                        </Grid>
                    </Grid>  
                    {propertyType ==10?null:              

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                                <InputLabel>No of Bath</InputLabel>
                                <Select
                                value={bathrooms}
                                onChange={(e) => setBathrooms(e.target.value)}
                                >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                    {num} Bathroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                                </Select>
                                <FormHelperText className='text-danger'>{errors.bathrooms}</FormHelperText>

                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                              
                                <Select value={bedrooms}  onChange={(e) => setBedrooms(e.target.value)} label="No of Bedrooms">
                                <MenuItem value=""><em>None</em></MenuItem>

                           <MenuItem value="-1" >Studio </MenuItem>
                           <MenuItem value="1" >1 Bedroom </MenuItem>
                           <MenuItem value="2" >2 Bedrooms </MenuItem>
                           <MenuItem value="3" >3 Bedrooms </MenuItem>
                           <MenuItem value="4" >4 Bedrooms </MenuItem>
                           <MenuItem value="5" >5 Bedrooms </MenuItem>
                           <MenuItem value="6" >6 Bedrooms </MenuItem>
                           <MenuItem value="7" >7 Bedrooms </MenuItem>
                           <MenuItem value="8" >8 Bedrooms </MenuItem>
                           <MenuItem value="9" >9 Bedrooms </MenuItem>
                           <MenuItem value="10" >10 Bedrooms </MenuItem>
                               </Select>
                                {/* <Select
                                value={bedrooms}
                                onChange={(e) => setBedrooms(e.target.value)}
                                >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                    {num} Bedroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                               
                                </Select> */}
                         <FormHelperText className='text-danger'>{errors.bedrooms}</FormHelperText>

                            </FormControl>
                        </Grid>
                    </Grid>

}

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            {/* <TextField
                                label="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                            /> */}
                            <FormControl fullWidth>
                                <InputLabel>location</InputLabel>
                                <Select
                                    value={location}
                                    onChange={(e) => setLocation(e.target.value)}
                                >
                                {
                                    locations.map((e, index)=>{
                                        return(
                                            <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                        )
                                    })
                                }
                                </Select>
                                <FormHelperText className='text-danger'>{errors.location}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <CurrencyInput
                                style={{
                                    width: '100%', padding: '15px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px',
                                    fontSize: '16px', transition: 'border-color 0.3s'
                                }}
                                id="input-example"
                                name="input-name"
                                label="Price"
                                placeholder="Price"
                                //   defaultValue={0}
                                value={expectedPrice}
                                decimalsLimit={2}
                                onValueChange={(value, name, values) => setExpectedPrice(value)}
                                error={!!errors.expectedPrice}
                                helperText={errors.expectedPrice}
                            />
                            {/* <TextField
                                label="Price"
                                value={AmountSaperator(expectedPrice)}
                                onChange={(e) => setExpectedPrice(e.target.value)}
                                fullWidth
                                error={!!errors.expectedPrice}
                                helperText={errors.expectedPrice}
                            /> */}
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                           
                           <FormControl fullWidth>
                               <InputLabel>Developer</InputLabel>
                               <Select
                               value={developer}
                               onChange={(e) => setDeveloper(e.target.value)}
                               >
                               {
                                   developers.map((e, index)=>{
                                       return(
                                           <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                       )
                                   })
                               }
                               </Select>
                               <FormHelperText className='text-danger'>{errors.developer}</FormHelperText>
                           </FormControl>
                       </Grid>
                        <Grid item xs={12} md={12} lg={6} xl={6}>
                            <FormControl fullWidth>
                                {/* <InputLabel id="users-label">Select Users</InputLabel> */}
                                <Autocomplete
                                    multiple
                                    id="checkboxes-tags-demo"
                                    options={owner}
                                    //   value={[
                                    //     {
                                    //         "id": 20,
                                    //         "name": "Richard Charles Regan Jr",
                                    //         "profile_image": "663b130f43062.png",
                                            
                                    //     }
                                    // ]}
                                    value={selectedUsers}
                                    onChange={ handleAutocompleteChange}
                                    disableCloseOnSelect
                                    getOptionLabel={(option) => option.name}
                                    renderOption={(props, option, { selected }) => (
                                        <li {...props} key={option.id} >
                                            <Checkbox
                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                            />
                                            <Avatar style={{marginRight:'10px'}} src={profileImageBaseUrl+"/"+option.profile_image} />
                                            {option.name}
                                        </li>
                                    )
                                    
                                }
                                  style={{ width: 400 }}
                                  renderInput={(params) => (
                                    <TextField {...params} label="Select Users" placeholder="" />
                                  )}
                                />


                            
                            </FormControl>
                        </Grid>
                    </Grid>

                    <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={12} xl={12}>
                <TextField
                            label="Handover"
                            value={handover}
                            onChange={(e) => setHandover(e.target.value)}
                            fullWidth
                            error={!!errors.handover}
                            helperText={errors.handover}
                        />
                        </Grid>
                        </Grid>
                    <Grid container spacing={2} style={{ marginTop: '2rem',marginBottom: '5rem' }}>
                   
                   {/* <Grid item xs={12} md={12} lg={4} xl={4}>
                   <label  class="form-label">Handover</label> {updateproperty.hand_over?updateproperty.hand_over:null}
                   <input accept=".pdf,.docx,.png,.jpeg,.jpg"  class="form-control" type="file" onChange={handleHandover}  />
                   <div className='text-danger'>{errors.handover}</div>
                   </Grid> */}
                   <Grid item xs={12} md={12} lg={6} xl={6}>
                   <label  class="form-label">Title deed</label> {updateproperty.title_deed?updateproperty.title_deed:null}
                   <input accept=".pdf,.docx,.png,.jpeg,.jpg" class="form-control" type="file" onChange={handleTtitledeed} />
                   <div className='text-danger'>{errors.titledeed}</div>
                   </Grid>
                   <Grid item xs={12} md={12} lg={6} xl={6}>
                   <label  class="form-label">Floor plan</label> {updateproperty.floor_plan?updateproperty.floor_plan:null}
                   <input accept=".pdf,.docx,.png,.jpeg,.jpg" class="form-control" type="file" onChange={handleFloorplan} />
                   <div className='text-danger'>{errors.floorplan}</div>
                   </Grid>
                   </Grid>

                    <Button onClick={handleSubmit} variant="contained" color="primary" type="submit" style={{ marginTop: '1rem' }} disabled={loading} >
                    {loading ? (
                <>
                  <BeatLoader color="white" size={5} />
                  Processing
                </>
              ) : (
                <>
                  <SaveIcon /> Update Property
                </>
              )}
                        
                    </Button>
                        
                </div>
            </div>
        // </Container>
    )
}

export default UpdatePropertyComp;
